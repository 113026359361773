export class cb_pager {
    id: string;
    settings: any;
    callback: (value:any) => Promise<void>;
    container: any;

    entry_count;
    page_size
    current_page

    constructor(id:string, settings: any, callback: (value:any) => Promise<void>) {
        this.id = id;
        this.callback = callback;
        this.container = document.getElementById(id);
        this.entry_count = 0;
        this.page_size = 10;
        this.current_page = 0;
    }

    async setPages(entry_count: number, page_size: number, current_page_p: number) {

        //console.log("setpages entry_count "+entry_count+" page_size "+page_size+" current_page" +current_page_p);
        this.entry_count = entry_count;
        this.page_size = page_size;
        
        let max_page_number_buttons = 5;

        let num_pages = Math.ceil(entry_count / page_size);
        if(current_page_p < num_pages) {
            this.current_page = current_page_p;
        }
        else {
            this.current_page = num_pages - 1;
        }

        //console.log("this.current_page "+this.current_page);

        let handlers = [] as string[];

        let disabled = "";
        if(this.current_page == 0) {
            disabled = " c-pager-button-disabled";
        }
        else {
            handlers.push("prev");
        }

        let html = `<div class="c-pager"><div><button class="c-pager-button c-pager-prev${disabled}" id="${this.id}$prev">&nbsp;</button>`;
        
        if(num_pages <= max_page_number_buttons) {
            for(let page = 0; page < num_pages; page++) {

                let active = "";
                if(page == this.current_page) {
                    active = " c-pager-button-active";
                }
                else {
                    handlers.push("" + page);
                }

                html += `<button class="c-pager-button c-pager-page${active}" id="${this.id}$${page}">${page+1}</button>`;
                
            }
        }
        else {
            //first page
            let page = 0;
            let active = "";
            if(page == this.current_page) {
                active = " c-pager-button-active";
            }
            else {
                handlers.push("" + page);
            }
            html += `<button class="c-pager-button c-pager-page${active}" id="${this.id}$${page}">${page+1}</button>`;

            if(this.current_page > 2) {
                html += `<button class="c-pager-button c-pager-page c-pager-button-disabled">...</button>`;
            }

            //Page number buttons

            let pn_start = this.current_page - 1;
            let pn_end = this.current_page + 1;
            if(pn_start < 1) {
                pn_start = 1;
            }
           
            if(pn_end >= num_pages - 2) {
                pn_end = num_pages - 2;
            }

            for(let pn = pn_start; pn <= pn_end; pn++) {
                let active = "";
                if(pn == this.current_page) {
                    active = " c-pager-button-active";
                }
                else {
                    handlers.push("" + pn);
                }
                html += `<button class="c-pager-button c-pager-page${active}" id="${this.id}$${pn}">${pn+1}</button>`;
            }

            if(this.current_page < num_pages - 3) {
                html += `<button class="c-pager-button c-pager-page c-pager-button-disabled">...</button>`;
            }

            //last page
            page = num_pages - 1;
            active = "";
            if(page == this.current_page) {
                active = " c-pager-button-active";
            }
            else {
                handlers.push("" + page);
            }
            
            html += `<button class="c-pager-button c-pager-page${active}" id="${this.id}$${page}">${page+1}</button>`;
        }

        disabled = "";
        if(this.current_page >= num_pages - 1) {
            disabled = " c-pager-button-disabled";
        }
        else {
            handlers.push("next");
        }

        html += `<button class="c-pager-button c-pager-next${disabled}" id="${this.id}$next">&nbsp;</button>`;

        let from = this.current_page*this.page_size + 1;
        if(from < 0) {
            from = 0;
        }
        let to = from + this.page_size - 1;
        if(to > this.entry_count) {
            to = this.entry_count;
        }
        html += `</div><div class="c-pager-info">Showing ` + from + " to "+to + " of " + this.entry_count +"</div></div>";

        this.container.innerHTML = html;

        //Event handlers

        let self = this;

        for(let h of handlers) {

            let button_id = this.id + "$" + h;
            let button = document.getElementById(button_id);

            if(button) {
                button.addEventListener('click', async function() {
                    let button_id = this.id;

                    let s = button_id.split("$");

                    let page = s[s.length-1];
                    await self.setPage(page);
                });
            }
        }

    }

    async setPage(page: string) {

        //console.log("page "+page);

        let goto_page = 0;
        if(page == "next") {
            goto_page = this.current_page + 1;
        }
        else if(page == "prev") {
            if(this.current_page <= 0) {
                return;
            }
            goto_page = this.current_page - 1;
        }
        else {
            goto_page = Number(page);
        }

        await this.callback(goto_page);
    }



}