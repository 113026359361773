import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { drill } from "../../codebricks-runtime/CBUtil";
import { CBWebUtil } from "../controls/cb_web_util";

export class cd_tree_webcomponent extends HTMLElement {
    ci: web_cd_tree | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cd_tree(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cd-tree', cd_tree_webcomponent);

export class web_cd_tree extends CodeBrick {

    element: HTMLElement;
    listener = false;
    handlers = {} as any;
    expand_levels = 0;
    row_idx = 0;
    first_ul = true;

    out_data = [] as any;
    cements = {} as { [child_idx: number]: any };

    initial_search = "";
    search = "";

    cfg = null as any;

    initialized = false;

    //shadowroot: ShadowRoot;
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("CodeBricksTree cb_event "+input+" "+JSON.stringify(this.blueprint.cfg)+" => "+JSON.stringify(cfg));

        if(input == 'cfg') {

            // let container_style = "";
            // if(cfg.style) {
            //     for(let v in cfg.style) {
            //         container_style += v+':'+cfg.style[v]+";"
            //     }
            // }

            // let classes = '';
            // if(cfg.style_classes) {
            //     for(let cl in cfg.style_classes) {
            //         classes += " " + cfg.style_classes[cl];
            //     }
            // }

            this.cfg= cfg;

            if(!this.initialized) {
                this.initialized = true;
                let search_html = `<div class="cd-tree-search-container${cfg.search && cfg.search.hidden?" hidden":""}"><input type="text" spellcheck="false" id="${this.brick_id+"$search"}" placeholder="${(cfg.search && cfg.search.label) ? cfg.search.label : 'Search'}" ${CBWebUtil.GetElementStylesString("cd-tree-search", cfg, "search")}/><span class="cd-tree-search-close" id="${this.brick_id+"$search_close"}"></span></div>`;
    
                //this.element.innerHTML = `<div id="${this.brick_id}">`+recurse_data.html + '</ul></div>';

                this.element.innerHTML = search_html + `<div id="${this.brick_id}" ${CBWebUtil.GetElementStylesString("cd-tree", cfg, "tree")}></div>`;
                //And then this
                //await this.send_dynamic_cfg_tree("@", "/"+this.blueprint.name + this.dc, cfg.data, cfg.child_path, { i : 0 });
    
                let self = this;
                
                let search_element = document.getElementById(this.brick_id+"$search") as HTMLInputElement;
    
                if(search_element) {
                    if(cfg.table && cfg.table.search && (cfg.table.search.value != this.initial_search || cfg.table.search.value == "")) {
                        (<HTMLInputElement>search_element).value = cfg.table.search.value === undefined ? "" : cfg.table.search.value;
                        this.initial_search = cfg.table.search.value;
                    }
        
                    search_element.addEventListener('input', async function() {
                        if(search_element) {
                            await self.render(self.cfg);
                        }
                    });
                    
                    let search_term = search_element.value;

                    if(search_term) {
                        search_element.classList.add("cd-table-search-active");
                    }
                    else {
                        search_element.classList.remove("cd-table-search-active");
                    }
                    let search_close = document.getElementById(this.brick_id+"$search_close");
                    if(search_close) {
                        search_close.style.display = search_term == "" ? "none" : "inline-block";
          
                        search_close.addEventListener("click", async function() {
                            let search = document.getElementById(self.brick_id+"$search");
                            let search_term = (<HTMLInputElement>search).value;
                            if(search_term) {
                                (<HTMLInputElement>search).value = "";
                                await self.render(self.cfg);
                            }
                        });                       
                    }
                }
            }
            else {
                let container = document.getElementById(this.brick_id);
                if(container) {
                    CBWebUtil.ApplyElementStyles(container, cfg, "tree");
                }
                let search = document.getElementById(this.brick_id+"$search");
                if(search) {
                    CBWebUtil.ApplyElementStyles(search, cfg, "search");
                }
            }

            await this.render(cfg);
            

            //return { "@": this.out_data };
        }
    }

    async render(cfg: any) {

        this.flush_dynamic();

        this.expand_levels = cfg.expand_levels || 0;

        let recurse_data = { html: '', i: 0 }

        let search_element = document.getElementById(this.brick_id+"$search") as HTMLInputElement;
        if(search_element) { 
            this.search = search_element.value;

            let search_term = search_element.value;

            if(search_term) {
                search_element.classList.add("cd-table-search-active");
            }
            else {
                search_element.classList.remove("cd-table-search-active");
            }
            let search_close = document.getElementById(this.brick_id+"$search_close");
            if(search_close) {
                search_close.style.display = search_term == "" ? "none" : "inline-block";
            }
        }

        let data = cfg.data

        if(this.search) {
            if(data) {
                if(Array.isArray(data)) {
                    let arr_data = [] as any;
                    for(let d of data) {
                        let searched = this.search_data(cfg, d, this.search);
                        if(searched) {
                            arr_data.push(searched);
                        }
                    }
                    data = arr_data;
                }
                else {
                    data = this.search_data(cfg, data, this.search);
                }
            }
        }
        
        this.row_idx = 0;
        if(data) {
            if(Array.isArray(data)) {
                for(let d of data) {
                    this.render_recurse(recurse_data, cfg, d, recurse_data.i, true); 
                }
            }
            else {
                this.render_recurse(recurse_data, cfg, data, 1, true); 
            }
        }

        let tree = document.getElementById(this.brick_id);
        if(tree) {
            tree.innerHTML = recurse_data.html;
        }


        let self = this;

        for(let id in this.handlers) {
            let subMenuExp = document.getElementById(id + "$exp");
            if(subMenuExp) {
                subMenuExp.addEventListener('click', function(event) {

                    event.preventDefault();
                    const subMenuList = document.getElementById(id + "$ul");//(<any>event.target).nextElementSibling;
                    let subMenuExp = document.getElementById(id + "$exp");
                    if(subMenuList && subMenuExp) {
                        if(subMenuList.style.display=="none"){
                            subMenuExp.classList.remove("c-tree-expander-collapsed");
                            subMenuExp.classList.add("c-tree-expander-expanded");
                            subMenuList.style.display = "block";
                        }
                        else {
                            subMenuExp.classList.remove("c-tree-expander-expanded");
                            subMenuExp.classList.add("c-tree-expander-collapsed");
                            subMenuList.style.display = "none";
                        }
                    }
                    event.stopPropagation();

                });
            }

            let subMenuHeading = document.getElementById(id);
            if(subMenuHeading) {
                subMenuHeading.addEventListener('click', function(event) {
                    self.cb_emit({"@": self.handlers[id]});
                });
            }
        }
        
        await this.send_dynamic_initialisation_events(this.out_data);
    }

    render_recurse(recurse_data: { html: string, i : number }, cfg: any, data: any, level: number, create_ul = false) {

    
        //let id =  this.blueprint.name+"_"+recurse_data.i;
        let id =  this.brick_id+"$"+recurse_data.i;

        if(create_ul) {
            recurse_data.html += `<ul id="${id}$ul" class="c-tree-submenu-items" style="display:${this.expand_levels == 0 || level <= this.expand_levels ? 'block' : 'none'}">`;
            recurse_data.i++;
            id =  this.brick_id+"$"+recurse_data.i;
        }

        let children = data;
        if(cfg.child_path) {
            children = drill(cfg.child_path, data);
        }

        let expander = '';
        if(children && Object.keys(children).length > 0 && !cfg.static) { //} && !this.first_ul) {
            if(this.expand_levels == 0 || level <= this.expand_levels) {
                expander = `<span id="${id}$exp" class="c-tree-expander-expanded"></span>`;
            }
            else {
                expander = `<span id="${id}$exp" class="c-tree-expander-collapsed"></span>`;
            }
        }
        else {
            expander = `<span class="c-tree-expander"></span>`;
        }
        this.first_ul = false;

        let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

        let child_dc = dc_root + "--" + this.row_idx;
            
        this.out_data[this.row_idx] = data;

        let contained = '';
        if(this.blueprint.contains) {
            let i = 0;

            for(let sub of this.blueprint.contains) {

                let cement = this.cements[i] || sub.cement;

                let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc);
                if(cement && cement.hidden) {
                    contained += `<div class="hidden">${brick}</div>`;
                }
                else {
                    contained += brick;
                }
                i++;
            }
            this.row_idx++;
        }

        recurse_data.i++;

        recurse_data.html += `<li><div class="c-tree-node">`+expander+' '+contained+`</div>`;
        this.handlers[id] = data;

        let ul = false;
        for(let k in children) {
            if(!ul) {
                ul = true;
                recurse_data.html += `<ul id="${id}$ul" class="c-tree-submenu-items" style="display:${this.expand_levels == 0 || level <= this.expand_levels ? 'block' : 'none'}">`;
            }
            let child = children[k];
            // let label = k;
            // if(cfg.label_path) {
            //     label = drill(cfg.label_path, child);
            // }
            this.render_recurse(recurse_data, cfg, child, level + 1, false);
        }

        recurse_data.html += '</li>';

        if(ul) {
            recurse_data.html += '</ul>';
        }
        if(create_ul) {
            recurse_data.html += '</ul>';
        }

        
    }

    // render_recurse_table_with_links(recurse_data: { html: string, i : number }, cfg: any, data: any, level: number, create_table = false) {

    
    //     //let id =  this.blueprint.name+"_"+recurse_data.i;
    //     let id =  this.brick_id+"$"+recurse_data.i;

    //     if(create_table) {
    //         recurse_data.html += `<table id="${id}$table" class="c-tree-table" style="display:${this.expand_levels == 0 || level <= this.expand_levels ? 'block' : 'none'}">`;
    //         recurse_data.i++;
    //         id =  this.brick_id+"$"+recurse_data.i;
    //     }

    //     let children = data;
    //     if(cfg.child_path) {
    //         children = drill(cfg.child_path, data);
    //     }

    //     let expander = '';
    //     if(children && Object.keys(children).length > 0 && !cfg.static) { //} && !this.first_ul) {
    //         if(this.expand_levels == 0 || level <= this.expand_levels) {
    //             expander = `<span id="${id}$exp" class="c-tree-expander-expanded"></span>`;
    //         }
    //         else {
    //             expander = `<span id="${id}$exp" class="c-tree-expander-collapsed"></span>`;
    //         }
    //     }
    //     else {
    //         expander = `<span class="c-tree-expander"></span>`;
    //     }
    //     this.first_ul = false;

    //     let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

    //     let child_dc = dc_root + "--" + this.row_idx;
            
    //     this.out_data[this.row_idx] = data;

    //     let contained = '';
    //     if(this.blueprint.contains) {
    //         let i = 0;

    //         for(let sub of this.blueprint.contains) {

    //             let cement = this.cements[i] || sub.cement;

    //             let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc);
    //             if(cement && cement.hidden) {
    //                 contained += `<div class="hidden">${brick}</div>`;
    //             }
    //             else {
    //                 contained += brick;
    //             }
    //             i++;
    //         }
    //         this.row_idx++;
    //     }

    //     recurse_data.i++;

    //     recurse_data.html += `<tr><div class="c-tree-node">`+expander+' '+contained+`</div></tr>`;
    //     this.handlers[id] = data;

    //     let ul = false;
    //     for(let k in children) {
    //         // if(!ul) {
    //         //     ul = true;
    //         //     recurse_data.html += `<ul id="${id}$ul" class="c-tree-submenu-items" style="display:${this.expand_levels == 0 || level <= this.expand_levels ? 'block' : 'none'}">`;
    //         // }
    //         let child = children[k];

    //         this.render_recurse_table_with_links(recurse_data, cfg, child, level + 1, false);
    //     }

    //     //recurse_data.html += '</li>';


    //     if(create_table) {
    //         recurse_data.html += '</table>';
    //     }

        
    // }

    search_data(cfg: any, data: any, search: string) {

        let ret = {} as any;

        let children = data;
        if(cfg.child_path) {
            children = drill(cfg.child_path, data);
        }

        let found = false;

        if(cfg && cfg.search && cfg.search.search_property) {
            if(data[cfg.search.search_property].toLowerCase().indexOf(search.toLocaleLowerCase()) != -1) {
                found = true;
            }
        }
        else {
            for(let prop in data) {
                if(typeof data[prop] == "string") {
                    if(data[prop].toLowerCase().indexOf(search.toLocaleLowerCase()) != -1) {
                        found = true;
                    }
                }
            }
        }

        if(found) {
            return data;
        }

        let childs = [];
        for(let k in children) {
            let child = children[k];

            let has = this.search_data(cfg, child, search);
            if(has) {
                found = true;

                if(cfg.child_path) {
                    childs.push(has);
                }
            }
        }

        if(found) {
            for(let p in data) {
                ret[p] = data[p];
            }

            if(cfg.child_path) {
                ret[cfg.child_path] = childs;
            }

            return ret;
        }

        return null;
    }


    cb_initial_cement(cements: { [child_idx: number]: any }) {
        //console.log("cd-table "+this.brick_id+" cb_initial_cement "+JSON.stringify(cements));
        this.cements = cements;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {

        //console.log("cd-table "+this.brick_id+" cb_update_cement "+child_idx + " cement "+JSON.stringify(cement));

        //this.cements[child_idx] = cement;

        let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

        let child_dc = dc_root + "--" + row_idx;

        let child = this.element.querySelector('[dc="'+child_dc+'"][idx="'+child_idx+'"][container_id="'+this.brick_id+'"]');
        if(child) {
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
        }
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}
