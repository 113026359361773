import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_popout } from "../controls/cb_popout";
import Sugar from "sugar";
import { DateRange } from "../../shared-funcs/DateRange"
import { cb_datetimepicker } from "../controls/cb_datetimepicker";
import { codebricks_format } from "../../codebricks-runtime/CBFormatting";

export class c_daterange_webcomponent extends HTMLElement {
    ci: web_c_daterange | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_daterange(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-daterange', c_daterange_webcomponent);

export class web_c_daterange extends CodeBrick {

    element: HTMLElement;
    initialised = false;

    displayformat = "{short}";
    outputformat = "{short}";
    value = "";
    date1 = null as any;
    date2 = null as any;

    //--
    popout: cb_popout;

    datepicker1: cb_datetimepicker;
    datepicker2: cb_datetimepicker;
    cfg = {} as any;

    preset = "";

    snapshot = undefined as any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        let self = this;
        this.element.innerHTML = `<div id="${this.brick_id}" class='c-daterange-container no_click_close'></div>`;
        this.popout = new cb_popout(this.brick_id, true, this.blueprint.ins.cfg, "c-daterange", false, false,
        function() {
            //pop opened
        },
        function() {

            // console.log(self.brick_id+" emit "+emit_val)

            console.log("PRE "+self.date1);

            let emit_val =  {

                start: codebricks_format(self.date1, self.outputformat, self.context.composition_runners[self.cid].compos?.system_options || {}).value,
                // Sugar.Date.format(
                //     self.date1, self.dateformat
                // ),
                end : codebricks_format(self.date2, self.outputformat, self.context.composition_runners[self.cid].compos?.system_options || {}).value
                // Sugar.Date.format(
                //     self.date2, self.dateformat
                // )
            };

            console.log("POST "+self.date1);

            self.snapshot = self.snapshot || {};
            self.snapshot.cfg = self.snapshot.cfg || {};
            self.snapshot.cfg.value = self.preset || (emit_val.start + " - "+emit_val.end);

            self.cb_emit({"@": emit_val, "@preset" : self.preset || (emit_val.start + " - "+emit_val.end)  });
        });

        let drop = document.getElementById(this.brick_id+"$drop");
        if(drop) {
            drop.innerHTML = `<div class="c-daterange-drop-inner">
                                <div>
                                    <div class="c-daterange-fromto">
                                        <div class="c-daterange-from">
                                            <div class="c-daterange-h">From</div>
                                            <div id="${this.brick_id}$picker1"></div>
                                        </div>
                                        
                                        <div class="c-daterange-to">
                                            <div class="c-daterange-h">To</div>
                                            <div id="${this.brick_id}$picker2"></div>
                                        </div>
                                    </div>
                                    <div class="c-daterange-selection" id="${this.brick_id}$selection"></div>
                                </div>
                                <div class="c-daterange-presets-container" id="${this.brick_id}$presets"></div>
                              </div>`;
        }

        let current_year = Number(Sugar.Date.format(Sugar.Date.create(), "{yyyy}"));

        this.datepicker1 = new cb_datetimepicker(this.brick_id+"$picker1", 
        true,
        true,
        true,
        false,
        false,
        current_year - 120,
        current_year + 50,
        function (value: any) {

            self.date1 = value == "" ? 
                 Sugar.Date.create() : Sugar.Date.create(value);
                             
            if(Sugar.Date.isBefore(self.date2, self.date1)) {
                self.date2 = Sugar.Date.create(self.date1.getTime()); //clone date1
                self.datepicker2.set_value(self.date2);
            }
            if(self.cfg.max_range) {
                let date_max = Sugar.Date.create(self.date1.getTime());
                date_max = Sugar.Date.advance(date_max, self.cfg.max_range);
                date_max = Sugar.Date.rewind(date_max, "1 day");
                if(Sugar.Date.isBefore(date_max, self.date2)) {
                    self.date2 = date_max;
                    self.datepicker2.set_value(self.date2);
                }
            }

            self.popout.setDisplayValue(
                codebricks_format(self.date1, self.displayformat, self.context.composition_runners[self.cid].compos?.system_options || {}).value
                +" - "
                +
                codebricks_format(self.date2, self.displayformat, self.context.composition_runners[self.cid].compos?.system_options || {}).value
            );

            let selection = document.getElementById(self.brick_id+"$selection");
            if(selection) {

                let days = Sugar.
                Date.range(self.date1, self.date2).days() + 1;

                let disp = days+" day"+(days == 1 ? "" : "s") +" selected";

                if(self.cfg.max_range) {
                    disp += " (Max range: "+self.cfg.max_range+")";
                }

                selection.innerHTML = disp;
            }

            self.preset = "";
    
        });

        this.datepicker2 = new cb_datetimepicker(this.brick_id+"$picker2",
        true,
        true,
        true,
        false,
        false,
        current_year - 120,
        current_year + 50,
        function (value: any) {

            self.date2 = value == "" ? 
                 Sugar.Date.create() : Sugar.Date.create(value);
                
            if(Sugar.Date.isBefore(self.date2, self.date1)) {
                self.date1 = Sugar.Date.create(self.date2.getTime());
                self.datepicker1.set_value(self.date1);
            }
            if(self.cfg.max_range) {
                let date_min = Sugar.Date.create(self.date2.getTime());
                date_min = Sugar.Date.rewind(date_min, self.cfg.max_range);
                date_min = Sugar.Date.advance(date_min, "1 day");
                if(Sugar.Date.isAfter(date_min, self.date1)) {
                    self.date1 = date_min;
                    self.datepicker1.set_value(self.date1);
                }
            }
            
            self.popout.setDisplayValue(
                codebricks_format(self.date1, self.displayformat, self.context.composition_runners[self.cid].compos?.system_options || {}).value
                +" - "
                +
                codebricks_format(self.date2, self.displayformat, self.context.composition_runners[self.cid].compos?.system_options || {}).value
            );

            let selection = document.getElementById(self.brick_id+"$selection");
            if(selection) {
                let days = Sugar.
                Date.range(self.date1, self.date2).days() + 1;

                let disp = days+" day"+(days == 1 ? "" : "s") +" selected";

                if(self.cfg.max_range) {
                    disp += " (Max range: "+self.cfg.max_range+")";
                }

                selection.innerHTML = disp;
            }

            self.preset = "";
        });
  
        this.popout.init();

    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {


        this.cfg = cfg;
        this.popout.setLabel(cfg.label);
        let values = DateRange.GetValues(cfg, this.context.composition_runners[this.cid].compos?.system_options);
        this.displayformat = values.displayformat;
        this.outputformat = values.outputformat;
        this.date1 = values.date1;
        this.date2 = values.date2;
        this.preset = values.preset;

        let presets_html = "";
        if(cfg.presets) {
            for(let p = 0; p < cfg.presets.length; p++) {
                let preset = cfg.presets[p];
                if(this.cfg.max_range) {
                    let max_days = Sugar.Date.range("now for "+this.cfg.max_range).days();
                    let preset_days = Sugar.Date.range(preset.start + " to "+preset.end).days();
                    if(preset_days > max_days) {
                        continue;
                    }
                }
                presets_html += `<button class="c-datetime-preset" id="${this.brick_id}$preset${p}">${cfg.presets[p].label}</button>`;
            }
        }
        let presets_element = document.getElementById(this.brick_id+"$presets");
        if(presets_element) {
            presets_element.innerHTML = presets_html;
        }

        if(cfg.presets) {
            let self = this;
            for(let p = 0; p < cfg.presets.length; p++) {
                let preset_button = document.getElementById(this.brick_id+"$preset"+p);
                if(preset_button) {
                    preset_button.addEventListener("click", function(e:any) {
                        let p = Number(e.target.id.split("$preset")[1]);
                        let preset = self.cfg.presets[p];
                        self.date1 =

                            Sugar.
                            Date.create(
                                preset.start);
                        

                        self.date2 =
                        Sugar.
                            Date.create(
                                preset.end);
                        
                        self.set_start_end(preset.label); 
                        
                        self.popout.closeSelect();
                    });
                }
            }
        }

        this.set_start_end(this.preset);

        let emit_val =  {

            start: codebricks_format(this.date1, this.outputformat, this.context.composition_runners[this.cid].compos?.system_options || {}).value,
            end :codebricks_format(this.date2, this.outputformat, this.context.composition_runners[this.cid].compos?.system_options || {}).value
        };

        //console.log("c-daterange "+this.brick_id+ " return " + JSON.stringify(emit_val));

        return {"@": emit_val, "@preset" : this.preset || (emit_val.start + " - "+emit_val.end) };
    }

    set_start_end(preset: string) {
        this.datepicker1.init(this.date1);

        this.datepicker2.init(this.date2);

        if(preset && this.context.platform != "file") {
            this.popout.setDisplayValue(preset);
        }
        else {
            this.popout.setDisplayValue(
                codebricks_format(this.date1, this.displayformat, this.context.composition_runners[this.cid].compos?.system_options || {}).value
                +" - "
                +
                codebricks_format(this.date2, this.displayformat, this.context.composition_runners[this.cid].compos?.system_options || {}).value
            );
        }

        let selection = document.getElementById(this.brick_id+"$selection");
        if(selection) {
            let days = Sugar.
            Date.range(this.date1, this.date2).days() + 1;

            let disp = days+" day"+(days == 1 ? "" : "s") +" selected";

            if(this.cfg.max_range) {
                disp += " (Max range: "+this.cfg.max_range+")";
            }

            selection.innerHTML = disp;
        }


        this.preset = preset;

    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        if(status == "required") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.add("required");
            }
        }
        else if(status == "") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.remove("required");
            }
        }
    }

    cb_snapshot() {
        return this.snapshot;
    }
}
