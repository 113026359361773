import { CBContext, CBEventInfo, Composition, TagItem } from "../../codebricks-runtime/CBModels";
import { Clone, CloneMerge, DrillGet, FindBrick, FindBrickContainer, ObjectDeepMerge, OutputSchemaToNodes, RegisterSOCallback, array_move, drill } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { TemplateUtil } from "../../codebricks-runtime/TemplateUtil";
import { TagItemParser } from "../../codebricks-runtime/TagItemParser";
import { CBWebUtil } from "../controls/cb_web_util";

import {minimalSetup, EditorView, basicSetup } from "codemirror";
import {AnnotationType} from "@codemirror/state";
import {autocompletion, startCompletion, CompletionSection, Completion, insertCompletionText, closeBrackets } from "@codemirror/autocomplete";
import {parser as cbtemplateParser} from "../template_lang/cbtemplate-parser";
import {html, htmlLanguage} from "@codemirror/lang-html";
import {parseMixed} from "@lezer/common";
import { LRLanguage, syntaxTree, syntaxHighlighting } from '@codemirror/language';
import { cbtemplateHighlightStyle } from "../template_lang/cbtemplate-highlight";
import { TagElementPredictor } from "../template_lang/TagElementPredictor";
import { CBTemplateParserNodes } from "../template_lang/CBTemplateNodes";
import { javascript, javascriptLanguage, scopeCompletionSource } from "@codemirror/lang-javascript";
import {keymap} from "@codemirror/view";
import {indentWithTab} from "@codemirror/commands";
import { cb_tooltip } from "../controls/cb_tooltip";

import Sugar from "sugar";

//import {bracketMatching} from "@codemirror/matchbrackets";

export class c_template_form_webcomponent extends HTMLElement {
    ci: web_c_template_form | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_template_form(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-template-form', c_template_form_webcomponent);

interface ui_node {
    id: string;
    //parent_id: number;
    schema: any;
    value: any;
    //children: ui_node[];
    //label: string;
    desc: string;
    path: string;
    //container_type: string;
}

export class web_c_template_form extends CodeBrick {

    element: HTMLElement;
    initialised = false;
    value = "";
    handlers = {} as { [col: string] : any };
    edit_path = "";
    any_props = {} as { [prop_id: string] : boolean };
    enable_templating = true;

    ui_tree = {} as ui_node;
    nodeid = 1;

    container_id = "";


    cb_selects = {} as any;

    sources = [] as any;
    specs = {} as any;
    composition_name = "";
    container_name = "";
    composition_tree = {} as Composition;
    brick_name = "";
    brick_type = "";

    pre_edit_tag_contents = "";

    has_ok_callback = false;

    element_idx = 0;

    schema = {} as any;

    drag_handle: any;

    current_path = "";

    editors_by_e_id = {} as {
        [e_id:string]: {
            has: boolean,
            editor: EditorView | null,
            last_head : number
        }
    }

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        (<any>window).cb_editor = (<any>window).cb_editor || {};
    }

    cb_update_cement(child_idx: number, cement: any, row_idx: number): void {
        throw new Error("Method not implemented.");
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        
        if(input == 'form') {
            //console.log("CodeBricksForm "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg)+ " source "+info.source);

            //This here and where the values are used later is to keep the focus and cursor position if the data refreshes, which is necessary for the debugger.
            let focused_id = null;
            let selection = null;
            if(document.activeElement) {
                let ed = document.activeElement
                while(ed.parentElement) {
                    if(ed.id.startsWith("e_vcb_")) {
                        focused_id = ed.id;
                        if(this.editors_by_e_id[focused_id]) {
                            let editor = this.editors_by_e_id[focused_id].editor;
                            if(editor) {
                                selection = editor.state.selection.main;
                            }
                        }
                        break;
                    }
                    ed = ed.parentElement;
                }     
            }
            
            
            this.enable_templating = cfg.enable_templating || false;

            this.container_id = `c${this.brick_id}$${this.nodeid}`;

            this.element.innerHTML = `<div id="${this.brick_id}$form" class="editor-form">`; //</div><div id="${this.container_id}"></div>`;

            if(!this.initialised) {
                let mod1_html = `<cci-modal id="${this.brick_id}$mod1" ins='{"cfg":{ "title":"Edit Tag", "cancel_button": "Cancel", "ok_button":"OK" }}'></cci-modal>
                <cci-modal id="${this.brick_id}$mod2" ins='{"cfg":{ "title":"Edit Value", "cancel_button": "Cancel", "ok_button":"OK" }}'>      
                </cci-modal>`;
                document.body.insertAdjacentHTML("beforeend", mod1_html);


                let self = this;
                RegisterSOCallback(this.brick_id+"$mod2", "@", 
                    async function(data:any) {
                        //let node = self.find_node_by_path(data.node_path);
                        let value = (<any>window).so_bricks[self.brick_id+"$expanded_brick"].getValue();

                        let asjson = document.getElementById("asjson") as HTMLInputElement;
                        if(asjson.checked) {
                            let parsed = JSON.parse(value);
                        
                            let form_element = await self.get_form_element_by_path(data.node_path);

                            let editor_property = form_element.getAttribute("editor_property");
                            let editor_path = form_element.getAttribute("editor_path");
                            //let container_type = form_element.getAttribute("container_type");

                            let editor_color = "editor-color-1";
                            for(let c of form_element.classList) {
                                if(c.indexOf("editor-color") == 0) {
                                    editor_color = c;
                                }
                            }

                            let schema = self.get_path_schema(editor_path);
                            if(schema.type == "schema") {
                                value = self.get_schema_path_value(editor_path, value);
                                schema.sub = true;
                                schema.prop_editable = true;
                            }

                            //console.log("editor_property "+editor_property+ " editor_path " + editor_path + " container_type " + container_type + " schema "+JSON.stringify(schema) + " value "+JSON.stringify(value));

                            form_element.outerHTML = await self.render_form_property(schema, editor_property, parsed, editor_path, editor_color, "string");  
                        }
                        else {

                            //console.log("mod2 set_form_property_value path "+data.node_path+" value "+value);

                            //let form_prop = self.get_form_element_by_path(data.node_path);
                            await self.set_form_property_value(data.node_path, value);
                        }

                        // if(node) {
                        //     node.value = value;
                        //     await self.update_value_editor_value(node);
                        // }
                        (<any>window).so_bricks[self.brick_id+"$mod2_brick"].cb_event("hide", 1);

                        self.convertInputDivsToCodemirror();

                        await self.cb_emit({"@": self.get_form_value()});
                    }
                );
            }

            // if(this.ui_tree && this.ui_tree.children) {
            //     this.truncateChildren(this.ui_tree);
            // }

            //this.ui_tree = { id: this.nodeid++, children: [] as ui_node[], path: this.brick_id } as ui_node;

            //console.log('c-template-form in data '+JSON.stringify(cfg.data));

            //console.log('c-template-form in schema '+JSON.stringify(cfg.schema));
            this.schema = cfg.schema;

            // for(let schema_input in cfg.schema) {
            //     //let input_node = { id: this.nodeid++, parent_id: root_node_id, schema: cfg.schema[schema_input], label: schema_input, children: [] as ui_node[],  } as ui_node;

            //     let input_data;
            //     if(cfg.data) {
            //         input_data = cfg.data[schema_input];
            //     }

            //     // let input_node = this.add_node(this.ui_tree, cfg.schema[schema_input], input_data, schema_input);
            //     // if(input_node) {
            //     //     await this.render_node_recurse(input_node, "object");
            //     // }
            // }

            await this.render_form_inputs(cfg.schema, cfg.data || {});

            cb_tooltip.set_tooltips();
            
            this.initialised = true;

            if(focused_id && this.editors_by_e_id[focused_id]) {
                let editor = this.editors_by_e_id[focused_id].editor;
                let head = this.editors_by_e_id[focused_id].last_head;
                if(editor) {
                    if(head == -1) {
                        head = editor.state.doc.length;
                    }                 
                    editor.focus();
                    editor.dispatch(
                        editor.state.update({
                            selection: selection
                        })
                    );
                }
            }
 
            return {"@": this.get_form_value() };
        }
        else if(input == "property") {

            return { "@": this.get_form_value() };
        }
        else if(input == "tag_editor_data") {
            this.sources = cfg.sources;
            this.specs = cfg.specs;
            this.composition_name = cfg.composition_name;
            this.container_name = cfg.container_name;
            this.composition_tree = cfg.composition_tree;
            this.brick_name = cfg.brick_name;
            this.brick_type = cfg.brick_type;

            if(this.composition_tree.debug_log) {
                ObjectDeepMerge(this.composition_tree.debug_log.last_emit_data, this.context.last_emit_data["_1"]);
                this.context.last_emit_data["_1"] = this.composition_tree.debug_log.last_emit_data;
            }
        }
    }

    get_value_id(node: ui_node) {
        let value_id = "v"+this.brick_id + "__" + node.id;
        return value_id;
    }


    removeElement(id: string) {
        var elem = document.getElementById(id) as HTMLElement;
        if(elem) {
            let pnode = elem.parentNode;
            if(pnode) {
                return pnode.removeChild(elem);
            }
        }
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_status(status: string): void {
        let container = document.getElementById(this.container_id);
        if(container) {
            if(status == "loading") {           
                container.classList.add("loading");            
            }
            else {
                container.classList.remove("loading");            
            }
        }
    }
    cb_snapshot() {}

    ///////////////////////////////////////////////////////////////////////////////

    async render_form_inputs(schema: any, data: any) {

        //console.log("render_form_inputs schema "+JSON.stringify(schema)+ " data "+JSON.stringify(data));
        //this.destroyCodemirrorEditors();

        let html = "";
        for(let schema_input in schema) {  
            if(schema[schema_input].type !== undefined) {      
                html += await this.render_form_property(schema[schema_input], schema_input, data[schema_input], schema_input, "editor-color-1", "");    
            } 
        }
        let form_container = document.getElementById(`${this.brick_id}$form`);
        if(form_container) {
            form_container.innerHTML = html;
        }
        let self = this;

        this.convertInputDivsToCodemirror();

        //@ts-expect-error
        if(!window["editor_any_type_change_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_any_type_change_"+this.ci_idx] = async function(element: any) {
                let editor_element = element.parentElement.parentElement.parentElement;
                let editor_property = editor_element.getAttribute("editor_property");
                let editor_path = editor_element.getAttribute("editor_path");
                let container_type = editor_element.getAttribute("container_type");
                let editor_color = "editor-color-1";
                for(let c of editor_element.classList) {
                    if(c.indexOf("editor-color") == 0) {
                        editor_color = c;
                    }
                }
                let new_type = element.value;
                let new_data = "" as any;
                if(new_type == "array") {
                    new_data = [""];
                }
                else if(new_type == "object") {
                    new_data = {};
                }
                else if(new_type == "number") {
                    new_data = 0;
                }
                else if(new_type == "boolean") {
                    new_data = false;
                }

                editor_element.outerHTML = await self.render_form_property({type: "any"}, editor_property, new_data, editor_path, editor_color, container_type);

                self.convertInputDivsToCodemirror();

                await self.cb_emit({"@": self.get_form_value()});
            }
        }

        //@ts-expect-error
        if(!window["editor_schema_type_change_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_schema_type_change_"+this.ci_idx] = async function(element: any) {
                let editor_element = element.parentElement.parentElement.parentElement;
                let editor_property = editor_element.getAttribute("editor_property");
                let editor_path = editor_element.getAttribute("editor_path");
                let container_type = editor_element.getAttribute("container_type");
                let editor_color = "editor-color-1";
                for(let c of editor_element.classList) {
                    if(c.indexOf("editor-color") == 0) {
                        editor_color = c;
                    }
                }
                // let new_type = element.value;
                // let new_data = {} as any;
                // new_data[editor_property] = { type: new_type, desc: "" } as any
                // if(new_type == "object" || new_type == "object[]") {
                //     new_data[editor_property].properties = {};
                // }
                let new_type = element.value;
                let new_data = { type: new_type, desc: "" } as any
                if(new_type == "object" || new_type == "object[]") {
                    new_data.properties = {};
                }

                editor_element.outerHTML = await self.render_form_property({type: "schema", sub: true, prop_editable: true }, editor_property, new_data, editor_path, editor_color, container_type);

                self.convertInputDivsToCodemirror();

                await self.cb_emit({"@": self.get_form_value()});
            }
        }

        //@ts-expect-error
        if(!window["editor_any_prop_add_enter_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_any_prop_add_enter_"+this.ci_idx] = async function(element: any, event: any) {
                if(event.key == "Enter") {
                    //@ts-expect-error
                    await window["editor_any_prop_add_"+self.ci_idx](element.previousElementSibling);
                }
            }
        }

        //@ts-expect-error
        if(!window["editor_any_prop_add_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_any_prop_add_"+this.ci_idx] = async function(element: any) {
                let editor_element = element.parentElement.parentElement.parentElement;
                let editor_property = editor_element.getAttribute("editor_property");
                let editor_path = editor_element.getAttribute("editor_path");
                let container_type = editor_element.getAttribute("container_type");
                let prop_name_input = element.nextElementSibling;

                let editor_color = "editor-color-1";
                for(let c of editor_element.classList) {
                    if(c.indexOf("editor-color") == 0) {
                        editor_color = c;
                    }
                }

                let obj = {} as any;
                self.get_form_property_value(obj, editor_element);
                let current_value = obj[editor_property] || {};

                let prop_name = prop_name_input.value;
                if(prop_name === "") {
                    alert("Please supply a property name."); 
                }
                else {
                    current_value[prop_name] = "";
                }

                let schema = self.get_path_schema(editor_path);

                schema.type = "any";
                container_type = container_type || "object";

                //console.log("schema "+JSON.stringify(schema) + " editor_property " + editor_property + " current_value "+JSON.stringify(current_value) + " editor_path "+editor_path+" container_type "+container_type);

                editor_element.outerHTML = await self.render_form_property(schema, editor_property, current_value, editor_path, editor_color, container_type);

                self.convertInputDivsToCodemirror();

                let e_id = "e_v"+self.brick_id+"__"+editor_path +"."+prop_name;
                
                setTimeout(function() {
                    let editor = self.editors_by_e_id[e_id] as any;
                    if(editor) {
                        editor.editor.focus();
                    }
                }, 100);

                await self.cb_emit({"@": self.get_form_value()});
            }
            
        }

        //@ts-expect-error
        if(!window["editor_schema_prop_add_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_schema_prop_add_"+this.ci_idx] = async function(element: any, sub: boolean) {
                let editor_element = element.parentElement.parentElement.parentElement;
                let editor_property = editor_element.getAttribute("editor_property");
                let editor_path = editor_element.getAttribute("editor_path");
                let container_type = editor_element.getAttribute("container_type");
                let prop_name_input = element.nextElementSibling;

                let editor_color = "editor-color-1";
                for(let c of editor_element.classList) {
                    if(c.indexOf("editor-color") == 0) {
                        editor_color = c;
                    }
                }

                let obj = {} as any;
                self.get_form_property_value(obj, editor_element);

                //console.log("editor_property "+editor_property+" editor_property "+editor_property+ " obj "+JSON.stringify(obj));

                let current_value = obj[editor_property] || {};


                let prop_name = prop_name_input.value;

                //console.log("editor_schema_prop_add_ sub "+sub+" prop_name "+prop_name+" current_value "+JSON.stringify(current_value))

                
                if(prop_name === "") {
                    alert("Please supply a property name."); 
                }
                else {
                    if(sub) {           
                        current_value.properties[prop_name] = { type: "string", desc: "" };
                    }
                    else {
                        current_value[prop_name] = { type: "string", desc: "" };
                    }
                }

                //console.log("editor_schema_prop_add_ current_value after: "+JSON.stringify(current_value))

                let schema = self.get_path_schema(editor_path);
                if(sub) {
                    schema.sub = true;              
                    schema.prop_editable = true;                 
                }
                else {
                    if(schema.sub) {
                        delete schema.sub;
                    }
                    if(schema.prop_editable) {
                        delete schema.prop_editable;
                    }
                }

                //console.log("schema "+JSON.stringify(schema) + " editor_property " + editor_property + " current_value "+JSON.stringify(current_value) + " editor_path "+editor_path+" container_type "+container_type);

                editor_element.outerHTML = await self.render_form_property(schema, editor_property, current_value, editor_path, editor_color, container_type);

                self.convertInputDivsToCodemirror();

                await self.cb_emit({"@": self.get_form_value()});
            }
            
        }

        //@ts-expect-error
        if(!window["editor_any_item_add_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_any_item_add_"+this.ci_idx] = async function(element: any) {
                let editor_element = element.parentElement.parentElement.parentElement;
                let editor_property = editor_element.getAttribute("editor_property");
                let editor_path = editor_element.getAttribute("editor_path");
                let container_type = editor_element.getAttribute("container_type");

                let editor_color = "editor-color-1";
                for(let c of editor_element.classList) {
                    if(c.indexOf("editor-color") == 0) {
                        editor_color = c;
                    }
                }

                let obj = {} as any;
                self.get_form_property_value(obj, editor_element);
                let current_value = obj[editor_property] || [];

                if(current_value.length > 0) {
                    let prev = current_value[current_value.length - 1];
                    if(Array.isArray(prev)) {
                        current_value.push([""]);
                    }
                    else if(prev !== null && typeof prev == "object") {
                        current_value.push(Clone(prev));
                    }
                    else if(typeof prev == "number") {
                        current_value.push(prev + 1);
                    }
                    else if(typeof prev == "boolean") {
                        current_value.push(!prev);
                    }
                    else {
                        current_value.push("");
                    }
                }
                else {
                    current_value.push("");
                }

                let schema = self.get_path_schema(editor_path);
                
                editor_element.outerHTML = await self.render_form_property(schema, editor_property, current_value, editor_path, editor_color, container_type);

                self.convertInputDivsToCodemirror();

                await self.cb_emit({"@": self.get_form_value()});
            }
        }

        //@ts-expect-error
        if(!window["editor_any_prop_del_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_any_prop_del_"+this.ci_idx] = async function(element: any) {
                let editor_element = element.parentElement.parentElement.parentElement;
                
                editor_element.parentNode.removeChild(editor_element);

                await self.cb_emit({"@": self.get_form_value()});
            }
        }

        //@ts-expect-error
        if(!window["editor_any_item_del_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_any_item_del_"+this.ci_idx] = async function(element: any) {
                let editor_element = element.parentElement.parentElement.parentElement;
                let editor_property = editor_element.getAttribute("editor_property");

                //container element
                let container_element = editor_element.parentElement.parentElement;
                let container_property = container_element.getAttribute("editor_property");
                let container_path = container_element.getAttribute("editor_path");
                let container_container_type = editor_element.getAttribute("container_type");
                let container_color = "editor-color-1";
                for(let c of container_element.classList) {
                    if(c.indexOf("editor-color") == 0) {
                        container_color = c;
                    }
                }

                let obj = {} as any;
                self.get_form_property_value(obj, container_element);
                let current_value = obj[container_property];

                current_value.splice(Number(editor_property), 1);

                let schema = self.get_path_schema(container_path);
                
                container_element.outerHTML = await self.render_form_property(schema, container_property, current_value, container_path, container_color, container_container_type);
                
                self.convertInputDivsToCodemirror();

                await self.cb_emit({"@": self.get_form_value()});
            }
        }


        //@ts-expect-error
        if(!window["editor_onmousedown_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_onmousedown_"+this.ci_idx] = async function(ev: any) {
                self.drag_handle = ev.target;
            }
        }

        //@ts-expect-error
        if(!window["editor_ondragstart_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_ondragstart_"+this.ci_idx] = async function(ev: any) {
                let handle = ev.target.querySelector('[editor_drag="handle"]');
                if (handle.contains(self.drag_handle)) {
                    ev.dataTransfer.setData("text/plain", ev.target.id);
                }
                else {
                    ev.preventDefault();
                }
            }
        }

        //@ts-expect-error
        if(!window["editor_ondragover_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_ondragover_"+this.ci_idx] = async function(ev: any) {
                ev.preventDefault();           
                ev.dataTransfer.dropEffect = "move";
            }
        }

        //@ts-expect-error
        if(!window["editor_ondragover_child_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_ondragover_child_"+this.ci_idx] = async function(ev: any) {
                ev.preventDefault();
                let source_id = ev.srcElement.id;         
                let this_id = ev.currentTarget.id;

                let source_split = source_id.split(".");
                let source_pos = source_split[source_split.length-1];

                let this_split = this_id.split(".");
                let this_pos = this_split[this_split.length-1];

                let this_element = document.getElementById(this_id);

                //console.log("this_id "+this_id);
                if(this_element) {
                    let prev = this_element.parentElement?.querySelector(".editor-drop-target");
                    if(prev) {
                        prev.classList.remove("editor-drop-target");
                    }
                    this_element.classList.add("editor-drop-target")
                }
            }
        }

        //@ts-expect-error
        if(!window["editor_ondrop_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_ondrop_"+this.ci_idx] = async function(ev: any) {
                ev.preventDefault();             
                // Get the id of the target and add the moved element to the target's DOM
                const source_id = ev.dataTransfer.getData("text/plain");

                let loc_id = ev.target.id;

                let target = ev.target;
                while(target && target.getAttribute("editor_drag") != "drop") {
                    target = target.parentNode;
                    if(target.getAttribute("editor_drag") == "loc") {
                        loc_id = target.id;
                    }
                }

                let source_elem = document.getElementById(source_id);

                if(source_elem && (target.getAttribute("editor_drag") == "drop")) {
                    //console.log("drop "+source_id+ " into " + target.id + " parent "+ target.parentElement.id);

                    
                    let source_split = source_id.split(".");
                    let source_pos = Number(source_split[source_split.length-1]);

                    let loc_split = loc_id.split(".");
                    let loc_pos = Number(loc_split[loc_split.length-1]);

                    //container element
                    let container_element = target.parentNode;
                    let container_property = container_element.getAttribute("editor_property");
                    let container_path = container_element.getAttribute("editor_path");
                    let container_container_type = source_elem.getAttribute("container_type") as string;
                    let container_color = "editor-color-1";
                    for(let c of container_element.classList) {
                        if(c.indexOf("editor-color") == 0) {
                            container_color = c;
                        }
                    }

                    let obj = {} as any;
                    self.get_form_property_value(obj, container_element);
                    let current_value = obj[container_property];

                    let source_val = current_value[source_pos];

                    //take it from old pos
                    current_value.splice(source_pos, 1);
                    current_value.splice(loc_pos, 0, source_val);

                    let schema = self.get_path_schema(container_path);
                    
                    container_element.outerHTML = await self.render_form_property(schema, container_property, current_value, container_path, container_color, container_container_type);

                    self.convertInputDivsToCodemirror();

                    await self.cb_emit({"@": self.get_form_value()});
                }
            }
        }

        //@ts-expect-error
        if(!window["editor_prop_name_"+this.ci_idx]) {
            //@ts-expect-error
            window["editor_prop_name_"+this.ci_idx] = async function(input: any) {
                let form_element = input.parentElement.parentElement.parentElement;
                let new_prop_name = input.value;
                let path = form_element.getAttribute("editor_path");
                let path_parts = path.split(".");
                path_parts[path_parts.length - 1] = new_prop_name;
                let new_path = path_parts.join(".");
                form_element.setAttribute("editor_path", new_path);
                form_element.setAttribute("id", "_editor__"+new_path);
                form_element.setAttribute("editor_property", new_prop_name);

                await self.cb_emit({"@": self.get_form_value()});
            }
        }

        //console.log("form value: "+JSON.stringify(this.get_form_value()));
    }

    async render_form_property(schema: any, property: string, data: any, path: string, color_class: string, parent_container_type: string) {

        //console.log("render_form_property schema "+JSON.stringify(schema) + " property "+property+" data "+JSON.stringify(data) + " path "+path + " parent_container_type "+parent_container_type);
        //console.log("render_form_property "+path + " " +property);


        if(schema.type == undefined) {
            return "";
        }

        if(data === undefined || (typeof schema.default == "object" && data === "")) {
            data = schema.default;
        }

        let editor_type = schema.type;
        let container_type= "";
        if(schema.type == "any") {       
            if(Array.isArray(data)) {
                editor_type = "array";
            }
            else if(data && typeof data == "object") {
                editor_type = "object";
            }
            else {
                editor_type = typeof data;
            }
            container_type = editor_type;
        }
        else if(schema.type[schema.type.length - 1] == "]") {
            editor_type = "array";
            container_type = editor_type;
        }
        else if(schema.type == "object") {
            container_type = editor_type;
        }

        let is_template = data && typeof data == "string" && (data || '').indexOf("{{") != -1;
        if(is_template) {
            editor_type = "string";
        }

        let html = `<div id="_editor__${path}" class="editor-form-property ${color_class}" editor_property="${property}" editor_type="${editor_type}" editor_path="${path}" container_type="${container_type}" ${parent_container_type=="array"?` draggable="true" editor_drag="loc" ondragstart="editor_ondragstart_${this.ci_idx}(event)" ondragover="editor_ondragover_child_${this.ci_idx}(event)"`:""}>`;
        
        let render_id = "r"+this.brick_id + "$" + path;

        let title = property;
        if(schema.prop_editable) {
            title = `<input style="width:${property.length + 1}ch" class="editor-propname-input" value="${property}" onkeypress="this.style.width = ((this.value.length + 2)) + 'ch';" onchange="editor_prop_name_${this.ci_idx}(this)"/>`
        }

        let no_link = !this.enable_templating || (this.brick_name == this.composition_tree.name && path.split(".")[0] == "unit_ins");

        html += `<div class="editor-form-property-header"><div class="editor-property-title" tooltip="${schema.desc || ""}">${title}${(schema.desc) ? `<div class="c-template-form-info"><img src="https://s2.svgbox.net/materialui.svg?ic=info_outline&color=02366d" width="16" height="16" style="margin:-4px;padding-bottom:2px"></div>` : ""}</div>
            ${parent_container_type=="array"?`<div editor_drag="handle" class="editor-drag-handle" onmousedown="editor_onmousedown_${this.ci_idx}(event)"></div>`:""}
            <div>
                
                ${schema.type == "any" ? `<select class="editor-any-type-select" onchange="editor_any_type_change_${this.ci_idx}(this)">
                <option value="string" ${editor_type == "string" ? "selected" : ""}>string</option>
                <option value="number" ${editor_type == "number" ? "selected" : ""}>number</option>
                <option value="boolean" ${editor_type == "boolean" ? "selected" : ""}>boolean</option>
                <option value="object" ${editor_type == "object" ? "selected" : ""}>object</option>
                <option value="array" ${editor_type == "array" ? "selected" : ""}>array</option>
                </select>` : ""}

                ${(schema.type == "schema" && schema.sub) ? `<select editor_role="schema_type_select" onchange="editor_schema_type_change_${this.ci_idx}(this)">
                <option value="string" ${(!data || !data.type || data.type == "string") ? "selected" : ""}>string</option>
                <option value="number" ${data && data.type == "number" ? "selected" : ""}>number</option>
                <option value="boolean" ${data && data.type == "boolean" ? "selected" : ""}>boolean</option>
                <option value="object" ${data && data.type == "object" ? "selected" : ""}>object</option>
                <option value="string[]" ${data && data.type == "string[]" ? "selected" : ""}>string[]</option>
                <option value="object[]" ${data && data.type == "object[]" ? "selected" : ""}>object[]</option>
                <option value="any" ${data && data.type == "any" ? "selected" : ""}>any</option>
                </select>` : ""}

                ${schema.fromdata ? `<button class="c-template-form-button" style="padding: 2px 5px" fromdata='${JSON.stringify(schema.fromdata)}' onclick="cb_editor.fromdata${this.ci_idx}(this, '${path}')">From Data</button>`: ""} 
                
                ${(schema.type!="boolean"&&schema.type!="number"&&(schema.type!="string"||schema.options))?`<button id="${render_id}$edit" class="c-template-form-button-sec" style="padding: 2px 5px" onclick="cb_editor.expand${this.ci_idx}(this, '${path}')"><img src="https://s2.svgbox.net/materialui.svg?ic=content_paste&color=02366d" width="16" height="16" style="margin:-4px;padding-bottom:2px"></button>`:""}
                ${no_link ? "" : `<button id="${render_id}$link" class="c-template-form-button" style="padding: 2px 5px;margin-left:3px" onclick="cb_editor.addtag${this.ci_idx}(this, '${path}')"><img src="https://s2.svgbox.net/hero-solid.svg?ic=link&color=fff" width="16" height="16" style="margin:-4px;padding-bottom:2px"></button>`}
                ${parent_container_type=="array" && (schema.type == "any" ? schema.sub : true) ?`<button class="c-template-form-button" style="padding: 2px 5px" onclick="editor_any_item_del_${this.ci_idx}(this)"><img src="https://s2.svgbox.net/hero-outline.svg?ic=x&color=fff" width="16" height="16" style="margin:-4px;padding-bottom:2px"></button>`:""}
                ${((parent_container_type=="object" && (schema.type == "any" && schema.sub)) || (schema.type == "schema" && schema.sub)) ?`<button class="c-template-form-button" style="padding: 2px 5px" onclick="editor_any_prop_del_${this.ci_idx}(this)"><img src="https://s2.svgbox.net/hero-outline.svg?ic=x&color=fff" width="16" height="16" style="margin:-4px;padding-bottom:2px"></button>`:""}
                

            </div>
        </div>`;

        let next_color = this.next_color_class(color_class);

        let array_drop_zone = container_type=="array"?` editor_drag="drop" ondrop="editor_ondrop_${this.ci_idx}(event)" ondragover="editor_ondragover_${this.ci_idx}(event)"`:"";
        
        if(typeof data == "string" && data.indexOf("{{") != -1) {
            let node = { id: path, path: path, schema: schema, value: (data === undefined || data === "") ? (schema.default === undefined ? '' : schema.default) : data, desc: schema.desc || "", label: property };

            //console.log("editor_type "+editor_type + " container_type " + container_type + " parent_container_type " + parent_container_type + " node "+JSON.stringify(node));

            html += await this.render_value_editor_html(node); //data;
        }
        else if(schema.type == "object") {
            html += `<div class="editor-form-properties">`;
            for(let prop in schema.properties) {
                let dp = undefined;
                if(data && typeof data == "object") {
                    dp = data[prop];
                }
                html += await this.render_form_property(schema.properties[prop], prop, dp, path + "." +prop, next_color, container_type);
            }
            html += `</div>`;
        }
        else if(schema.type == "any") {
            if(data && Array.isArray(data)) {
                html += `<div class="editor-form-properties" editor_path="${path}" ${array_drop_zone}>`;
                for(let i = 0; i < data.length; i++) {
                    html += await this.render_form_property({ type: "any", sub: true }, i + "", data[i], path + "." +i, next_color, container_type);
                }
                html += `<div><button class="c-template-form-button" onclick="editor_any_item_add_${this.ci_idx}(this)">+</button></div>`;

                html += `</div>`;
            }
            else if(data && typeof data == "object") {
                html += `<div class="editor-form-properties" editor_path="${path}">`;
                for(let p in data) {
                    html += await this.render_form_property({ type: "any", prop_editable: true, sub: true }, p, data[p], path + "." +p, next_color, container_type);
                }
                html += `<div><button class="c-template-form-button" onclick="editor_any_prop_add_${this.ci_idx}(this)">+</button><input class="editor-prop-add-input" type="text" placeholder="New Property Name" onkeyup="editor_any_prop_add_enter_${this.ci_idx}(this, event)"></input></div>`;

                html += `</div>`;
            }
            else {
                let node = { id: path, path: path, schema: schema, value: (data === undefined || data === "") ? (schema.default === undefined ? '' : schema.default) : data, desc: schema.desc || "", label: property };
                html += await this.render_value_editor_html(node); //data;
            }
        }
        else if(schema.type == "schema") {
            if(typeof data == "object") {
                if(!schema.sub) {
                    //the base inputs
                    html += `<div class="editor-form-properties" editor_path="${path}">`;
                    for(let input in data) {

                        //console.log("NOSUB path " +path +" input "+input + " data "+JSON.stringify(data));

                        html += await this.render_form_property({ type: "schema", sub: true, prop_editable: true }, input, data[input], path+"."+input, next_color, container_type);
                    }

                    html += `<div><button class="c-template-form-button" onclick="editor_schema_prop_add_${this.ci_idx}(this, false)">+</button><input class="editor-prop-add-input" type="text" placeholder="Property Name"></input></div>`;

                    html += `</div>`;
                }
                else {     

                    html += `desc: <input editor_role="schema_desc" value="${data.desc===undefined?"":data.desc}" onchange="editdesc_${this.ci_idx}()"/>`;

                    if(data.type == "object" || data.type == "object[]") {
                        let properties = data.properties || {};
                        html += "properties:"
                        html += `<div class="editor-form-properties" editor_path="${path}">`;
                        for(let p in properties) {
                            html += await this.render_form_property({ type: "schema", sub: true, prop_editable: true }, p, properties[p], path + ".properties." +p, next_color, container_type);
                        }

                        html += `<div><button class="c-template-form-button" onclick="editor_schema_prop_add_${this.ci_idx}(this, true)">+</button><input class="editor-prop-add-input" type="text" placeholder="Property Name"></input></div>`;

                        html += `</div>`;
                    }
                    else {
                        if(path.split(".")[0].indexOf("out") != -1) {
                            //html += `value: <input editor_role="schema_value" value="${data.value === undefined ? "" : data.value}" />`;
                            let value_path = path + ".value";

                            //console.log("value_path "+value_path + " data.value " + data.value);

                            let node = { id: value_path, path: value_path, schema: schema, value: (data.value === undefined || data.value === "") ? (schema.default === undefined ? '' : schema.default) : data.value, desc: "", label: property };
                            html += `value: `;
                            html += await this.render_value_editor_html(node);
                        }
                        else {
                            //html += `default: <input editor_role="schema_default" value="${data.default === undefined ? "" : data.default}" />`;
                            //console.log("render default "+JSON.stringify(data.default === undefined ? "" : data.default)+ " container_type "+container_type);

                            html += await this.render_form_property({ type: "any", sub: true, prop_editable: false }, "default", data.default === undefined ? "" : data.default, path + ".default", next_color, container_type);
                        }
                    }
                }
            }
        }
        else if(schema.type[schema.type.length - 1] == "]") {
            let array_of_type = "";
            for(let s = schema.type.length - 1; s >= 0; s--) {
                if(schema.type[s] == "[") {
                    array_of_type = schema.type.substring(0, s);
                    break;
                }
            }
            data = data || [];
            if(data && Array.isArray(data)) {
                let child_schema = Clone(schema);
                child_schema.type = array_of_type;
                child_schema.desc = "";
                if(schema.fromdata) {
                    delete child_schema.fromdata;
                }
                html += `<div class="editor-form-properties" ${array_drop_zone}>`;
                
                for(let i = 0; i < data.length; i++) {
                    html += await this.render_form_property(child_schema, i + "", data[i], path + "." +i, next_color, container_type);
                }

                html += `<div><button class="c-template-form-button" onclick="editor_any_item_add_${this.ci_idx}(this)">+</button></div>`;

                html += `</div>`;
            }
        }
        else if(schema.type == "string" && schema.options && (data || '').indexOf("{{") == -1) {
            let node = { id: path, path: path, schema: schema, value: data, desc: schema.desc || "", label: property };
            let value_id = this.get_value_id(node);
            html += `<textarea class='c-input c-template-form-textarea hidden' id="${value_id}" class="c-input" rows="1" placeholder="">${(data === undefined) ? (schema.default || '') : data}</textarea>`;

            html += `<div><select class="cbeditor-select" onchange="selectvalue_${this.ci_idx}(this)">`;
               
            let keys = null;
            let selected_value = '';
            for(let o = 0; o < schema.options.length; o++) {

                if(typeof schema.options[o] == 'object') {
                    if(keys === null) {
                        keys = Object.keys(schema.options[o]);
                    }

                    if(o == 0) {
                        selected_value = schema.options[o][keys[0]];
                    }
                    else if(data == schema.options[o][keys[0]]) {
                        selected_value = data;
                    }

                    let selected = data == schema.options[o][keys[0]] ? 'selected' : '';

                    html += `<option value="${schema.options[o][keys[0]]}" ${selected}>${schema.options[o][keys.length > 1 ? keys[1] : keys[0]]}</option>`;
                }
                else {

                    if(o == 0) {
                        selected_value = schema.options[o];
                    }
                    else if(data == schema.options[o]) {
                        selected_value = data;
                    }

                    let selected = data == schema.options[o] ? 'selected' : '';

                    html += `<option value="${schema.options[o]}" ${selected}>${schema.options[o]}</option>`
                }
            }
            html += "</select></div>";
        }
        else {
            let node = { id: path, path: path, schema: schema, value: (data === undefined) ? (schema.default === undefined ? '' : schema.default) : data, desc: schema.desc || "", label: property };
            html += await this.render_value_editor_html(node); //data;
        }

        // if(editor_type != "object" && editor_type != "array" && editor_type != "schema" && schema.type != "any") {
        //     html += `<div class="editor-form-property-desc">${schema.desc || ""}</div>`;
        // }

        return html +"</div>";
    }

    get_form_value() {
        let ret = {} as any;
        let form_container = document.getElementById(`${this.brick_id}$form`);
        if(form_container) {
            let children = form_container.children;
            for(let c = 0; c < children.length; c++) {
                this.get_form_property_value(ret, children[c], true);
            }         
        }

        //console.log("get_form_value "+JSON.stringify(ret));

        return ret;
    }

    get_form_property_value(form_object: any, editor_element: any, is_root = false) {
        let editor_type = editor_element.getAttribute("editor_type");
        let editor_property = editor_element.getAttribute("editor_property");
        let editor_path = editor_element.getAttribute("editor_path");

        //console.log("get_form_property_value "+editor_path + " editor_type "+ editor_type + " editor_property "+editor_property+" is_root "+is_root);

        if(editor_type == "array") {
            let child_container = editor_element.querySelector(".editor-form-properties");
            if(child_container) {
                form_object[editor_property]  = [];
                let children = child_container.children;
                for(let c = 0; c < children.length; c++) {
                    this.get_form_property_value(form_object[editor_property], children[c]);
                }

                let schema = this.get_path_schema(editor_path);
                if(schema.type != "any" && (schema.default === undefined || JSON.stringify(schema.default) == "[]") && JSON.stringify(form_object[editor_property]) == "[]") {
                    delete form_object[editor_property];
                }
            }
        }
        else if(editor_type == "object") {
            let child_container = editor_element.querySelector(".editor-form-properties");
            if(child_container) {
                form_object[editor_property] = {};
                let children = child_container.children;
                for(let c = 0; c < children.length; c++) {
                    this.get_form_property_value(form_object[editor_property], children[c]);
                }

                let schema = this.get_path_schema(editor_path);
                if(schema.type != "any" && (schema.default === undefined || JSON.stringify(schema.default) == "{}") && JSON.stringify(form_object[editor_property]) == "{}") {
                    delete form_object[editor_property];
                }
            }
        }
        else if(editor_type == "schema") {
            if(editor_path == editor_property) {
                let child_container = editor_element.querySelector(".editor-form-properties");
                form_object[editor_property] = form_object[editor_property] || {};
                if(child_container) {
                    let children = child_container.children;
                    for(let c = 0; c < children.length; c++) {
                        let child_property = children[c].getAttribute("editor_property");
                        if(child_property) {
                            this.get_form_property_value(form_object[editor_property], children[c]);
                        }
                    }
                }
            }
            else {
                let type_select = editor_element.querySelector('[editor_role="schema_type_select"]');
                let type = type_select.value;
                let desc_input = editor_element.querySelector('[editor_role="schema_desc"]');
                let desc = "";
                if(desc_input) {
                    desc = desc_input.value;
                }

                if(type == "object" || type == "object[]") {

                    form_object[editor_property] = { type: type, properties: {} };
                    if(desc !== "") {
                        form_object[editor_property].desc = desc;
                    }
                    let child_container = editor_element.querySelector(".editor-form-properties");
                    if(child_container) {
                        let children = child_container.children;
                        for(let c = 0; c < children.length; c++) {
                            let child_property = children[c].getAttribute("editor_property");
                            if(child_property) {
                                this.get_form_property_value(form_object[editor_property].properties, children[c]);
                            }
                        }
                    }
                }
                else {
                    
                    form_object[editor_property] = { type: type };
                    if(desc !== "") {
                        form_object[editor_property].desc = desc;
                    }

                    if(editor_path.split(".")[0].indexOf("out") != -1) {
                        let ta = editor_element.querySelector(".c-template-form-textarea");
                        if(ta) {
                            let val = ta.value;
                            form_object[editor_property].value = val;
                        }
                    }
                    else {
                        let default_object = editor_element.querySelector('[editor_property="default"]');
                        if(default_object) {
                            this.get_form_property_value(form_object[editor_property], default_object);
                        }
                    }
                
                }
            }

        }
        else {
            let ta = editor_element.querySelector(".c-template-form-textarea");
            let is_any = editor_element.querySelector(".editor-any-type-select");
            if(ta) {
                let val = ta.value;
                let schema = this.get_path_schema(editor_path);
                if(val === undefined) {
                    val = "";
                }

                if(val !== undefined || (is_any && editor_path != editor_property)) {
                    //console.log("editor_path " + editor_path + " val " +val + " schema.default " + schema.default);
                    if(editor_type == "number") {
                        if(!is_root && (schema.type != "any" && val == "" && schema.default === undefined)) {
                            return;
                        }
                        val = Number(val);
                    }
                    else if(editor_type == "boolean") {
                        if(val == "false") {
                            val = false;
                        }
                        else if(val.indexOf("{{") != -1) {
                            //leave as is
                        }
                        else {
                            val = Boolean(val);
                        }
                        if(!is_root && (schema.type != "any" && val === false && (schema.default || false) == false)) {
                            return;
                        }
                    }
                    else if(!is_root && (val === undefined || (schema.type != "any" && val == "" && (schema.default || "") == ""))) {
                        return "";
                    }

                    if(Array.isArray(form_object)) {
                        form_object.push(val);
                    }
                    else {
                        form_object[editor_property] = val;
                    }
                }
            }
        }
    }

    get_path_schema(path: string) {
        let path_parts = TemplateUtil.GetTermParts(path);
        if(this.schema) {
            return this.get_prop_schema(path_parts, 1, this.schema[path_parts[0]]);
        }
    }

    get_prop_schema(path_parts: string[], at: number, schema: any): any {
        if(at == path_parts.length) {
            return schema;
        }

        if(schema.type == "object") {
            return this.get_prop_schema(path_parts, at + 1, schema.properties[path_parts[at]]);
        }
        else if(schema.type[schema.type.length-1] == "]") {
            let array_of_type = "";
            for(let s = schema.type.length - 1; s >= 0; s--) {
                if(schema.type[s] == "[") {
                    array_of_type = schema.type.substring(0, s);
                    break;
                }
            }
            let child_schema = Clone(schema);
            child_schema.type = array_of_type;
            child_schema.desc = "";
            return child_schema;
        }

        return schema;
    }

    get_form_element_by_path(path: string) {
        let path_parts = TemplateUtil.GetTermParts(path);
        let found = null as any;
        let form_container = document.getElementById(`${this.brick_id}$form`);
        if(form_container) {
            let children = form_container.children;
            for(let c = 0; c < children.length; c++) {
                found = this.find_form_element(children[c], path_parts, 0);
                if(found) {
                    break;
                }
            }            
        }

        return found;
    }


    find_form_element(editor_element: any, path_parts: string[], at: number) : any {
        let editor_type = editor_element.getAttribute("editor_type");
        let editor_property = editor_element.getAttribute("editor_property");

        if(editor_property == path_parts[at]) {
            if(at == path_parts.length - 1) {
                return editor_element;
            }
            else {
                if(editor_type == "array" || editor_type == "object") {
                    let child_container = editor_element.querySelector(".editor-form-properties");
                    if(child_container) {
                        let children = child_container.children;
                        at++;
                        for(let c = 0; c < children.length; c++) {
                        let found = this.find_form_element(children[c], path_parts, at);
                            if(found) {
                                return found;
                            }
                        }
                    }
                }
                else if(editor_type == "schema") {

                    if(at == path_parts.length - 2 && path_parts[at + 1] == "value") {
                        return editor_element;
                    }

                    //console.log("find_form_element (schema) editor_type "+editor_type+" editor_property "+editor_property+" path_parts "+JSON.stringify(path_parts) + " at "+at);
                    let header_container = editor_element.querySelector('.editor-form-property-header');
                    let schema_type_select = header_container.querySelector('[editor_role="schema_type_select"]');
                    let child_container = editor_element.querySelector(".editor-form-properties");
                    if(child_container) {
                        if(schema_type_select) { //sub
                            let schema_type = schema_type_select.value;
                            if(schema_type == "object" || schema_type == "object[]") {
                                at++; //skip "properties"
                            }
                        }
         
                        let children = child_container.children;
                        at++;
                        for(let c = 0; c < children.length; c++) {
                            let found = this.find_form_element(children[c], path_parts, at);
                            if(found) {
                                return found;
                            }
                        }
                        
                    }
                }
            }
        }
        return null;
    }

    async set_form_property_value(path: string, value: any) {

        //console.log("set_form_property_value path "+path+" value "+value);

        let found = this.get_form_element_by_path(path);

        if(found) {
            let textarea = found.querySelector(".c-template-form-textarea") as HTMLTextAreaElement;
            if(textarea) {
                textarea.value = value;
            }

            let tageditor = found.querySelector(".editor-value-editor");
            if(tageditor) {
                let node = { id: path, path: path, schema: {}, value: value, desc: "" };
                tageditor.outerHTML = await this.get_tag_editors_html(node);
            }

            if(value.indexOf("{{") != -1) {

                let form_element = await this.get_form_element_by_path(path);

                let editor_property = form_element.getAttribute("editor_property");
                let editor_path = form_element.getAttribute("editor_path");
                let container_type = form_element.getAttribute("container_type");

                //console.log("set_form_property_value {{ path "+path+" value "+value+ " editor_path "+editor_path + " editor_property " + editor_property +" container_type "+container_type);


                let editor_color = "editor-color-1";
                for(let c of form_element.classList) {
                    if(c.indexOf("editor-color") == 0) {
                        editor_color = c;
                    }
                }

                let schema = this.get_path_schema(editor_path);
                if(schema.type == "schema") {
                    value = this.get_schema_path_value(editor_path, value);
                    schema.sub = true;
                    schema.prop_editable = true;
                }

                //console.log("editor_property "+editor_property+ " editor_path " + editor_path + " container_type " + container_type + " schema "+JSON.stringify(schema) + " value "+JSON.stringify(value));

                form_element.outerHTML = await this.render_form_property(schema, editor_property, value, editor_path, editor_color, "string");  
            }
            else {
                let select = found.querySelector("select");
                if(select) {
                    select.value = value;
                }
            }
        }
    }

    get_schema_path_value(path: string, value: string) {
        let scema_value = {} as any;
        //let path_parts = path.split(".");
        let o = scema_value;
        // for(let i = 2; i < path_parts.length; i++) {
        //     o[path_parts[i]] = {};
        //     o = o[path_parts[i]];
        // }
        o["type"] = "string";
        o["value"] = value;
        return scema_value;
    }

    next_color_class(color_class: string) {
        if(color_class == "editor-color-1") {
            return "editor-color-2";
        }
        else {
            return "editor-color-1";
        }
    }

    ////////////////////////////////////////////////////////////////////////////////

    async render_value_editor_html(node: ui_node) : Promise<string> {
        let html = "";

        let value_id = this.get_value_id(node);

        //raw view
        html += `<textarea class='c-input c-template-form-textarea hidden' id="${value_id}" class="c-input" rows="1" placeholder="">${(node.value === undefined) ? '' : node.value}</textarea>`;

        html += `<div id="${value_id}_tageditor">`;
        html += await this.get_tag_editors_html(node);
        html += "</div>"

        return html;
    }

    async update_value_editor_value(node: ui_node) {
        let value_id = this.get_value_id(node);

        let textarea = document.getElementById(value_id) as HTMLTextAreaElement;
        if(textarea) {
            textarea.value = node.value;
        }

        let tageditor = document.getElementById(value_id +"_tageditor");
        if(tageditor) {
            tageditor.innerHTML = await this.get_tag_editors_html(node);
        }

        await this.cb_emit({"@": this.get_form_value()});
    }

    async get_tag_editors_html(node: ui_node) {
        let html = "";
        let self = this;

        let value_id = this.get_value_id(node);

        let e_id = "e_"+value_id;

        //tag editor view
        let tags = [] as string[];

        //This is so an editor will be rendered for adding a tag
        let source = "''";
        if(this.sources && this.sources.length > 0) {
            source = this.sources[0].value;
        }
        tags.push(`{{${source}}}`);

        if(node.value) {
            TemplateUtil.ExtractTags(node.value, tags);
        }

        //@ts-expect-error
        window.tag_map = window.tag_map || {} as { [tag_id:string] : string };

        let tev = node.value === undefined ? '' : node.value;

        let escaped_tev = CBWebUtil.escapeHtml(tev);

        // if(node.value) {
        //     console.log("node.value "+node.value);
        // }

        // let ti = 0;
        // for(let t in tags) {
        //     let tag = tags[t];
        //     let tag_content = tag.substring(2, tag.length - 2);

        //     let tag_id = node.id + "_" + t;

        //     //@ts-expect-error
        //     window.tag_map[tag_id] = tag_content;

        //     //tag 0 is the "new tag" editor
        //     if(ti > 0) {
        //         let tag_pill = `<tag class='editor-tag-pill' contenteditable='false' onclick="cb_editor.edittag${this.ci_idx}(this, '${node.path}')">${tag_content}</tag>`;
        //         let escaped_tag = CBWebUtil.escapeHtml(tag);
        //         escaped_tev = escaped_tev.replace(escaped_tag, tag_pill);
        //     }

        //     if(!(<any>window).cb_editor["edittag"+this.ci_idx]) {
        //         (<any>window).cb_editor["edittag"+this.ci_idx] = async function(element: any, node_path: string) {

        //             self.current_path = node_path;

        //             let tag_content = element.innerHTML;
        //             self.pre_edit_tag_contents = tag_content;

        //             (<any>window).t_selection = undefined;

        //             let mod1 = document.getElementById(self.brick_id+"$mod1_brick$body");
        //             if(mod1) {
        //                 mod1.innerHTML = await self.render_tag_editor(tag_content);
        //             }

                    
        //             (<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("data", { node_path: node_path });
        //             (<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("show_no_animate", 1);      
        //         }
        //     }

            if(!(<any>window).cb_editor["addtag"+this.ci_idx]) {
                (<any>window).cb_editor["addtag"+this.ci_idx] = async function(element: any, node_path: string) {
                    let textbox = element.parentElement.parentElement.parentElement.querySelector(".editor-value-editor");

                    let textbox_path = "";
                    if(textbox) {
                        let s = textbox.id.split("__");
                        textbox_path = s[s.length-1];
                    }
                    
                    let add_text = "{{}}";
                    let cursor_plus = 2;
                    let head_plus = 0;

                    if(!textbox || textbox_path != node_path) {
                
                        //select element, change to a tageditor

                        let form_element = element.parentElement.parentElement.parentElement;
                        let form_element_id = form_element.id;

                        let editor_property = form_element.getAttribute("editor_property");
                        let editor_path = form_element.getAttribute("editor_path");
                        let container_type = form_element.getAttribute("container_type");
                        let editor_color = "editor-color-1";
                        for(let c of form_element.classList) {
                            if(c.indexOf("editor-color") == 0) {
                                editor_color = c;
                            }
                        }

                        let schema = self.get_path_schema(editor_path);

                        let val = "{{"; //Can't be empty, else it wil stay a select
                        add_text = "}}";
                        cursor_plus = 0;
                        head_plus = 2;

                        form_element.outerHTML = await self.render_form_property(schema, editor_property, val, editor_path, editor_color, container_type);   
                        
                        self.convertInputDivsToCodemirror();

                        form_element = document.getElementById(form_element_id);

                        textbox = form_element.querySelector(".editor-value-editor");
                    }
                                  
                    if(textbox) {

                        //textbox.focus();
                        let editor = self.editors_by_e_id[textbox.id].editor;
                        if(editor) {
                            let head = self.editors_by_e_id[textbox.id].last_head;
                            if(head == -1) {
                                editor.dispatch(
                                    editor.state.update({
                                        selection: {
                                            anchor: editor.state.doc.length, // How do I get anchor by column and row
                                        }
                                    })
                                );
                                head = editor.state.doc.length;
                            }

                            //console.log("get head "+head + " " +textbox.id);

                            editor.focus();
                
                            // //TODO check if not inside tag already....

                            const transaction = editor.state.update({
                                changes: {
                                    from: head,
                                    insert: add_text,
                                },
                                // the next 2 lines will set the appropriate cursor position after inserting the new text.
                                selection: { anchor: head + cursor_plus },
                                scrollIntoView: true,
                            });

                            if (transaction) {
                                editor.dispatch(transaction);
                            }

                            startCompletion(editor);
                            //self.drawAutoComplete(textbox);
                        }
                    }
                }
            }

        //             self.current_path = node_path;
        //             //self.set_node_from_contenteditable(node.path);

        //             self.pre_edit_tag_contents = '';

        //             let textbox = element.parentElement.parentElement.parentElement.querySelector(".editor-value-editor");
        //             if(textbox) {
        //                 textbox.focus();

        //                 //@ts-expect-error
        //                 (<any>window).t_selection = window.getSelection().getRangeAt(0);
        //             }

        //             let tag_content = "''";
        //             let sources = self.get_source_list();
        //             if(self.sources && self.sources.length > 0) {
        //                 tag_content = sources[0];
        //             }
        //             let mod1 = document.getElementById(self.brick_id+"$mod1_brick$body");
        //             if(mod1) {
        //                 mod1.innerHTML = await self.render_tag_editor(tag_content);
        //             }

                    
        //             (<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("data", { node_path: node_path });
        //             (<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("show_no_animate", 1);      
        //         }
        //     }

            if(!(<any>window).cb_editor["expand"+this.ci_idx]) {
                (<any>window).cb_editor["expand"+this.ci_idx] = async function(element: any, node_path: string) {

                    self.current_path = node_path;

                    let ta = element.parentNode.parentNode.parentNode.querySelector("textarea");
                    let value = "";
                    let asjson = false;
                    if(ta) {
                        let s = ta.id.split("__");
                        let textbox_path = s[s.length-1];
                        if(textbox_path != node_path) {
                            let editor_element = element.parentElement.parentElement.parentElement;
                            let obj = {} as any;
                            self.get_form_property_value(obj, editor_element);
                            let s2 = node_path.split(".");
                            value = JSON.stringify(obj[s2[s2.length-1]], null, 2);
                            asjson = true;
                        }
                        else {
                            value = ta.value || "";
                        }
                    }

                    let mod2 = document.getElementById(self.brick_id+"$mod2_brick$body");
                    if(mod2) {
                        mod2.innerHTML = `<c-editor-code style="width:100%" id="${self.brick_id}$expanded" ins='{ "cfg": { "value": "" }}'></c-editor-code><div><input type="checkbox" id="asjson" name="asjson" ${asjson ? "checked" : ""}/><label for="asjson">Parse as JSON into Object</label></div>`;
                    }

                    let language = "";
                    if(node_path.endsWith("function")) {
                        language = "javascript";
                    }
                    else if(node_path.indexOf("html") != -1) {
                        language = "html";
                    }

                    //console.log("node_path "+node_path + " ta "+ta+" mod2 " +mod2);

                    
                    (<any>window).so_bricks[self.brick_id+"$mod2_brick"].cb_event("cfg", { "title": self.brick_name + " - " + node_path, "cancel_button": "Cancel", "ok_button":"OK" });
                    (<any>window).so_bricks[self.brick_id+"$mod2_brick"].cb_event("data", { node_path: node_path });
                    (<any>window).so_bricks[self.brick_id+"$mod2_brick"].cb_event("show_no_animate", 1);    
        
                    (<any>window).so_bricks[self.brick_id+"$expanded_brick"].cb_event("cfg", {"language":language});   
                    (<any>window).so_bricks[self.brick_id+"$expanded_brick"].cb_event("token_list", self.sources);                   
                    (<any>window).so_bricks[self.brick_id+"$expanded_brick"].cb_event("value", value);
                    (<any>window).so_bricks[self.brick_id+"$expanded_brick"].cb_event("focus", value);
                }
            }

            if(!(<any>window).cb_editor["fromdata"+this.ci_idx]) {
                (<any>window).cb_editor["fromdata"+this.ci_idx] = async function(element: any, path: string) {
                    let fromdata = JSON.parse(element.getAttribute("fromdata"));

                    //every time you run in the debugger or save a change! it creats a new cid _1, _2 .... 
                    //so we use this highest one
                    let cids = Object.keys(self.context.composition_runners);
                    let hi_cid = cids[cids.length-1];

                    let brick_ids = self.context.composition_runners[hi_cid].get_brick_ids(self.brick_name, "");
                    
                    let ci = (<any>window).codebricks_context.bricks[brick_ids[0]];

                    let source_data = drill(fromdata.source, ci.last_ins);

                    if(!confirm(fromdata.desc + " Proceed?")) {
                        return;
                    }

                    if(fromdata.type == "columns") {
                        if(source_data && source_data.length > 0) {
                            let columns = Object.keys(source_data[0]);
                            let mapping_col = "";
                            let dest_data = [];
                            for(let column of columns) {
                                let entry = {} as any;
                                
                                for(let mapping of fromdata.mappings) {
                                    if(mapping.transform == "pretty") {
                                        entry[mapping.dest] = Sugar.String.titleize(column);
                                    }
                                    else {
                                        entry[mapping.dest] = column;
                                    }
                                    if(!mapping_col) {
                                        mapping_col = mapping.dest;
                                    }
                                }
                                dest_data.push(entry);
                            }

                            if(!mapping_col) {
                                return;
                            }
                           
                            let editor_element = element.parentElement.parentElement.parentElement;
                            let editor_property = editor_element.getAttribute("editor_property");
                            let editor_path = editor_element.getAttribute("editor_path");
                            let container_type = editor_element.getAttribute("container_type");
            
                            let editor_color = "editor-color-1";
                            for(let c of editor_element.classList) {
                                if(c.indexOf("editor-color") == 0) {
                                    editor_color = c;
                                }
                            }

                            let obj = {} as any;
                            self.get_form_property_value(obj, editor_element);
                            let current_value = obj[editor_property] || [];

                            for(let newcol of dest_data) {
                                let has = false;
                                for(let hascol of current_value)  {
                                    if(newcol[mapping_col] == hascol[mapping_col]) {
                                        has = true;
                                        break;
                                    }                                   
                                }
                                if(!has) {
                                    current_value.push(newcol);
                                }
                            }
            
                            let schema = self.get_path_schema(editor_path);
                            
                            editor_element.outerHTML = await self.render_form_property(schema, editor_property, current_value, editor_path, editor_color, container_type);
            
                            await self.cb_emit({"@": self.get_form_value()});
                        }
                    }

                    
                }
            }

        //     ti++;
        // }


        
        //html += `<div class="editor-value-editor" rows="1" contenteditable="true" id="${e_id}" onblur="editvalue_${this.ci_idx}(this)" oncopy="editor_value_copy(event)" onpaste="editor_value_paste(event)" oninput="oninput_${this.ci_idx}(event, this)" onclick="oninput_${this.ci_idx}(event, this)">${escaped_tev}</div>`;
        html += `<div class="editor-value-editor" id="${e_id}" lang="${node.schema.syntax || ""}" static="${node.schema.static || ""}">${escaped_tev}</div>`;


        // //@ts-expect-error$
        // if(!window["oninput_"+this.ci_idx]) {
        //     //@ts-expect-error
        //     window["oninput_"+this.ci_idx] = async function(event, ce_div) {
        //         let value = CBWebUtil.unescapeHtml(ce_div.innerHTML);

        //         //console.log("oninput value "+value+ " event " + event.currentTarget.offsetTop + " " + event.currentTarget.offsetWidth);

        //         self.drawAutoComplete(ce_div);
        //     }
        // }


        //@ts-expect-error
        if(!window["selectvalue_"+this.ci_idx]) {
            //@ts-expect-error
            window["selectvalue_"+this.ci_idx] = async function(select) {
                let textarea= select.parentElement.parentElement.querySelector("textarea");
                if(textarea) {
                    let selected_value = select.value;
                    textarea.value = selected_value;
                    await self.cb_emit({"@": self.get_form_value()});
                }
            }
        }


        //@ts-expect-error
        if(!window["editvalue_"+this.ci_idx]) {
            //@ts-expect-error
            window["editvalue_"+this.ci_idx] = async function(ce_div) {

                // let has_popout = ce_div.querySelector(".editor-tag-popout-container");
                // if(has_popout) {
                //     has_popout.remove();
                // }

                let textarea= ce_div.parentElement.parentElement.querySelector("textarea");
                // console.log("editvalue_ "+node.id+". content: "+ce_div.innerHTML);
                if(textarea) {
                //     let pilled_value = CBWebUtil.unescapeHtml(ce_div.innerHTML);

                //     let tag_value = Sugar.String.removeTags(pilled_value, "tag", function(all, content) {
                //         return "{{" + content + "}}";
                //     });
                    let editor = self.editors_by_e_id[ce_div.id].editor;     
                    let tag_value = editor.state.doc.toString();

                    textarea.value = tag_value;
                    node.value = tag_value;
                    await self.cb_emit({"@": self.get_form_value()});
                }

            }
        }

        //@ts-expect-error
        if(!window["editdesc_"+this.ci_idx]) {
            //@ts-expect-error
            window["editdesc_"+this.ci_idx] = async function() {
                await self.cb_emit({"@": self.get_form_value()});
            }
        }

        // //@ts-expect-error
        // if(!window["editor_hide_mod1_"+this.ci_idx]) {
        //     //@ts-expect-error
        //     window["editor_hide_mod1_"+this.ci_idx] = function() {
        //         (<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("hide", 1)
        //     }
        // }

        // //@ts-expect-error
        // if(!window["editor_value_copy"]) {
        //     //@ts-expect-error
        //     window["editor_value_copy"] = function(event) {
        //         //@ts-expect-error
        //         const range = window.getSelection().getRangeAt(0),
        //             rangeContents = range.cloneContents(),
        //             helper = document.createElement("div");

        //         helper.appendChild(rangeContents);

        //         let tag_value = CBWebUtil.unescapeHtml(helper.innerHTML);

        //         tag_value = Sugar.String.removeTags(tag_value, "tag", function(all, content) {
        //             return "{{" + content + "}}";
        //         });

        //         event.clipboardData.setData("text/plain", tag_value);
        //         event.preventDefault();
        //     }
        // }

        ////@ts-expect-error
        // if(!window["editor_value_paste"]) {
        //     //@ts-expect-error
        //     window["editor_value_paste"] = function(event) {
        //         //alert("paste");

        //         //@ts-expect-error
        //         let clipboardData = event.clipboardData || window.clipboardData;
        //         let pastedData = clipboardData.getData('Text');

        //         let pastedHtml = clipboardData.getData('text/html');

        //         //console.log(pastedData + " ------ " +pastedHtml);

        //         if(pastedData || pastedHtml) {

        //             event.stopPropagation();
        //             event.preventDefault();

        //             //self.insertTextAtCaret(CBWebUtil.escapeHtml(pastedData));
        //             self.insertTextAtCaret(pastedData || pastedHtml);
        //         }
                
        //     }
        //}

        return html;
    }

    convertInputDivsToCodemirror() {
        let value_editors = document.querySelectorAll('.editor-value-editor');
        if(value_editors) {
            for(let e = 0; e < value_editors.length; e++) {
                let value_editor = value_editors[e] as HTMLElement;

                if(this.editors_by_e_id[value_editor.id]) {
                    //console.log("Already have editor for "+value_editor.id);
                    //continue;
                }

                this.editors_by_e_id[value_editor.id] = { has: true, editor: null, last_head: -1 };
                //console.log("convertToCodeMirror "+value_editor.id);
                this.convertToCodeMirror(value_editor);

                //    
                           
            }
        }
    }

    convertToCodeMirror(value_editor: HTMLElement) {

        if(value_editor.children.length > 0 && value_editor.children[0].classList.contains("cm-editor")) {
            //console.log("This element is already a cm editor");
            return;
        }

        let self = this;

        let doc = CBWebUtil.unescapeHtml(value_editor.innerHTML);
        value_editor.innerHTML = "";

        let lang = value_editor.getAttribute("lang");
        let is_static = value_editor.getAttribute("static");

        let extensions = [] as any;

        if(!is_static) {
            let config = {
                props: [
                    //   // Add basic folding/indent metadata
                    //   foldNodeProp.add({Conditional: foldInside}),
                    //   indentNodeProp.add({Conditional: cx => {
                    //     let closed = /^\s*\{% endif/.test(cx.textAfter)
                    //     return cx.lineIndent(cx.node.from) + (closed ? 0 : cx.unit)
                    //   }})
                ],
                // wrap: parseMixed(node => {
                //     return node.type.isTop ? {
                //     parser: htmlLanguage.parser,
                //     overlay: node => node.type.name == "Text"
                //     } : null
                // })
            } as any;

            if(lang == "html") {
                config.wrap = parseMixed(node => {
                    return node.type.isTop ? {
                    parser: htmlLanguage.parser,
                    overlay: node => node.type.name == "Text"
                    } : null
                });
            }

            const mixedCBTemplateParser = cbtemplateParser.configure(config);

            const cbtemplateLanguage = LRLanguage.define({parser: mixedCBTemplateParser})

            const cbtemplateAutocompletion = cbtemplateLanguage.data.of({
                autocomplete: function cbTemplateCompletionSource(context: any) {
                    let {state, pos} = context;
                    let nodeBefore = syntaxTree(state).resolveInner(pos, -1);

                    let node_type = nodeBefore.type.name;

                    let word = context.matchBefore(/\w*/);
                    //if (word.from == word.to && !context.explicit)
                    //    return null
                    //console.log("type: "+node_type+" value "+word.text);

                    // if(nodeBefore.nextSibling) {
                    //     let alternative_type = nodeBefore.nextSibling.type.name;
                    //     console.log("alternaive type: "+alternative_type+" value "+word.text);
                    // }
                    //tree.from tree.to
                    //tree.prevSibling.prevSibling.from tree.prevSibling.prevSibling.to


                    let options = [] as any[];

                    if(node_type == "Brick") {
                        self.CreateBrickOptions(word.text, options);
                    }
                    else if(node_type == "Output") {
                        if(nodeBefore.prevSibling) {            
                            let brick_name = context.state.sliceDoc(nodeBefore.prevSibling.from, nodeBefore.prevSibling.to);
                            self.CreateOutputOptions(word.text, brick_name, options);
                        }                    
                    }
                    else if(node_type == "Prop") {
                        self.CreatePropOptions(nodeBefore, context.state, word.text, options);                   
                    }
                    else if(node_type == "FormatType") {
                        self.CreateFormatTypeOptions(word.text, options);
                    }
                    else if(node_type == "FormatFunc") {
                        if(nodeBefore.prevSibling && nodeBefore.prevSibling.prevSibling) {            
                            let format_type = context.state.sliceDoc(nodeBefore.prevSibling.prevSibling.from, nodeBefore.prevSibling.prevSibling.to);
                            self.CreateFormatFuncOptions(word.text, format_type, options);
                        }                    
                    }
                    else if(node_type == "Formatparams") {
                        let option = {
                            label: "()",
                            displayLabel: "() (Add Format Parameters)",
                            section: "Format Functions",
                            apply: function(
                                view: EditorView,
                                completion: Completion,
                                from: number,
                                to: number) 
                            {
                                //console.log("apply "+completion.label);
                                view.dispatch(insertCompletionText(view.state, completion.label, to, to));

                                startCompletion(editor);
                                return true;
                            }
                        }
                        options.push(option);
                    }
                    // else if(node_type == "String") {
                    //     let option = {
                    //         label: "\"",
                    //         displayLabel: "\"" + " (end string)",
                    //         section: "Insert Primitive Value: (You may also type numbers)"
                    //     }
                    //     options.push(option);
                    // }

                    let tep = new TagElementPredictor(CBTemplateParserNodes);
                    tep.GetACOptions(nodeBefore);
                    let allowed_nexts = tep.GetAllAllowedNextTags();

                    //console.log("allowed_nexts: "+JSON.stringify(allowed_nexts));

                    for(let allowed_next of allowed_nexts) {
                        if(allowed_next[0] == "Brick" && node_type != "Brick") {
                            self.CreateBrickOptions(word.text, options);
                        }
                        else if(allowed_next[0] == "Output" && node_type != "Output") {
                            if(nodeBefore.prevSibling) {
                                let brick_name = context.state.sliceDoc(nodeBefore.prevSibling.from, nodeBefore.prevSibling.to);
                                self.CreateOutputOptions(word.text, brick_name, options);
                            }
                        }
                        else if(allowed_next[0] == "Prop" && node_type != "Prop") {
                            self.CreatePropOptions(nodeBefore, context.state, word.text, options);
                        }
                        else if(allowed_next[0] == "Eq") {
                            let option = {
                                label: "=",
                                displayLabel: "= (" + allowed_next[1] + ")",
                                section: "Opertators",
                                boost: -1,
                                apply: function(
                                    view: EditorView,
                                    completion: Completion,
                                    from: number,
                                    to: number) 
                                {
                                    //console.log("apply "+completion.label);
                                    view.dispatch(insertCompletionText(view.state, completion.label, to, to));

                                    startCompletion(editor);
                                    return true;
                                }
                            } 
                            options.push(option);
                        }
                        else if(allowed_next[0] == "And") {
                            let option = {
                                label: "&",
                                displayLabel: "& (And)",
                                section: "Opertators",
                                apply: function(
                                    view: EditorView,
                                    completion: Completion,
                                    from: number,
                                    to: number) 
                                {
                                    //console.log("apply "+completion.label);
                                    view.dispatch(insertCompletionText(view.state, completion.label, to, to));

                                    startCompletion(editor);
                                    return true;
                                }
                            }
                            options.push(option);
                        }
                        else if(allowed_next[0] == "Or") {
                            let option = {
                                label: "|",
                                displayLabel: "| (Or)",
                                section: "Opertators",
                                apply: function(
                                    view: EditorView,
                                    completion: Completion,
                                    from: number,
                                    to: number) 
                                {
                                    //console.log("apply "+completion.label);
                                    view.dispatch(insertCompletionText(view.state, completion.label, to, to));

                                    startCompletion(editor);
                                    return true;
                                }
                            }
                            options.push(option);
                        }
                        else if(allowed_next[0] == "Plus") {
                            let option = {
                                label: "+",
                                displayLabel: "+ (Append Term)",
                                section: "Opertators",
                                apply: function(
                                    view: EditorView,
                                    completion: Completion,
                                    from: number,
                                    to: number) 
                                {
                                    //console.log("apply "+completion.label);
                                    view.dispatch(insertCompletionText(view.state, completion.label, to, to));

                                    startCompletion(editor);
                                    return true;
                                }
                            }
                            options.push(option);
                        }
                        else if(allowed_next[0] == "Boolean") {
                            let option = {
                                label: "true",
                                displayLabel: "true" + " (true)",
                                section: "Insert Primitive Value: (You may also type numbers)"
                            }
                            options.push(option);
                            let option2 = {
                                label: "false",
                                displayLabel: "false" + " (false)",
                                section: "Insert Primitive Value: (You may also type numbers)"
                            }
                            options.push(option2);
                        }
                        else if(allowed_next[0] == "Null") {
                            let option = {
                                label: "null",
                                displayLabel: "null" + " (null)",
                                section: "Insert Primitive Value: (You may also type numbers)"
                            }
                            options.push(option);
                        }
                        else if(allowed_next[0] == "String") {
                            let option = {
                                label: "\"",
                                displayLabel: "\"" + " (String)",
                                section: "Insert Primitive Value: (You may also type numbers)",
                            }
                            options.push(option);
                        }
                        else if(allowed_next[0] == "FormatType" && node_type != "FormatType") {
                            self.CreateFormatTypeOptions(word.text, options);
                        }
                        else if(allowed_next[0] == "FormatFunc" && node_type != "FormatFunc") {
                            if(nodeBefore.prevSibling) {            
                                let format_type = context.state.sliceDoc(nodeBefore.prevSibling.from, nodeBefore.prevSibling.to);
                                self.CreateFormatFuncOptions(word.text, format_type, options);
                            }                    
                        }
                        else if(allowed_next[0] == "Formatparams" && node_type != "Formatparams") {
                            let option = {
                                label: "()",
                                displayLabel: "() (Add Format Parameters)",
                                section: "Format Functions",
                                apply: function(
                                    view: EditorView,
                                    completion: Completion,
                                    from: number,
                                    to: number) 
                                {
                                    //console.log("apply "+completion.label);
                                    view.dispatch(insertCompletionText(view.state, completion.label, to, to));

                                    startCompletion(editor);
                                    return true;
                                }
                            }
                            options.push(option);
                        }
                        else if(allowed_next[0] == "Number") {
                        }
                        else {
                            let option = {
                                label: allowed_next[0],
                                displayLabel: allowed_next[0] + " (" + allowed_next[1] + ")",
                                section: "Opertators",
                                boost: -2,
                                apply: function(
                                    view: EditorView,
                                    completion: Completion,
                                    from: number,
                                    to: number) 
                                {
                                    //console.log("apply "+completion.label);
                                    view.dispatch(insertCompletionText(view.state, completion.label, to, to));

                                    startCompletion(editor);
                                    return true;
                                }
                            } 
                            options.push(option);
                        }
                    }    

                    // if(node_type == "{{" || node_type == "Brick") {
                    //     for(let source of self.sources) {
                    //         let option = {
                    //             label: source.value,
                    //             displayLabel: source.value + " (" + source.meta + ")",
                    //             type: "brick"
                    //         }
                    //         options.push(option);
                    //     }
                    // }

                    // let next_section = { name: "Next:", 
                    //     header: function(section: CompletionSection) {
                    //         const element = document.createElement("completion-section");
                    //         element.innerHTML = `<div>Next</div>`;
                    //         return element;
                    //     }
                    // } 

                    //console.log(JSON.stringify(options));

                    return {
                        from: word.from, //context.state.selection.main.head, //word.from,// + word.text.length,
                        filter: false,
                        options: options
                        // [
                        //  {label: word.text, displayLabel: word.text, type: "keyword"},
                        //  {label: word.text + ".", displayLabel: ". (select property)", type: "keyword", section: next_section },
                        //  {label: word.text + "?", displayLabel: "? (then)", type: "keyword", section: next_section },
                        //  //{label: "hello", type: "variable", info: "(World)", section: brick_section},
                        //  //{label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro", section: brick_section},
                        //  //{label: "time", type: "text", apply: new Date().toTimeString(), detail: "macro", section: brick_section},
                        //  //{label: "string", displayLabel: "string", type: "keyword", section: { name: "Static value:" } },
                        //  //{label: "number", displayLabel: "number", type: "keyword", section: { name: "Static value:" } }
                        // ]
                    }
                }
            });

            // if you want something clickable in th elist, without firing the selection, you have to
            // catch onmousedown= and then event.stopPropagation();
            // window.test_click = function(event: any) {
            //     event.stopPropagation();
            //     console.log("hi");           
            // }

            //console.log("creating editor "+value_editor.id);

            this.destroyCodemirrorEditor(value_editor.id);

            extensions = [
                minimalSetup,
                closeBrackets(),
                EditorView.lineWrapping,
                autocompletion({
                    // addToOptions: [{
                    //     render: (completion: Completion, state: EditorState, view: EditorView) => {
                    //       if(completion.label == "match") {      
                    //         const element = document.createElement("div");
                    //         element.innerHTML = `<div>This is a addToOption</div>match`;
                    //         return element;
                    //       }
                    //       return null;
                    //     },
                    //     position: 0
                    // }],
                    // optionClass: function(completion: Completion) {
                    //     return "";
                    // },
                    //closeOnBlur: false,
                    activateOnCompletion: function(completion: Completion) {

                        //console.log("activateOnCompletion "+JSON.stringify(completion));

                        return true;
                    },
                    // compareCompletions: function(a: Completion, b: Completion) {
                    //     if(a.section == "Operators") {
                    //         return 1;
                    //     }
                    //     return 0;
                    // }
                }),
                cbtemplateLanguage,
                cbtemplateAutocompletion,
                syntaxHighlighting(cbtemplateHighlightStyle), //{fallback: true}),
                //html().support,
                //bracketMatching({brackets : "{{}}"}),
                ];

            if(lang == "html") {
                extensions.push(keymap.of([indentWithTab]));
                extensions.push(html().support);
            }
            else if(lang == "javascript") {
                extensions.push(javascript().support);
            }
            else {
                extensions.push(cbtemplateLanguage.data.of({
                    autocomplete: function completionSource(context: any) {
                        let before = context.matchBefore(/\w+|\{\{/)
                        if (!context.explicit && !before) return null

                        //console.log("before "+before);

                        let char = context.state.doc.slice(context.state.selection.main.head - 1, context.state.selection.main.head);
                        if(char == "\"" || char == "'") {
                            return;
                        }

                        return {
                            from: before ? before.from : context.pos,
                            options: [
                                {
                                    label:"null"
                                },
                                {
                                    label:"true"
                                },
                                {
                                    label:"false"
                                }
                            ],
                            validFor: /^\w*$/
                        }
                    }
                }));
            }
        }
        else {

            extensions = [
                EditorView.lineWrapping
            ];

            if(lang == "javascript") {
                extensions.push(basicSetup);
                extensions.push(keymap.of([indentWithTab]));
                extensions.push(javascript());
                //extensions.push(indentUnit.of("    "));
                extensions.push(javascriptLanguage.data.of({
                    autocomplete: scopeCompletionSource(globalThis)
                }));

                extensions.push(javascriptLanguage.data.of({
                    autocomplete: function completionSource(context: any) {
                        let before = context.matchBefore(/\{\{/)
                        if (!context.explicit && !before) return null

                        if(before != null) {
                            return {
                                from: before ? before.from : context.pos,
                                options: [
                                    {label: "{{", displayLabel: "This is static field, tags are not allowed. Please use tags in the params instead", type: "tag"}
                                ],
                                validFor: /\{\{/
                            }
                        }
                    }
                }));
            }
            else {
                extensions.push(minimalSetup);

                const completions = [
                    {label: "null", type: "keyword"},
                    {label: "true", type: "keyword"},
                    {label: "false", type: "keyword"},
                    {label: "{{", displayLabel: "This is static field, tags are not allowed", type: "tag"}
                ];

                function myCompletions(context: any) {
                    let before = context.matchBefore(/\w+|\{\{/)
                    // If completion wasn't explicitly started and there
                    // is no word before the cursor, don't open completions.
                    if (!context.explicit && !before) return null
                    return {
                      from: before ? before.from : context.pos,
                      options: completions,
                      validFor: /^\w*$/
                    }
                }

                extensions.push(autocompletion({override: [myCompletions]}));
            }

        }

        extensions.push(EditorView.updateListener.of((v:any) => {
            if(editor.hasFocus) {

                if(self.editors_by_e_id[value_editor.id].last_head != editor.state.selection.main.head) {
                    //this is to keep the ac open if you cursor right out of ""
                    let char = v.state.doc.slice(v.state.selection.main.head - 1, v.state.selection.main.head);
                    if(char == "\"" || char == "'") {
                        setTimeout(function() {
                            startCompletion(editor);
                        }, 1);
                    }
                }

                self.editors_by_e_id[value_editor.id].last_head = editor.state.selection.main.head;
                //console.log("set head "+self.editors_by_e_id[value_editor.id].last_head + " " +value_editor.id);
                //startCompletion(editor);

            }
            if (v.docChanged) {
                // Document changed
                //self.cb_emit({ "@": v.state.doc.toString() }); 
                (<any>window)["editvalue_" + self.ci_idx](value_editor);

                
            }
        }));

        let editor = new EditorView({
            doc: doc,
            extensions: extensions,
            parent: value_editor
        });

        this.editors_by_e_id[value_editor.id] = { has: true, editor: editor, last_head: -1 };    
    }

    CreateBrickOptions(search_word: string, options: any[]) {
        if(this.composition_tree && this.composition_tree.debug_log && this.composition_tree.debug_log.last_emit_data) {
            let search_word_lc = search_word.toLowerCase();

            // let brick_section = { name: "Brick:", 
            //     header: function(section: CompletionSection) {
            //         const element = document.createElement("completion-section");
            //         element.innerHTML = `<div>Bricks</div>`;
            //         return element;
            //     }
            // } 
            let brick_section = "Get value from this brick:";

            for(let source of this.sources) {
                let brick_name = source.value;
                if(this.composition_tree.debug_log.last_emit_data[brick_name]) {
                    if(brick_name.toLowerCase().startsWith(search_word_lc)) {
                        let option = {
                            label: brick_name,
                            displayLabel: brick_name + " (" + source.meta + ")",
                            section: brick_section,
                            boost: 1
                        }
                        options.push(option);
                    }
                }
            }

            for(let source of this.sources) {
                let brick_name = source.value;
                if(this.composition_tree.debug_log.last_emit_data[brick_name]) {
                    if(!brick_name.toLowerCase().startsWith(search_word_lc) && brick_name.toLowerCase().indexOf(search_word_lc) != -1) {
                        let option = {
                            label: brick_name,
                            displayLabel: brick_name + " (" + source.meta + ")",
                            section: brick_section
                        }
                        options.push(option);
                    }
                }
            }

        }
        if("true".startsWith(search_word)) {
            let option = {
                label: "true",
                displayLabel: "true" + " (true)",
                section: "Insert Primitive Value: (You may also type numbers)"
            }
            options.push(option);
        }
        else if("false".startsWith(search_word)) {
            let option = {
                label: "false",
                displayLabel: "false" + " (false)",
                section: "Insert Primitive Value: (You may also type numbers)"
            }
            options.push(option);
        }
        else if("null".startsWith(search_word)) {
            let option = {
                label: "null",
                displayLabel: "null" + " (null)",
                section: "Insert Primitive Value: (You may also type numbers)"
            }
            options.push(option);
        }
    }

    CreateOutputOptions(search_word: string, brick_name: string, options: any[]) {
        if(this.composition_tree && this.composition_tree.debug_log && this.composition_tree.debug_log.last_emit_data && this.composition_tree.debug_log.last_emit_data[brick_name]) {
            let led = this.composition_tree.debug_log.last_emit_data[brick_name];
            let search_word_lc = search_word.toLowerCase();
            for(let output in led.outputs) {
                if(output.toLowerCase().indexOf(search_word_lc) != -1) {
                    if(output != "@" && output != "@debug_log") {
                        let option = {
                            label: output,
                            displayLabel: output + " (" + led.outputs[output].desc + ")",
                            section: "On outputs"
                        }
                        options.push(option);
                    }
                }
            }
        }
    }

    CreatePropOptions(nodeBefore: any, state: any, search_word: string, options: any[]) {
        let brick_node = nodeBefore.prevSibling;
        let prop_path = [];
        while(brick_node && brick_node.type.name !="Brick") {
            if(brick_node.type.name == "Prop" || brick_node.name == "Output") {
                prop_path.unshift(state.sliceDoc(brick_node.from, brick_node.to));
            }
            brick_node = brick_node.prevSibling;       
        }

        if(brick_node) {
            let brick_name = state.sliceDoc(brick_node.from, brick_node.to);
            if(this.composition_tree && this.composition_tree.debug_log && this.composition_tree.debug_log.last_emit_data && this.composition_tree.debug_log.last_emit_data[brick_name]) {
                let led = this.composition_tree.debug_log.last_emit_data[brick_name].outputs;
                if(prop_path.length > 0 && prop_path[0][0] == "@") {
                    led = led[prop_path[0]].data;
                    prop_path.splice(0, 1);
                }
                else {
                    led = led["@"].data;
                }
                let atdata = DrillGet(prop_path, led);
                let prop;
                if(prop_path.length == 0) {
                    prop = undefined;
                }
                else {
                    prop = prop_path[prop_path.length-1];
                }
                
                this.CreateObjectOptions(prop, atdata, options, "", search_word);
                
            }
        }  
    }

    CreateObjectOptions(key: string, val: any, options: any[], path: string, search_word: string) {
        if(key !== undefined && key.toLowerCase().indexOf(search_word.toLowerCase()) != -1) {
            let s = path.split(".");
            let last = s[s.length - 1];
            let padstr = "-";
            let display = last.padStart(s.length + (last.length - 1)*padstr.length, padstr);
            let meta = val;
            if(typeof val == "object" && val !== null) {
                if(Array.isArray(val)) {
                    meta = "List / Array";
                }
                else {
                    meta = "Object";
                }
            }
            options.push({
                label: path,
                displayLabel: display + " ("+meta+")",
                section: " Property" //leading space is to boost is in list
            });
        }
        let search_lc = search_word.toLocaleLowerCase();
        if(typeof val == "object" && val !== null) {
            for(let p in val) {
                if(p.toLocaleLowerCase().startsWith(search_lc)) {
                    this.CreateObjectOptions(p, val[p], options, path ? (path + "." + p) : p, search_word);
                }
            }
            for(let p in val) {
                if(p.toLocaleLowerCase().indexOf(search_lc) != -1 && !p.toLocaleLowerCase().startsWith(search_lc)) {
                    this.CreateObjectOptions(p, val[p], options, path ? (path + "." + p) : p, search_word);
                }
            }
        }
    }

    CreateFormatTypeOptions(search_word: string, options: any[]) {
        let types = ["Array", "Date", "DateFromUTC", "DateToUTC", "Number", "Object", "Range", "RegExp", "String"]
        let search_word_lc = search_word.toLowerCase();
        for(let type of types) {   
            if(type.toLowerCase().startsWith(search_word_lc)) {
                let option = {
                    label: type,
                    displayLabel: type,
                    section: "Format"
                }
                options.push(option);
            }        
        }
    }

    CreateFormatFuncOptions(search_word: string, format_type: string, options: any[]) {

        let search_word_lc = search_word.toLowerCase();
        //@ts-expect-error
        let funcs = Sugar[format_type];
        if(format_type == "Array") {
            funcs = Sugar.Array([]);
        }
        for(let f in funcs) {
            if(f.toLowerCase().startsWith(search_word_lc)) {
                let option = {
                    label: f,
                    displayLabel: f,
                    section: "Format Functions"
                }
                options.push(option);
            }
        }
        
    }

    destroyCodemirrorEditor(e_id: string) {
        let editor = this.editors_by_e_id[e_id].editor;
        //console.log("destroying editor "+e_id);
        if(editor) {
            editor.destroy();
        }
    }

    destroyCodemirrorEditors() {
        for(let e_id in this.editors_by_e_id) {
            let editor = this.editors_by_e_id[e_id].editor;
            //console.log("destroying editor "+e_id);
            if(editor) {
                editor.destroy();
            }
        }
        this.editors_by_e_id = {};
    }

    // drawAutoCompleteForContentEditable(ce_div: HTMLElement) {
    //     let has_popout = ce_div.querySelector(".editor-tag-popout-container");
    //     if(has_popout) {
    //         has_popout.remove();
    //     }

    //     let line = this.getCaretLineValueAndOffset();

    //     let before_cursor = line?.value.substring(0, line.startOffset);
    //     let after_cursor = line?.value.substring(line.startOffset);

    //     let e_id = ce_div.id;


    //     let html = `<span class="editor-tag-popout-container"><div class="editor-tag-popout" id="${e_id}$popout">`;


    //     html += `</div></span>`;

    //     this.insertHtmlAtCaret(html);
    // }

    // drawAutoComplete(ce_div: HTMLElement) {
    //     let has_popout = ce_div.querySelector(".editor-tag-popout-container");
    //     if(has_popout) {
    //         has_popout.remove();
    //     }

    //     let line = this.getCaretLineValueAndOffset();

    //     let before_cursor = line?.value.substring(0, line.startOffset);
    //     let after_cursor = line?.value.substring(line.startOffset);

    //     let e_id = ce_div.id;


    //     let html = `<span class="editor-tag-popout-container"><div class="editor-tag-popout" id="${e_id}$popout">`;


    //     html += `</div></span>`;

    //     this.insertHtmlAtCaret(html);
    // }

    // set_node_from_contenteditable(node_path: string) {

    //     let node = this.find_node_by_path(node_path);

    //     if(node) {
    //         console.log("pre "+node.value);

    //         let value_id = this.get_value_id(node);

    //         let e_id = "e_"+value_id;

    //         let ce_div = document.getElementById(e_id);
    //         if(ce_div) {

    //             let textarea = document.getElementById(value_id) as HTMLTextAreaElement;
    //             if(textarea) {
    //                 let pilled_value = CBWebUtil.unescapeHtml(ce_div.innerHTML);

    //                 let tag_value = Sugar.String.removeTags(pilled_value, "tag", function(all, content) {
    //                     return "{{" + content + "}}";
    //                 });

    //                 textarea.value = tag_value;
    //                 node.value = tag_value;
    //             }
    //         }
    //     }
    // }

    // get_tag_editor_value() : string {
    //     let tag_value =  "";

    //     let mod1 = document.getElementById(this.brick_id+"$mod1_brick$body");

    //     if(mod1) {
    //         let prev_ti_type = null;

    //         let ti_types = mod1.querySelectorAll('[ti_type]');
    //         for(let i = 0; i < ti_types.length; i++) {
 
    //             let ti_element = ti_types[i] as HTMLInputElement;

    //             let ti_type = ti_element.getAttribute("ti_type");

    //             if(ti_type == "," || ti_type == "?" || ti_type == "(" || ti_type == ")") {
    //                 tag_value += ti_type;
    //             }
    //             else if(ti_type == "{") {
    //             }
    //             else if(ti_type == ":") {
    //                 if(ti_types.length > i + 1) {
    //                     let next = ti_types[i + 1] as HTMLInputElement;
    //                     let next_ti_type = next.getAttribute("ti_type");
    //                     if(next_ti_type == "" || next_ti_type == "ignore") {
     
    //                     }
    //                     else {
    //                         tag_value += ti_type;
    //                     }
    //                 }
    //             }
    //             else if(ti_type == "comparator") {
    //                 let element_value = ti_element.value;
    //                 if(element_value == "") {
    //                     prev_ti_type = element_value;
    //                 }
    //                 else {
    //                     tag_value += element_value;
    //                 }
    //             }
    //             else {
    //                 if(prev_ti_type == "") { //skip if prevous option is "received"

    //                 }
    //                 else {
    //                     let element_value = ti_element.value;
    //                     if(ti_type == "s") {
    //                         tag_value += '"' + element_value + '"';
    //                     }
    //                     else if(ti_type == "0") {
    //                         tag_value += "null";
    //                     }
    //                     else if(ti_type == "true" || ti_type == "false") {
    //                         tag_value += ti_type;
    //                     }
    //                     else {
    //                         tag_value += element_value;
    //                     }
    //                 }
                   
    //             }

                
    //         }
            
    //     }

    //     return tag_value;
    // }

    // async render_tag_editor(tag_content: string) {

    //     let tagitem = TagItemParser.ParseTagItem(tag_content, { i : 0 }, "}}");

    //     let ret = `<div editor_role="tag_editor" class="editor-tag-editor-container">`;

    //     ret += "<div>The value of the tag will be the last recieved of the following alternatives:</div>";
        
    //     ret += `<div class="editor-tag-ui">`;
    //     ret += await this.render_tagitem_editor(tagitem);
    //     ret += "</div>"

    //     ret += `<div style="display: flex;justify-content:flex-end"><button class="c-template-form-button" onclick="cb_editor.add_section${this.ci_idx}(this)">Add Alternative</button></div>`;
        
    //     ret += `</div>`;

    //     let self = this;

    //     if(!this.has_ok_callback) {
    //         this.has_ok_callback = true;
    //         RegisterSOCallback(this.brick_id+"$mod1", "@", 
    //             async function(data:any) {

    //                 if(!data) {
    //                     return;
    //                 }

    //                 let tag_value = self.get_tag_editor_value();

    //                 let path = data.node_path;

    //                 let form_element = self.get_form_element_by_path(path);

    //                 //console.log("path "+path);

    //                 let tageditor = form_element.querySelector(".editor-value-editor");

    //                 //console.log("path "+path+ " tag_value " + JSON.stringify(tag_value) + " tageditor "+tageditor);

    //                 if(!tageditor) {
    //                     //select element, change to a tageditor

    //                     let editor_property = form_element.getAttribute("editor_property");
    //                     let editor_path = form_element.getAttribute("editor_path");
    //                     let container_type = form_element.getAttribute("container_type");
    //                     let editor_color = "editor-color-1";
    //                     for(let c of form_element.classList) {
    //                         if(c.indexOf("editor-color") == 0) {
    //                             editor_color = c;
    //                         }
    //                     }

    //                     let schema = self.get_path_schema(editor_path);

    //                     let val = "{{" + tag_value + "}}";

    //                     form_element.outerHTML = await self.render_form_property(schema, editor_property, val, editor_path, editor_color, container_type);     
    //                 }
    //                 else {

    //                     let previous_value = Sugar.String.removeTags(tageditor.innerHTML, "tag", function(all, content) {
    //                         return "{{" + content + "}}";
    //                     });

    //                     //console.log("tag_value "+tag_value+ " path "+path+" previous_value "+previous_value + " pre_edit_tag_contents "+self.pre_edit_tag_contents);

    //                     //@ts-expect-error
    //                     if(window.t_selection) {
    //                         //@ts-expect-error
    //                         self.restoreSelection(window.t_selection);

    //                         self.insertTextAtCaret("{{"+tag_value+"}}");

    //                         //let tageditor = document.getElementById("e_"+value_id);
    //                         if(tageditor) {
    //                             tag_value = Sugar.String.removeTags(tageditor.innerHTML, "tag", function(all, content) {
    //                                 return "{{" + content + "}}";
    //                             });
    //                         }
    //                         //@ts-expect-error
    //                         window.t_selection = undefined;

    //                         //console.log("1 tag_value "+tag_value);
    //                     }
    //                     else if(previous_value !== undefined && self.pre_edit_tag_contents && previous_value.indexOf("{{"+self.pre_edit_tag_contents+"}}") != -1) {
    //                         let new_value = previous_value.replace("{{"+self.pre_edit_tag_contents+"}}", "{{"+tag_value+"}}");
    //                         tag_value = new_value;

    //                         //console.log("2 tag_value "+tag_value);
    //                     }
    //                     else {
    //                         if(previous_value === undefined) {
    //                             previous_value= "";
    //                         }
    //                         tag_value = previous_value + "{{"+tag_value+"}}";

    //                         //console.log("3 tag_value "+tag_value);
    //                     }

    //                     //console.log("mod1 set_form_property_value node_path "+data.node_path+" tag_value "+tag_value);

    //                     tag_value = CBWebUtil.unescapeHtml(tag_value);

    //                     await self.set_form_property_value(data.node_path, tag_value);
    //                 }

    //                 await self.cb_emit({"@": self.get_form_value()});

    //                 //@ts-expect-error
    //                 window["editor_hide_mod1_"+self.ci_idx]();

    //             });
    //     }

    //     if(!(<any>window).cb_editor["add_section"+this.ci_idx]) {
    //         (<any>window).cb_editor["add_section"+this.ci_idx] = async function(element: any) {
    //             let tag_editor_div = element.parentElement.parentElement;
    //             let sections_container = tag_editor_div.querySelector(".editor-tag-ui");
    //             if(sections_container) {
    //                 let source = "''";
    //                 let type = "s";
    //                 if(self.sources && self.sources.length > 0) {
    //                     source = self.sources[0].value;
    //                     type = "p";
    //                 }
    //                 let new_tagitem = { type: ",", items: [{ type: type, slice: source, val: "" }]  };

    //                 let section_html = `<div ti_type="," class="editor-alternatively">ALTERNATIVELY</div>`;
    
    //                 section_html += await self.render_section_editor(new_tagitem);;
    
    //                 sections_container.insertAdjacentHTML('beforeend', section_html);
    //             }
    //         }
    //     }

    //     return ret;
    // }

    // async render_tagitem_editor(ti: TagItem) {

    //     let ret = "";

    //     if(ti.type == "{") {
    //         if(ti.items) {
    //             for(let i = 0; i < ti.items.length; i++) {
    //                 ret += await this.render_tagitem_editor(ti.items[i]);
    //                 if(i < ti.items.length - 1 && ti.items[i + 1].type != ";") {
    //                     ret += `<div ti_type="," class="editor-alternatively">ALTERNATIVELY</div>`;
    //                 }
    //             }
    //         }
    //     }
    //     else if(ti.type == ",") {
    //         if(ti.items) {
    //             ret += await this.render_section_editor(ti);
    //         }
    //     }
    //     else if(ti.type == ";") {
    //         ret += "Tag Format: "+ ti.slice;
    //     }

    //     return ret;
    // }

    // async render_section_editor(ti: TagItem) {
    //     let ret = "";

    //     if(ti.items) {

    //         this.complete_section_tagitem(ti);
    
    //         ret += `<div class="editor-tag-ui editor-section" class="${ti.items.length == 1 ? "hidden" :""}">`;

    //         ret += `<button editor_role="section_delete" onclick="cb_editor.section_delete${this.ci_idx}(this)" class="editor-section-del_btn">×</button>`;

    //         ret += `<div editor_role="if" class="editor-condition ${ti.items.length == 1 ? "hidden" : ""}">IF</div>`;

    //         let term_idx = 0;
    //         let state_obj = { state : "", can_ignore: false };
    //         for(let item of ti.items) {

    //             ret += await this.get_sub_section_item_html(ti, term_idx, state_obj);

    //             if(term_idx == 0) {
    //                 ret += `<div editor_role="conditional"><input type="checkbox" id="conditional_${this.element_idx}" ${ti.items.length == 1 ? "" : "checked"} onchange="cb_editor.conditional_change${this.ci_idx}(this)"/> <label for="conditional_${this.element_idx}">Conditional</label></div>`;
    //                 this.element_idx++;
    //             }

    //             term_idx++;
    //         }

    //         ret += "</div>";
    //     }

    //     let self = this;

    //     if(!(<any>window).cb_editor["conditional_change"+this.ci_idx]) {
    //         (<any>window).cb_editor["conditional_change"+this.ci_idx] = async function(element: any) {
    //             let target_container = element.parentNode.parentNode;
    //             if(element.checked) {
    //                 let and_or_item = {
    //                     type: "",
    //                     items: [
    //                         { type: "=" },
    //                         { type: "s", val: "" },
    //                         { type: "?", val: "" },
    //                         { type: "s", val: "" },
    //                         { type: ":", val: "" },
    //                         { type: "s", val: "" }
    //                     ]
    //                 };
    //                 let state_obj = { state: "", can_ignore: false };

    //                 let html = await self.get_sub_section_item_html(and_or_item, 0, state_obj);
    //                 html += await self.get_sub_section_item_html(and_or_item, 1, state_obj);
    //                 html += await self.get_sub_section_item_html(and_or_item, 2, state_obj);
    //                 html += await self.get_sub_section_item_html(and_or_item, 3, state_obj);
    //                 html += await self.get_sub_section_item_html(and_or_item, 4, state_obj);
    //                 html += await self.get_sub_section_item_html(and_or_item, 5, state_obj);
                
    //                 target_container.insertAdjacentHTML("beforeend", html);
    //             }
    //             else {
    //                 for(let c = target_container.childNodes.length - 1; c >=0; c--) {
    //                     let childnode = target_container.childNodes[c];
    //                     let editor_role = childnode.getAttribute("editor_role");
    //                     if(editor_role == "conditional") {
    //                         break;
    //                     }
    //                     target_container.removeChild(childnode);
    //                 }
    //             }

    //             let if_element = target_container.querySelector('[editor_role="if"]');
    //             if(if_element) {
    //                 if(element.checked) {
    //                     if_element.classList.remove("hidden");
    //                 }
    //                 else {
    //                     if_element.classList.add("hidden");
    //                 }
    //             }
    //         }
    //     }

    //     if(!(<any>window).cb_editor["add_and_or"+this.ci_idx]) {
    //         (<any>window).cb_editor["add_and_or"+this.ci_idx] = async function(element: any) {
    //             let and_or_item = {
    //                 type: "",
    //                 items: [
    //                     { type: "&" },
    //                     { type: "p" },
    //                     { type: "=" },
    //                     { type: "s", val: "" },

    //                     //not drawn, just for the sake of has_else
    //                     { type: "?" },
    //                     { type: "s" },
    //                     { type: ":" },
    //                     { type: "s" },
    //                 ]
    //             };

    //             let state_obj = { state: "", can_ignore: false };

    //             let html = await self.get_sub_section_item_html(and_or_item, 0, state_obj);
    //             html += await self.get_sub_section_item_html(and_or_item, 1, state_obj);
    //             html += await self.get_sub_section_item_html(and_or_item, 2, state_obj);
    //             html += await self.get_sub_section_item_html(and_or_item, 3, state_obj);

    //             let target_container = element.parentNode.parentNode;
    //             let subsequent_sibling = target_container.querySelector('[editor_role="add_and_or"]');
    //             if(subsequent_sibling) {
    //                 subsequent_sibling.insertAdjacentHTML("beforebegin", html);
    //             }
    //         }
    //     }

    //     if(!(<any>window).cb_editor["section_delete"+this.ci_idx]) {
    //         (<any>window).cb_editor["section_delete"+this.ci_idx] = async function(element: any) {
    //             element.parentNode.parentNode.removeChild(element.parentNode.previousElementSibling);
    //             element.parentNode.parentNode.removeChild(element.parentNode);
    //         }
    //     }

    //     if(!(<any>window).cb_editor["comparator_change"+this.ci_idx]) {
    //         (<any>window).cb_editor["comparator_change"+this.ci_idx] = async function(comparator_select: any) {
    //             let comparator = comparator_select.value;

    //             let subsequent_term = comparator_select.parentNode.nextElementSibling;

    //             if(comparator == "") {
    //                 subsequent_term.classList.add("hidden");
    //             }
    //             else {
    //                 subsequent_term.classList.remove("hidden");
    //             }
    //         }
    //     }


    //     return ret;
    // }

    // complete_section_tagitem(ti: TagItem) {
    //     ti.items = ti.items || [];
    //     if(ti.items.length == 0) { // this if for ,}} implied term
    //         ti.items.push({ type: "s", val: ""});
    //     }

    //     let has_compare = false;
    //     for(let t = 0; t < ti.items.length; t++) {
    //         let type = ti.items[t].type;
    //         if(type == "=" || type == "!" || type == "<" || type == ">" || type == "<=" || type == ">=") {
    //             has_compare = true;
    //         }
    //         else if(type == "?") {
    //             if(!has_compare) {
    //                 ti.items.splice(t, 0, { type: "" }, { type: "s", val: ""});
    //                 t += 2;
    //             }
    //         }
    //     }

    //     if(ti.items.length >= 2 && ti.items[ti.items.length - 1].type == "?") {
    //         //a?
    //         ti.items.push({ type: "s", val: ""});
    //         ti.items.push({ type: ":", val: ""});
    //         ti.items.push({ type: "ignore", val: ""});
    //     }
    //     else if(ti.items.length >= 2 && ti.items[ti.items.length - 2].type == "?") {
    //         //a?b
    //         ti.items.push({ type: ":", val: ""});
    //         ti.items.push({ type: "ignore", val: ""});
    //     }
    // }

    // async get_sub_section_item_html(ti: TagItem, term_idx: number, state_obj: { state: string, can_ignore: boolean}) {
    //     let ret = "";

    //     if(ti.items) {
    //         let item = ti.items[term_idx];

    //         if(item.type == "s" || item.type == "n" || item.type == "p" || item.type == "b" || item.type == "0" || item.type == "0" || item.type == "{" || item.type == "ignore") {
    //             let hidden = term_idx > 0 && ti.items[term_idx - 1].type == "";
    //             ret += await this.render_term_editor(item, state_obj.can_ignore, hidden);
    //         }
    //         else if(item.type ==  "=" || item.type == "!" || item.type == "<" || item.type == ">" || item.type ==  ">=" || item.type ==  "<=" || item.type == "") {
    //             let comp = item.type;

    //             ret += `<div editor_role="comparator_select" class="editor-comparator">IS <select ti_type="comparator" onchange="cb_editor.comparator_change${this.ci_idx}(this)" class="c-select cbeditor-select">
    //                 <option value="" ${comp==""?"selected":""}>received</option>
    //                 <option value="=" ${comp=="="?"selected":""}>equal to</option>
    //                 <option value="!=" ${comp=="!"?"selected":""}>not equal to</option>
    //                 <option value=">" ${comp==">"?"selected":""}>greater than</option>
    //                 <option value="<" ${comp=="<"?"selected":""}>less than</option>
    //                 <option value=">=" ${comp==">="?"selected":""}>greater than or equal to</option>
    //                 <option value="<=" ${comp=="<="?"selected":""}>less than or equal to</option>
    //             </select></div>`;
    //         }
    //         else if(item.type == "|" || item.type == "&") {
    //             if(state_obj.state == "and_or_container") {
    //                 ret += "</div>";
    //                 state_obj.state = "";
    //             }
    //             ret += `<div editor_role="container" class="editor-tag-ui">`;
    //             ret += `<button editor_role="and_or_delete" onclick="javascript:this.parentNode.parentNode.removeChild(this.parentNode)" class="editor-section-del_btn">×</button>`;
    //             state_obj.state = "and_or_container";

    //             ret += `<div editor_role="and_or_select"><select ti_type="and_or" class="c-select cbeditor-select">
    //                 <option value="&" ${item.type=="&"?"selected":""}>AND</option>
    //                 <option value="|" ${item.type=="|"?"selected":""}>OR</option>
    //             </select></div>`;
    //         }
    //         else if(item.type == "?") {
    //             if(state_obj.state == "and_or_container") {
    //                 ret += "</div>";
    //                 state_obj.state = "";
    //             }

    //             ret += `<div editor_role="add_and_or" style="display:flex;justify-content:flex-end"><button class="c-template-form-button" onclick="cb_editor.add_and_or${this.ci_idx}(this)">Add AND/OR</button></div>`;

    //             ret += `<div class="editor-condition" editor_role="then" ti_type="?">THEN</div>`;
    //         }
    //         else if(item.type == ":") {
    //             ret += `<div class="editor-condition" editor_role="else" ti_type=":">ELSE</div>`;
    //             state_obj.can_ignore = true;
    //         }
    //     }

    //     return ret;
    // }

    // async render_term_editor(term: TagItem, canIgnore = false, hidden = false) {

    //     if(term === undefined) {
    //         term = { type: "s", val: "" };
    //     }
        
    //     let type = term.type;

    //     let ret = `<div editor_role="term" class="editor-term ${hidden ? "hidden":""}">`;

    //     let val = term.val;
    //     if(val === undefined) {
    //         val = '';
    //     }
        
    //     if(term.type == "p") {
    //         val = term.slice;
    //         if(!val && this.sources && this.sources.length > 0) {
    //             val = this.sources[0].caption;
    //         }
    //     }

    //     //ret += `<label class="cbeditor-label">Value Type</label>`;
    //     ret += `<select onchange="cb_editor.term_type${this.ci_idx}(this)" class="editor-value-type-select">
    //         <option value="p" ${type=="p"?"selected":""}>Value output by other Brick</option>
    //         <option value="s" ${type=="s"?"selected":""}>string</option>
    //         <option value="n" ${type=="n"?"selected":""}>number</option>
    //         <option value="true" ${(type=="b" && term.val == true) ? "selected":""}>true</option>
    //         <option value="false" ${(type=="b" && term.val == false)?"selected":""}>false</option>
    //         <option value="0" ${type=="0"?"selected":""}>null</option>
    //         <option value="{" ${type=="{"?"selected":""}>(Nested Tag)</option>`;
        
    //     if(canIgnore) {
    //         ret += `<option value="" ${type=="ignore"?"selected":""}>Ignore</option>`;
    //     }
            
    //     ret += `</select>`;

    //     //ret += `<label editor_role="value_input_label" class="cbeditor-label ${type == "s" || type == "n" ? "" : "hidden"}">Value</label>`;
    //     ret += `<input editor_role="value_input" ti_type="${type}" type="text" value="${val}" class="c-input cbeditor-input ${type == "s" || type == "n" ? "" : "hidden"}" />`;

    //     ret += `<div editor_role="outsel">`;
    //     if(type == "{") {
    //         let bracketed_tag = TemplateUtil.UnParseTagItem(term);
    //         if(bracketed_tag) {
    //             ret += await this.render_tag_editor(bracketed_tag.substring(2));
    //         }
    //     }
    //     else if(type == "p") {
    //         ret += await this.render_output_selection(val);
    //     }
    //     ret += "</div>";
    //     ret += `</div>`;

    //     let self = this;

    //     if(!(<any>window).cb_editor["term_type"+this.ci_idx]) {
    //         (<any>window).cb_editor["term_type"+this.ci_idx] = async function(type_sel: any) {
    //             let type = type_sel.value;

    //             //console.log("type "+type);

    //             let value_input = type_sel.parentNode.querySelector('[editor_role="value_input"]');
    //             if(value_input) {
    //                 if(type == "s" || type == "n") {
    //                     value_input.value = "";
    //                     value_input.classList.remove("hidden");
    //                 }
    //                 else {
    //                     value_input.classList.add("hidden");
    //                 }

    //                 value_input.setAttribute("ti_type", type);
    //             }
    //             let value_input_label = type_sel.parentNode.querySelector('[editor_role="value_input_label"]');
    //             if(value_input_label) {
    //                 if(type == "s" || type == "n") {
    //                     value_input_label.classList.remove("hidden");
    //                 }
    //                 else {
    //                     value_input_label.classList.add("hidden");
    //                 }
    //             }

    //             let outsel = type_sel.parentNode.querySelector('[editor_role="outsel"]');
    //             if(outsel) {
    //                 if(type == "p") {
    //                     outsel.innerHTML = await self.render_output_selection(value_input.value);
    //                     outsel.classList.remove("hidden");
    //                 }
    //                 else if(type == "{") {
    //                     let brackets_term = term;
    //                     if(brackets_term.type != "{") {
    //                         brackets_term = { type: "{", items: [
    //                             { type: ",", items: [
    //                                 { type: "p" }
    //                             ] }
    //                         ] };
    //                     }

    //                     //console.log("brackets_term "+JSON.stringify(brackets_term));

    //                     outsel.innerHTML = `<div ti_type="("></div>` + await self.render_tagitem_editor(brackets_term) + `<div ti_type=")"></div>`;//, node, Number(t)*100 + "", s*100);
    //                     outsel.classList.remove("hidden");
    //                 }
    //                 else {
    //                     outsel.classList.add("hidden");
    //                 }
    //             }
    //         }
    //     }

    //     return ret;
    // }

    get_source_list() {
        //if a brick is in a sc-container, it should only have sibling bricks, and the container as available sources.
        //External bricks should not have bricks in containers available.
        //We will traverse the composition and gather

        let sources_obj = {} as any;
        if(this.sources) {
            for(let src of this.sources) {
                sources_obj[src.value] = true;
            }
        }

        let context_container_name = this.find_brick_context(this.composition_tree, "");

        if(context_container_name == this.brick_name) {
            let input = this.current_path.split(".")[0];
            if(input == "in_data") {
                let container = FindBrickContainer(this.composition_tree, this.brick_name);
                if(container) {
                    context_container_name = container.name; //for in_data we need to get the external sources, so the sources of the container context.
                }
            }
        }
        return this.get_context_sources(context_container_name, sources_obj);
    }

    find_brick_context(from: any, context: string) : string {
        if(from.name == this.brick_name) {
            return context;
        }
        if(from.contains) {
            for(let c of from.contains) {
                if(c.type == "sc-container") {
                    let found = this.find_brick_context(c, c.name);
                    if(found) {
                        return found;
                    }
                }
                else {
                    let found = this.find_brick_context(c, context);
                    if(found) {
                        return found;
                    }
                }
            }
        }
        return "";
    }

    get_context_sources(context_container_name: string, sources_obj: any) {
        let start = this.composition_tree;
        let sources = [];
        if(context_container_name != "") {
            start = FindBrick(this.composition_tree, context_container_name);
            sources.push(context_container_name);
        }
        
        sources.push(start.name);
        this.gather_sources_recurse(start, sources_obj, sources);

        return sources;
    }

    gather_sources_recurse(from: any, sources_obj: any, sources: string[]) {     
        if(from.contains) {
            for(let c of from.contains) {
                if(sources_obj[c.name]) {
                    sources.push(c.name);
                }
                if(c.type != "sc-container") {
                    this.gather_sources_recurse(c, sources_obj, sources)
                }
            }
        }
    }

    // async render_output_selection(val: string) {
 
    //     let ret = "";
    //     //val is souce_name.@output.etc

    //     let source = "";
    //     let term_parts = TemplateUtil.GetTermParts(val);
    //     source = term_parts[0];

    //     ret += `<div editor_role="linked">`;

    //     let select_id = `source${this.element_idx}`
    //     ret += `<select editor_role="source_select" id="${select_id}" onchange="cb_editor.source_select${this.ci_idx}(this)" class="hidden">`;
    //     if(this.sources) {
    //         for(let src of this.sources) {
    //            ret += `<option value="${src.value}" ${source == src.value ? "selected":""}>${src.caption}</option>`;
    //         }
    //     }
    //     ret += `</select>`;

    //     //c-select
    //     let souce_list = this.get_source_list();

    //     ret += `<c-select id="c${select_id}" ins='{ "cfg": { "label" : "", "value":"${source}", "options": ${JSON.stringify(souce_list)}, "style_classes":["cbeditor-select"] } }'></c-select>`;
    //     RegisterSOCallback(`c${select_id}`, "@", function(value: any) {
    //         let element = document.getElementById(select_id) as HTMLSelectElement;
    //         if(element) {
    //             element.value = value;
    //             (<any>window).cb_editor[`source_select${self.ci_idx}`](element);
    //         }
    //     });
    //     this.element_idx++;
    //     //

    //     let output = "default";
    //     let drill_start = 1;
    //     if(term_parts.length > 1) {
    //         if(term_parts[1][0] == '@') {
    //             drill_start = 2;
    //             if(term_parts[1].length > 1) {
    //                 output = term_parts[1].substring(1);
    //             }
    //         }
    //     }

    //     let drill_val = "";
    //     for(let d = drill_start; d < term_parts.length; d++) {
    //         if(d > drill_start) {
    //             drill_val += ".";
    //         }
    //         drill_val += term_parts[d];
    //     }

    //     let outnodes = {};

    //     let spec_outs = await this.get_source_spec_outs(source);
    //     let output_desc = "";
    //     if(spec_outs) {
    //         let num_outs = Object.keys(spec_outs).length;
    //         ret += `<label class="cbeditor-label${num_outs <= 1 ? " hidden" : ""}">Other Brick Output</label>`;
    //         ret += `<select editor_role="output_select" onchange="cb_editor.output_select${this.ci_idx}(this)" class="c-select cbeditor-select${num_outs <= 1 ? " hidden" : ""}">`;

    //         for(let out in spec_outs) {
    //             ret += `<option value="${out}" ${out == output ? "selected" : ""} >${out + (spec_outs[out].desc ? (" - "+spec_outs[out].desc) : "")}</option>`;

    //             if(out == output) {
    //                 output_desc =  spec_outs[out].desc;
    //             }
    //         }

    //         ret += `</select>`;
        
    //         outnodes = OutputSchemaToNodes(spec_outs[output]);
    //     }

        
    //     ret += `<div class="editor-outnodes">`;
    //     //ret += `<label class="cbeditor-label">Select Output Value</label>`;

    //     let blueprint = FindBrick(this.composition_tree, source);//(<any>window).codebricks_context.blueprints._1[source];

    //     let nsod = "";
    //     if(blueprint) {
    //         nsod = blueprint.nsod || blueprint.nsid || "";
    //     }

    //     //every time you run in the debugger or save a change! it creats a new cid _1, _2 .... 
    //     //so we use this highest one
    //     let cids = Object.keys(this.context.composition_runners);
    //     let hi_cid = cids[cids.length-1];

    //     if(this.context.composition_runners[hi_cid]) {
    //         let source_brick_ids = this.context.composition_runners[hi_cid].get_brick_ids(source, "");
    //         if(source_brick_ids) {
    //             let source_brick = this.context.bricks[source_brick_ids[0]];
    //             if(source_brick && source_brick.dc) {
    //                 nsod = source_brick.dc;     
    //             }
    //             if(blueprint && blueprint.type[1] == "d") {
    //                 nsod = nsod + `--${source}--0`;
    //             }
    //         }
    //     }

    //     let out = "@";
    //     if(output != "default") {
    //         out = "@" + output;
    //     }

    //     // let outdata = null;
    //     // if((<any>window).codebricks_context.last_emit_data["_1"][source] && 
    //     //     (<any>window).codebricks_context.last_emit_data["_1"][source][out]) {
                
    //     //     outdata = (<any>window).codebricks_context.last_emit_data["_1"][source][out];

    //     //     outnodes = CloneMerge(outnodes, outdata);
    //     // }
    //     let outdata = await this.get_last_emit_data(source, out);
    //     if(outdata) {
    //         outnodes = CloneMerge(outnodes, outdata);
    //     }

    //     let base_path = source;
    //     if(out != "@") {
    //         base_path = source + "." + out;
    //     }

    //     ret += `<label class="cbeditor-label">Value Path</label>`;
    //     ret += `<input editor_role="value_path" type="text" value="${drill_val}" class="c-input cbeditor-input" onchange="cb_editor.value_path${this.ci_idx}(this, '${base_path}')"></input>`;
    //     ret += `<label class="cbeditor-label">Value Path Selector</label>`;

    //     //render outnodes
    //     let selected_path = base_path + (drill_val ? "." + drill_val : "");
    //     let string_val = "";
    //     if(typeof outnodes != "object") {
    //         string_val = ": "+JSON.stringify(outnodes);
    //     }
    //     ret += `<div><button class="editor-node-exp">-</button><button class="editor-node-button${drill_val == "" ? " editor-node-button-selected":""}" onclick="cb_editor.node_clicked${this.ci_idx}(this, '${base_path}')">${output} output: ${output_desc || ""} (Whole value)${string_val}</button></div>`;
        
    //     ret += `<div class="editor-outnode-container">`;
    //     ret += this.render_outnodes_recurse(outnodes, base_path, selected_path);
    //     ret += `</div>`;

    //     if(!outdata) {
    //         ret += `<div class="editor-no-outnodes">No data from the source on this output. Do the ui action to trigger the source, then a value path selector will become available.</div>`;
    //     }

    //     ret += "</div>";
    //     ret += "</div>";

    //     let self = this;


    //     if(!(<any>window).cb_editor["source_select"+this.ci_idx]) {
    //         (<any>window).cb_editor["source_select"+this.ci_idx] = async function(source_sel: any) {
    //             let val_input = source_sel.parentNode.parentNode.parentNode.querySelector('[editor_role="value_input"]') as HTMLInputElement;
    //             val_input.value = source_sel.value;

    //             let outsel = source_sel.parentNode.parentNode;
    //             if(outsel) {
    //                 outsel.innerHTML = await self.render_output_selection(val_input.value);
    //             }
    //         }
    //     }

    //     if(!(<any>window).cb_editor["output_select"+this.ci_idx]) {
    //         (<any>window).cb_editor["output_select"+this.ci_idx] = async function(output_sel: any) {
    //             let outsel = output_sel.parentNode.parentNode;
    //             if(outsel) {
    //                 let val_input = output_sel.parentNode.parentNode.parentNode.querySelector('[editor_role="value_input"]') as HTMLInputElement;
                    
    //                 let source_sel = outsel.querySelector('[editor_role="source_select"]');

    //                 val_input.value = source_sel.value + (output_sel.value == "default" ? "" : ".@" + output_sel.value);

    //                 outsel.innerHTML = await self.render_output_selection(val_input.value);
    //             }
    //         }
    //     }

    //     if(!(<any>window).cb_editor["node_clicked"+this.ci_idx]) {
    //         (<any>window).cb_editor["node_clicked"+this.ci_idx] = async function(button: any, path: string) {
                
    //             let outsel = button.parentNode.parentNode.parentNode.parentNode;
    //             while(outsel.getAttribute("editor_role") != "outsel") {
    //                 outsel = outsel.parentNode;
    //             }

    //             let term_div = outsel.parentNode;

    //             let val_input = term_div.querySelector("input");
    //             val_input.value = path;

    //             let value_path_input = outsel.querySelector('[editor_role="value_path"]');
    //             if(value_path_input) {
    //                 let term_parts = TemplateUtil.GetTermParts(path);
    //                 let value_path = "";
    //                 let first = true;
    //                 for(let p = 1; p < term_parts.length; p++) {
    //                     if(p == 1 && term_parts[p][0] == "@") {

    //                     }
    //                     else {
    //                         if(!first) {
    //                             value_path += ".";
    //                         }
    //                         value_path += term_parts[p];
    //                         first = false;
    //                     }
    //                 }
    //                 value_path_input.value = value_path;
    //             }

    //             let prev_selected = outsel.querySelector(".editor-node-button-selected");
    //             if(prev_selected) {
    //                 prev_selected.classList.remove("editor-node-button-selected");
    //             }
    //             button.classList.add("editor-node-button-selected");
    //         }
    //     }

    //     if(!(<any>window).cb_editor["value_path"+this.ci_idx]) {
    //         (<any>window).cb_editor["value_path"+this.ci_idx] = async function(input: any, base_path: string) {
                
    //             let outsel = input.parentNode.parentNode.parentNode;

    //             let term_div = outsel.parentNode;

    //             let val_input = term_div.querySelector("input");
    //             let full_path = base_path + (input.value ? "." + input.value : "");
    //             val_input.value = full_path;

    //             let prev_selected = outsel.querySelector(".editor-node-button-selected");
    //             if(prev_selected) {
    //                 prev_selected.classList.remove("editor-node-button-selected");
    //             }
    //             let button = outsel.querySelector(`[path="${full_path}"]`);
    //             if(button) {
    //                 button.classList.add("editor-node-button-selected");
    //             }
    //         }
    //     }

    //     return ret;
    // }

    // render_outnodes_recurse(nodes: any, path: string, selected_path: string) {
    //     let ret = "";
    //     if(typeof nodes == "object") {
    //         let max = 20;
    //         if(Array.isArray(nodes)) {
    //             max = 3;
    //         }
    //         let i = 0;
    //         for(let p in nodes) {
    //             let this_path = path ? (path + "." + p) : p;
    //             ret += `<div>${(typeof nodes[p] == "object" && nodes[p])? `<button class="editor-node-exp">-</button>` : ""}<button class="editor-node-button${this_path == selected_path ? " editor-node-button-selected":""}" onclick="cb_editor.node_clicked${this.ci_idx}(this, '${this_path}')" path="${this_path}">${p}${(typeof nodes[p] == "object" && nodes[p]) ? "" : (":<i>"+nodes[p]+"</i>")}</button></div>`;
    //             let sub = this.render_outnodes_recurse(nodes[p], this_path, selected_path);
    //             if(sub) {
    //                 ret += `<div class="editor-outnode-container">`+sub+`</div>`;
    //             }
    //             i++;
    //             if(i >= max) {
    //                 break;
    //             }
    //         }           
    //     }
    //     return ret;
    // }

    // async get_source_spec_outs(source_brick_name: string) {
    //     //_1 assumes editor context
    //     let source_blueprint = FindBrick(this.composition_tree, source_brick_name);
    //     if(source_blueprint) {
    //         let brick_type = source_blueprint.type;

    //         if(brick_type == "cc-use" || brick_type == "sc-use") {

    //             let full_type = brick_type;
    //             for(let source of this.sources) {
    //                 if(source.value == source_brick_name) {
    //                     full_type = source.meta;
    //                     break;
    //                 }
    //             }

    //             //console.log("full_type "+full_type);

    //             let sub_comp = full_type.substring(full_type.indexOf("/") + 1, full_type.length);

    //             //console.log("sub_comp "+sub_comp);

    //             let res = await fetch("/cb_api/admin/get-composition-spec-outs/"+sub_comp, { method: "GET"});

    //             let body = await res.text();

    //             let spec = JSON.parse(body);

    //             //console.log("spec "+JSON.stringify(spec));
  
    //             return spec;
    //         }
    //         else if(brick_type == "cc-unit" || brick_type == "sc-unit") {
    //             if(FindBrick(source_blueprint, this.brick_name) || FindBrick(source_blueprint, this.container_name)) { //container name for add
    //                 //If a brick is inside a cc-unit and the cc-unit is the source, then is should use the unit_ins
    //                 return source_blueprint.ins.unit_ins;
    //             }
    //             else {
    //                 //if it's outisde we should use the unit_outs
    //                 return source_blueprint.ins.unit_outs;
    //             }
    //         }
    //         else {
    //             if(this.specs) {
    //                 for(let spec of this.specs) {
    //                     if(spec.name == brick_type) {
    //                         return spec.spec.outs;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     return null;
    // }

    // async get_last_emit_data(source: string, output: string) {
        
    //     let dest_name = this.brick_name;
    //     let source_brick = FindBrick(this.composition_tree, source);
    //     let dest_brick = FindBrick(this.composition_tree, dest_name);
    //     let source_container = FindBrick(this.composition_tree, source_brick?.container_name || "");
    //     let dest_container = FindBrick(this.composition_tree, dest_brick?.container_name || "");
        

    //     if(source_brick && source_brick.type[0] == "s" && dest_brick && dest_brick.type[0] == "s" && source_container && source_container.type[0] == "s" && dest_container && dest_container.type[0] == "s" && source_brick?.container_name === dest_brick?.container_name || "") {
    //         //We need to get it from the server (unless its from a server to a client brick, in which case we just use the sc-use output, below)

    //         let res = await fetch("/cb_api/admin/last_emit_data/"+this.composition_name+"."+source, { method: "GET"});

    //         let body = await res.text();

    //         let out_data = JSON.parse(body || "{}");

    //         return out_data[output] || {};
    //     }
        
    //     let cids = Object.keys((<any>window).codebricks_context.last_emit_data);

    //     //every time you run in the debugger or save a change! it creats a new cid _1, _2 .... so we combine them for the debugger out data
    //     let lid = Clone((<any>window).codebricks_context.last_emit_data["_1"]);
    //     for(let c = 2; c < cids.length; c++) {
    //         ObjectDeepMerge(lid, (<any>window).codebricks_context.last_emit_data[cids[c]]);
    //     }

    //     if(lid && 
    //         lid[source] && 
    //         lid[source][output]) {

    //         console.log("source "+source+" output "+output + " " + JSON.stringify(lid[source][output]));
                
    //         return lid[source][output];
    //     }
    // }

    // restoreSelection(range: any) {
    //     if (range) {
    //         if (window.getSelection) {
    //             let sel = window.getSelection() as any;
    //             sel.removeAllRanges();
    //             sel.addRange(range);
    //         //@ts-expect-error
    //         } else if (document.selection && range.select) {
    //             range.select();
    //         }
    //     }
    // }

    // insertTextAtCaret(text: string) {
    //     var sel, range;
    //     if (window.getSelection) {
    //         sel = window.getSelection();
    //         //@ts-expect-error
    //         if (sel.getRangeAt && sel.rangeCount) {
    //             //@ts-expect-error
    //             range = sel.getRangeAt(0);
    //             range.deleteContents();
    //             range.insertNode( document.createTextNode(text) );

    //             range.setStart(range.endContainer, range.endOffset)
    //         }

    //     //@ts-expect-error
    //     } else if (document.selection && document.selection.createRange) {
    //         //@ts-expect-error
    //         document.selection.createRange().text = text;
    //     }
    // }

    // insertTextAfterCaret(text: string) {
    //     var sel, range;
    //     if (window.getSelection) {
    //         sel = window.getSelection();
    //         //@ts-expect-error
    //         if (sel.getRangeAt && sel.rangeCount) {
    //             //@ts-expect-error
    //             range = sel.getRangeAt(0);
    //             range.deleteContents();
    //             range.insertNode( document.createTextNode(text) );

    //             range.setEnd(range.endContainer, range.endOffset)
    //         }

    //     //@ts-expect-error
    //     } else if (document.selection && document.selection.createRange) {
    //         //@ts-expect-error
    //         document.selection.createRange().text = text;
    //     }
    // }

    // insertHtmlAtCaret(html: string) {
    //     var sel, range;
    //     if (window.getSelection) {
    //         sel = window.getSelection();
    //         //@ts-expect-error
    //         if (sel.getRangeAt && sel.rangeCount) {
    //             //@ts-expect-error
    //             range = sel.getRangeAt(0);

    //             //range.deleteContents();
    //             range.insertNode(range.createContextualFragment(html));

    //             range.setEnd(range.startContainer, range.startOffset);
    //         }

    //     //@ts-expect-error
    //     } else if (document.selection && document.selection.createRange) {
    //        // //@ts-expect-error
    //        // document.selection.createRange().text = text;
    //     }
    // }

    // getCaretLineValueAndOffset() {
    //     var sel, range;
    //     if (window.getSelection) {
    //         sel = window.getSelection();
    //         //@ts-expect-error
    //         if (sel.getRangeAt && sel.rangeCount) {
    //             //@ts-expect-error
    //             range = sel.getRangeAt(0);

    //             //@ts-expect-error
    //             let ret = { value: range.startContainer.wholeText || range.startContainer.innerText, startOffset: range.startOffset };

    //             return ret;
    //         }
    //     }
    // }
}

