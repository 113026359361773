import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { SUse } from "../../shared-funcs/SUse";

export class c_fileupload_webcomponent extends HTMLElement {
    ci: web_c_fileupload | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_fileupload(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-fileupload', c_fileupload_webcomponent);

export class web_c_fileupload extends CodeBrick {

    element: HTMLElement;
    initialised = false;
    cfg: any;
    info?: CBEventInfo;
    file_name = "";

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("c_fileupload " +this.brick_id+" cb_event "+input+" "+JSON.stringify(cfg));

        if(input == "clear" && cfg) {
            input = "cfg";
            cfg = this.cfg;
            this.initialised = false;
        }

        if(input == 'cfg')
        {

            this.cfg = cfg;
            this.info = info;

            let accept = "";
            if(this.cfg.accept) {
                accept = ` accept="${this.cfg.accept}"`;
            }

            if(!this.initialised) {

                this.element.innerHTML = `<div class="c-fileupload-container">
                    <label for="${this.brick_id}" class="c-fileupload">${cfg.input_label || ""}</label><div id="${this.brick_id}$filename"class="c-fileupload-filename"></div>
                    <input id="${this.brick_id}" type="file" name="${this.brick_id}" class="c-fileupload-input"${accept}/>
                    <button id="${this.brick_id}$button" class="c-button c-fileupload-button c-fileupload-button-nofile${cfg.auto_upload ? " hidden" : ""}">${cfg.button_label || ""}</button>                 
                </div>
                <progress id="${this.brick_id}$progress" class="c-fileupload-progressbar hidden" value="0" max="100"></progress>
                <div id="${this.brick_id}$uploaded" class="c-fileupload-uploaded"></div>`;

                let self = this;
                
                let file_input = document.getElementById(this.brick_id);
                if(file_input) {
                    file_input.addEventListener("change", async function (evt: any) {
                        self.file_name = evt.target.files[0].name;
                        let filename_elem = document.getElementById(self.brick_id + "$filename");
                        if(filename_elem) {
                            if(self.cfg.show_file_name) {
                                filename_elem.innerHTML = evt.target.files[0].name;
                            }
                            let upload_button = document.getElementById(self.brick_id + "$button");
                            if(upload_button) {
                                upload_button.classList.remove("c-fileupload-button-nofile");
                            }
                            let uploaded_elem = document.getElementById(self.brick_id + "$uploaded");
                            if(uploaded_elem) {
                                uploaded_elem.innerHTML = "";
                            }
                            if(self.cfg.auto_upload) {
                                await self.upload();
                            }
                        }
                    });
                }

                let button = document.getElementById(this.brick_id+"$button");
                if(button) {
                    button.addEventListener("click", async function(ev: MouseEvent) {
                        await self.upload();
                    });
                }
                this.initialised = true;
            }
            else {
                let button = document.getElementById(this.brick_id+"$button");
                if(button) {
                    button.innerHTML = cfg.button_label;
                }
            }
        }
    }

    async upload() {
        console.log("c_fileupload "+this.brick_id+" UPLOAD "+JSON.stringify(this.cfg));

        let fileupload =  document.getElementById(this.brick_id) as HTMLFormElement;
        if(fileupload) {

            if(!fileupload.files || fileupload.files.length == 0) {
                let uploaded_elem = document.getElementById(this.brick_id + "$uploaded");
                if(uploaded_elem) {
                    uploaded_elem.innerHTML = "No file selected";
                }
                return;
            }

            //Find the target sc-units:
            //let rlm = self.context.composition_runners[self.cid].get_reverse_link_map();
            //let targets = rlm[self.blueprint.name];

            let formData = new FormData();           
            formData.append("file", fileupload.files[0]);
            formData.append("composition", this.context.compositions[this.cid].composition);
            formData.append("unit_path", this.blueprint.name); //cfg.target_sc_unit);
            if(this.context.composition_runners[this.cid].debugging_enabled) {
                formData.append("debugging_enabled", "" + this.context.composition_runners[this.cid].debugging_enabled); 
            }
            if(this.info) {
                formData.append("source", this.info.source);
                formData.append("source_output", this.info.source_output);
            }
            formData.append("unwrap_output", "true");

            let params = SUse.GetPValuePairs(this, "cfg");


            //Only post the p values
            if(params) {
                for(let k in params) {
                    formData.append(k, params[k]);
                }
            }

            //We will use XMLHttpRequest and not fetch, because we need it for the upload progress bar.
            // let res = await fetch('/cb_api/cb/sc-use', {
            //     method: "POST", 
            //     body: formData
            // });
            // let body = await res.text();

            let self = this;
            let body = await new Promise(resolve => {
                var xhr = new XMLHttpRequest();
                xhr.open("POST", "/cb_api/cb/sc-use", true);
                xhr.onprogress = function(event) {
                    let percent = Math.round((event.loaded / event.total) * 100);
                    let uploaded_elem = document.getElementById(self.brick_id + "$uploaded");
                    if(uploaded_elem) {
                        uploaded_elem.innerHTML = "Uploaded " + Sugar.Number.bytes(event.loaded) + " of " + Sugar.Number.bytes(event.total) +" ("+percent+"%)";
                    }        
                    let progress_elem = document.getElementById(self.brick_id + "$progress") as HTMLProgressElement;
                    if(progress_elem) {
                        progress_elem.classList.remove("hidden");
                        progress_elem.value = Math.round(percent);
                    }
                }
                xhr.onload = function(event) {
                    resolve(xhr.response);
                    let uploaded_elem = document.getElementById(self.brick_id + "$uploaded");
                    if(uploaded_elem) {
                        // if(event.target) {
                        //     uploaded_elem.innerHTML = event.target.responseText;
                        // }
                        // else {
                            uploaded_elem.innerHTML = self.cfg.success_message || "";//"Upload complete.";
                        //}
                    }   
                    let progress_elem = document.getElementById(self.brick_id + "$progress") as HTMLProgressElement;
                    if(progress_elem) {
                        progress_elem.value = 0;
                        progress_elem.classList.add("hidden");
                    }              
                };
                xhr.onerror = function () {
                    resolve(undefined);
                    let uploaded_elem = document.getElementById(self.brick_id + "$uploaded");
                    if(uploaded_elem) {
                        uploaded_elem.innerHTML = "An error occurred during the upload";
                    }
                };
                xhr.onabort = function(e) {
                    resolve(undefined);
                    let uploaded_elem = document.getElementById(self.brick_id + "$uploaded");
                    if(uploaded_elem) {
                        uploaded_elem.innerHTML = "Upload aborted";
                    }
                }
                xhr.send(formData);
             }) as string | undefined;

            let res_data;
            try {
                if(body !== undefined) {
                    res_data = JSON.parse(body);
                }
            }
            catch(err) {
                res_data = body;
            }
            console.log("c_fileupload "+this.brick_id +" RES "+JSON.stringify(res_data));

            if(res_data !== undefined) {
                this.cb_emit({ "@" : res_data, "@filename" : self.file_name });
            }
        }
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {

    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}
