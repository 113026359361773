import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cd_flexbox_webcomponent extends HTMLElement {
    ci: web_cd_flexbox | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cd_flexbox(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cd-flexbox', cd_flexbox_webcomponent);

export class web_cd_flexbox extends CodeBrick {

    element: HTMLElement;
    empty_data_message = "";
    
    cements = {} as { [child_idx: number]: any };

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        
        element.innerHTML = `<div class="cd-flexbox" id="${this.brick_id}" brick_idx="${this.ci_idx}"></div>`;

    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("cd-flexbox "+this.brick_id+" cb_event input "+input+" source "+info.source+" cfg "+JSON.stringify(cfg));

        if(input == "data") {

            this.flush_dynamic();

            let slot = document.getElementById(this.brick_id);
            if(slot) {    
                let innerHTML = '';
                if(cfg && this.blueprint.contains) {
                    let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

                    let row_idx = 0;
                    for(let di in cfg) {
                        let d = cfg[di];
                        let i = 0;

                        let child_dc = dc_root + "--" + row_idx;

                        //console.log(dc_root+" /// "+sub.brick.name +" "+child_dc);                 
                        for(let sub of this.blueprint.contains) {


                            //if(render_condition) {
                                let cement = this.cements[i] || sub.cement;

                                // if(cement && cement.hidden) {
                                //     continue;
                                // }
                                let cement_child_id = this.brick_id+"$"+row_idx+"$"+i;

              
                                let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc);
                                if(cement) { //if(sub.type[1] == '-') {

                                    let hidden = (cement.hidden) ? ` hidden` : "";

                                    let lop_style = "";
                                    let lop_classes = "";
                                    if(sub.cement) {
                                        for(let v in cement) {
                                            if(v == "style_classes") {
                                                if(sub.cement[v]) {
                                                    lop_classes = cement[v].join(' ');
                                                }
                                            }
                                            else {
                                                lop_style += v+':'+cement[v]+";"
                                            }
                                        }
                                    }
                                    let prefix = sub.type.split('-')[0];
                                    if(prefix.indexOf('i') == -1 && prefix[0] != 's') {
                                        innerHTML += `<div style="${lop_style}" class="${lop_classes}${hidden}" id="${cement_child_id}">${brick}</div>`;
                                    }
                                    else {
                                        innerHTML += `<div style="position:absolute">` + brick + `</div>`; //flexbox ignores child elements with position absolute, for thing like gap, which is wah we want for invisible components. We can't use display:none because that breaks stuff
                                    }
                                }
                                else {
                                    innerHTML += brick;
                                }
                            //}    
                            i++;                     
                        }
                        row_idx++;
                    }

                }

                if(innerHTML == '' && this.empty_data_message) {
                    innerHTML = `<div class="cd-table-empty">${this.empty_data_message || ''}</div>`;
                }

                slot.innerHTML = innerHTML;

                //console.log("cd-flexbox  "+this.blueprint.name +": "+innerHTML);

                //console.log("cd-flexbox "+this.brick_id+" send "+JSON.stringify(cfg));

                await this.send_dynamic_initialisation_events(cfg);

                //return { '@' : cfg };
            }
        }
        else if(input == "cfg") {
            let container = document.getElementById(this.brick_id);
            if(container) {
                if(cfg.hidden) {
                    container.classList.add("hidden");
                }
                else {
                    container.classList.remove("hidden");
                }

                for(let option in cfg) {
                    if(option == "flex-wrap" && cfg[option]) {
                        container.style.flexWrap = cfg["flex-wrap"];
                    }
                    else if(option == "align-items" && cfg[option]) {
                        container.style.alignItems = cfg["align-items"];
                    }
                    else if(option == "align-content" && cfg[option]) {
                        container.style.alignContent = cfg["align-content"];
                    }
                    else if(option == "flex-direction" && cfg[option]) {
                        container.style.flexDirection = cfg["flex-direction"];
                    }
                    else if(option == "justify-content" && cfg[option]) {
                        container.style.justifyContent = cfg["justify-content"];
                    }
                    else if(option == "gap" && cfg[option]) {
                        container.style.gap = cfg["gap"];
                    }
                    // else if(option == "style_classes" && cfg[option]) {
                    //     let classes = cfg[option];
                    //     if(classes) {
                    //         for(let v of classes) {
                    //             if(v) {
                    //                 container.classList.add(v);
                    //             }
                    //         }
                    //     }
                    // }
                    // else if(option == "style" && cfg[option]) {
                    //     let styles = cfg[option];
                    //     if(styles) {
                    //         for(let v in styles) {
                    //             if(styles[v]) {
                    //                 container.style.setProperty(v, styles[v]);
                    //             }
                    //         }
                    //     }
                    // }
                }
                CBWebUtil.ApplyElementStyles(container, cfg, "flexbox");
            }
            this.empty_data_message = cfg.empty_data_message;
        }

    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
        this.cements = cements;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {

        let cement_child_id = this.brick_id+"$"+row_idx+"$"+child_idx;

        let child = document.getElementById(cement_child_id);
        if(child) {
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
        }

    }
    cb_status(status: string): void {
        let container = document.getElementById(this.brick_id);
        if(container) {
            if(status == "loading") {
                container.innerHTML = '<div class="loader loader-loading"></div>';
            }
            else {
                if(container.firstElementChild) {
                    container.firstElementChild.classList.remove("loader");
                    container.firstElementChild.classList.remove("loader-loading");
                }
            }
        }
    }
    cb_snapshot() {}
}


