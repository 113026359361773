import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { Clone, SplitGetLast, array_move } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cd_dragndrop_container_webcomponent extends HTMLElement {
    ci: web_cd_dragndrop_container | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cd_dragndrop_container(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cd-dragndrop-container', cd_dragndrop_container_webcomponent);

export class web_cd_dragndrop_container extends CodeBrick {

    element: HTMLElement;

    cements = {} as { [child_idx: number]: any };

    empty_data_message = "";

    grab_offset_x = 0;
    grab_offset_y = 0;

    data: any;

    //child_classes = "";

    edit_mode = false;

    loaded = false;

    cfg = {} as any;

    drag_target: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });
        this.init_cement();

        element.innerHTML = `<div class="cd-dragndrop-container" id="${this.brick_id}"></div>`
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log(this.brick_id + " " + input +" "+JSON.stringify(cfg));

        if(input == "hide") {
            let slot = document.getElementById(this.brick_id);
            if(slot) {
                if(cfg) {
                    slot.style.display = "none";
                }
                else {
                    slot.style.display = "flex";
                }
            }
        }
        else if(input == "cfg") {
            this.cfg = cfg;

            //let container_style = "";

            let container = document.getElementById(this.brick_id);
            if(container) {
                if(cfg.hidden) {
                    container.classList.add("hidden");
                }
                else {
                    container.classList.remove("hidden");
                }
                CBWebUtil.ApplyElementStyles(container, cfg, "container");
            }
            this.empty_data_message = cfg.empty_data_message;

            // if(cfg.style) {
            //     for(let v in cfg.style) {
            //         container_style += v+':'+cfg.style[v]+";"
            //     }
            // }

            // let classes = '';
            // if(cfg.style_classes) {
            //     for(let cl in cfg.style_classes) {
            //         classes += cfg.style_classes[cl] + " ";
            //     }
            // }

            // if(cfg.child_classes) {
            //     for(let cl in cfg.child_classes) {
            //         this.child_classes += cfg.child_classes[cl] + " ";
            //     }
            // }

            let data = cfg.data;
            this.data = data;

            await this.renderData(data, container);
        }
        else if(input == "edit_mode") {
            if(cfg) {
                if(this.loaded  && !this.edit_mode) {
                    this.enterEditMode();
                }
                this.edit_mode = true;
            }
            else {
                if(this.data) {
                    let blocks = this.element.querySelectorAll(".dragndrop-element");

                    blocks.forEach((draggable) => {
                        draggable.removeAttribute("draggable");
                        draggable.classList.remove("dragndrop-element-draggable");
                    });

                    let controls = this.element.querySelectorAll(".dragndrop-element-controls");
                    controls.forEach((element) => {
                        element.remove();
                    });

                    let container = this.element.querySelector(".cd-dragndrop-container");
                    if(container) {
                        container.classList.remove("cd-dragndrop-container-editmode");
                    }
                }
                this.edit_mode = false;
            }
        }
    }

    async renderData(data: any, container: any) {
        this.flush_dynamic();
        let innerHTML = '';
        if(container) {    
            
            if(data && this.blueprint.contains) {
                let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

                let row_idx = 0;
                let i = 0;
                for(let di in data) {

                    let d = data[di];

                    let child_dc = dc_root + "--" + row_idx;

                    let child_style_str = CBWebUtil.GetElementStylesString("", this.cfg, "child");
                    
                    for(let sub of this.blueprint.contains) {

                        let cement = this.cements[i] || sub.cement;
                            
                        let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc);
                    
                        if(cement) { 

                            let classes = "";
                            let cement_unresolved = false;
                            if(sub.cement && sub.cement.style_classes) {
                                for(let c of sub.cement.style_classes) {
                                    if(c.includes("{{")) {
                                        cement_unresolved = true;
                                        break;
                                    }
                                }
                                if(!cement_unresolved) {
                                    classes = sub.cement.style_classes.join(" ");
                                }
                            }
        
                            if(cement && cement.hidden) {
                                classes += " hidden";
                            }

                            let prefix = sub.type.split('-')[0];
                            if(prefix.indexOf('i') == -1 && prefix[0] != 's') {

                                let drag_info = {
                                    source_id: this.brick_id,                                
                                    tags: this.cfg.drag_tags
                                };
                                
                                innerHTML += `<div class="dragndrop-element ${classes}" id="${this.brick_id}$${i++}" cd-data="${CBWebUtil.escapeHtml(JSON.stringify(d))}" drag-info="${CBWebUtil.escapeHtml(JSON.stringify(drag_info))}"><div ${child_style_str} >${brick}</div></div>`;
                            }
                            else {
                                innerHTML += `<div style="position:absolute">` + brick + `</div>`; //flexbox ignores child elements with position absolute, for thing like gap, which is wah we want for invisible components. We can't use display:none because that breaks stuff
                            }
                        }
                        else {
                            innerHTML += brick;
                        }
                    }
                    row_idx++;
                }
                
            }

            this.element.innerHTML = `<div class="cd-dragndrop-container" id="${this.brick_id}">` + innerHTML + `</div>`;
        }

        if(data) {
            await this.send_dynamic_initialisation_events(data);
        }

        if(this.edit_mode) {
            this.enterEditMode();
        }

        this.loaded = true;
                
    }

    enterEditMode() {

        if(!this.data) {
            return;
        }
        this.edit_mode = true;

        let blocks = this.element.querySelectorAll(".dragndrop-element");

        let position = 0;
        let self = this;

        blocks.forEach((draggable) => {
            draggable.setAttribute('draggable', true);
            draggable.classList.add("dragndrop-element-draggable");

            let controls_html = `<div class="dragndrop-element-controls" position="${position}">
                <div class="dragndrop-element-controls-delete" onclick=""></div>
            </div>`;
            if(self.cfg.allow_delete) {
                draggable.insertAdjacentHTML("beforeend", controls_html);
            }

            let drag_handle = `<div class="drag-handle"></div>`;
            draggable.insertAdjacentHTML("beforeend", drag_handle);

            let pos = position;

            let del_btn = this.element.querySelector('.dragndrop-element-controls[position="'+position+'"] > .dragndrop-element-controls-delete');
            if(del_btn) {
                del_btn.addEventListener("click", function() {
                    self.deleteBlock(pos);
                })
            }

            draggable.addEventListener("mousedown", (e: any) => {
                self.drag_target = e.target;
            });

            draggable.addEventListener("dragstart", (e: any) => {
                if(!self.drag_target.classList.contains("drag-handle")) {
                    e.preventDefault();
                    return;
                }

                e.dataTransfer.setData("text", e.target.id);

                (<any>window).drag_info = {
                    source_id: self.brick_id,                                 
                    tags: self.cfg.drag_tags
                };

                draggable.classList.add("dragging");
            });
            draggable.addEventListener("dragend", () => {
                draggable.classList.remove("dragging");
                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });

            draggable.addEventListener("dragenter", () => {
                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
                draggable.classList.add("dropzone");
            });

            // draggable.addEventListener("dragleave", () => {
            //     //draggable.classList.remove("dropzone");
            // });

            draggable.addEventListener("dragover", (e: any) => {
                if(!self.may_drop()) {
                    return;
                }

                e.preventDefault();
                e.stopPropagation();               
            });

            position++;
        });

        let container = this.element.querySelector(".cd-dragndrop-container");

        if(container) {

            container.classList.add("cd-dragndrop-container-editmode");

            container.addEventListener("dragover", (e: any) => {
                if(!self.may_drop()) {
                    return;
                }

                e.preventDefault();

                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
                

            });

            container.addEventListener("drop", (e: any) => {
                e.preventDefault();
                e.stopPropagation();

                var data = e.dataTransfer.getData("text");
                let block = document.getElementById(data);
                if(container && block) {
                    let drag_info = JSON.parse(CBWebUtil.unescapeHtml(block.getAttribute("drag-info") || ""));
                    let cd_data = JSON.parse(CBWebUtil.unescapeHtml(block.getAttribute("cd-data") || ""));

                    //console.log("drop onto "+data+" drag_info "+JSON.stringify(drag_info) + " cd_data "+JSON.stringify(cd_data));

                    if(drag_info.source_id == self.brick_id) {

                        block.classList.remove("dragging");

                        let dropzone = self.element.querySelector(".dropzone");
                        if(dropzone) {

                            let dest_id = dropzone.id;

                            let src_id = data;
                                    
                            let dest_idx = Number(SplitGetLast(dest_id, "$"));
                            let src_idx = Number(SplitGetLast(src_id, "$"));

                            array_move(self.data, src_idx, dest_idx);
                            
                            if(dest_id < src_id) {
                                container.insertBefore(block, dropzone);
                            }
                            else {
                                self.insertAfter(dropzone, block);
                            }

                            dropzone.id = src_id;
                            block.id = dest_id;
                        }
                        else {
                            container.appendChild(block);
                        }
                    }
                    else {
                        //console.log("drop external data "+data);

                        let dropzone = self.element.querySelector(".dropzone");
                        if(dropzone) {
                            let dest_id = dropzone.id;
                            let dest_idx = Number(SplitGetLast(dest_id, "$"));

                            self.data.splice(dest_idx, 0, cd_data);
                        }
                        else {
                            self.data.push(cd_data);
                        }

                        let container = document.getElementById(self.brick_id);
                        if(container) {
                            self.renderData(self.data, container);
                        }           
                    }

                    self.saveLayout();
                }

                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });
            
        }
    }

    may_drop() {
        let drag_info = (<any>window).drag_info;
        if(drag_info) {

            if(drag_info.source_id == this.brick_id) {
                if(this.cfg.disallow_move) {
                    return;
                }
            }
            else {
                try {
                    let intersection = this.cfg.drop_tags.filter(function(n:any) {
                        return drag_info.tags.indexOf(n) !== -1;
                    });
                    if(!intersection || intersection.length == 0) {
                        return false;
                    }
                }
                catch(err) {
                    console.error(err);
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    deleteBlock(position: number) {
        let controls = this.element.querySelector('.dragndrop-element-controls[position="'+position+'"]');
        if(controls && controls.parentElement) {
            //controls.parentElement.remove();
            this.data.splice(position, 1);

            let container = document.getElementById(this.brick_id);
            if(container) {
                this.renderData(this.data, container);
            }

            this.saveLayout();
        }
    }

    saveLayout() {
        for(let d = 0; d < this.data.length; d++) {
            this.data[d]["Position"] = d;
        }

        this.cb_emit({ "@data" : this.data });
    }

    insertAfter(referenceNode: any, newNode: any) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
        this.cements = cements;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {

        let child = document.getElementById(this.brick_id+"$"+row_idx);
        if(child && cement) {
            if(cement.style_classes) {
                for(let c of cement.style_classes) {
                    child.classList.add(c);     
                }
            }
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
        }
    }
    cb_status(status: string): void {
        if(status == "loading") {
            let container = document.getElementById(this.brick_id);
            if(container) {
                container.innerHTML = '<div class="loader loader-loading"></div>';
            }
        }
    }
    cb_snapshot() {}
}


