import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_position_webcomponent extends HTMLElement {
    ci: web_cc_position | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_position(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-position', cc_position_webcomponent);

export class web_cc_position extends CodeBrick {

    element: HTMLElement;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });

        let container_style = "";
        if(this.blueprint.ins.cfg && this.blueprint.ins.cfg) {
            for(let v in this.blueprint.ins.cfg.style) {
                container_style += v+':'+this.blueprint.ins.cfg.style[v]+";"
            }
        }
        element.innerHTML = `<div id="${this.brick_id}" style="${container_style}"></div>`;

        let slot = element.querySelector('div');
        if(slot) {    
            let innerHTML = '';
            if(this.blueprint.contains) {
                let i = 0;
                for(let sub of this.blueprint.contains) {
                    let lop_style = "";
                    let lop_classes = "";
                    if(sub.cement) {
                        for(let v in sub.cement) {
                            if(v == "style-classes") {
                            }
                            else if(v == "style") {
                                if(sub.cement[v] && typeof sub.cement[v] == "object") {
                                    for(let s in sub.cement[v]) {
                                        lop_style += s+':'+sub.cement[v][s]+";"
                                    }
                                }
                            }
                            else {
                                lop_style += v+':'+sub.cement[v]+";"
                            }
                        }

                        if(sub.cement["style-classes"] && Array.isArray(sub.cement["style-classes"])) {
                            for(let c of sub.cement["style-classes"]) {
                                lop_classes += c + " ";
                            }
                        }
                    }
                    let brick = CBWebUtil.BrickHtml(sub, this, i);
                    innerHTML += `<div style="${lop_style}" class="${lop_classes}">${brick}</div>`;

                    i++;
                }
            }
            slot.innerHTML = innerHTML;
        }

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}


