import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { Clone, SplitGetLast, array_move } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cd_layout_grid_webcomponent extends HTMLElement {
    ci: web_cd_layout_grid | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cd_layout_grid(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cd-layout-grid', cd_layout_grid_webcomponent);

export class web_cd_layout_grid extends CodeBrick {

    element: HTMLElement;

    cements = {} as { [child_idx: number]: any };

    empty_data_message = "";

    grab_offset_x = 0;
    grab_offset_y = 0;

    data: any;

    //child_classes = "";

    edit_mode = false;

    loaded = false;

    cfg = {} as any;

    drag_target: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });
        //this.init_cement();

        element.innerHTML = `<div class="cc-layout-grid-container" id="${this.brick_id}"></div>`
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        if(input == "hide") {
            let slot = document.getElementById(this.brick_id);
            if(slot) {
                if(cfg) {
                    slot.style.display = "none";
                }
                else {
                    slot.style.display = "flex";
                }
            }
        }
        else if(input == "cfg") {
            this.cfg = cfg;

            let container = document.getElementById(this.brick_id);
            if(container) {
                if(cfg.hidden) {
                    container.classList.add("hidden");
                }
                else {
                    container.classList.remove("hidden");
                }
            
                // if(cfg.style) {
                //     for(let v in cfg.style) {
                //         if(cfg.style[v]) {
                //             container.style.setProperty(v, cfg.style[v]); 
                //         }
                //     }
                // }

                // if(cfg.style_classes) {
                //     for(let cl in cfg.style_classes) {
                //         //classes += cfg.style_classes[cl] + " ";
                //         if(cfg.style_classes[cl]) {
                //             container.classList.add(cfg.style_classes[cl]);
                //         }
                //     }
                // }

                // if(cfg.child_classes) {
                //     for(let cl in cfg.child_classes) {
                //         this.child_classes += cfg.child_classes[cl] + " ";
                //     }
                // }
                CBWebUtil.ApplyElementStyles(container, cfg, "container");
            }

            this.empty_data_message = cfg.empty_data_message;
            let data = cfg.data;
            this.data = data;

            await this.renderData(data, container);
       }
        else if(input == "edit_mode") {
            if(cfg) {
                if(this.loaded  && !this.edit_mode) {
                    this.enterEditMode();
                }
                this.edit_mode = true;
            }
            else {
                if(this.data) {
                    let blocks = this.element.querySelectorAll(".cc-layout-grid-cell");

                    blocks.forEach((draggable) => {
                        draggable.removeAttribute("draggable");
                        draggable.classList.remove("dragndrop-element-draggable");
                    });

                    let controls = this.element.querySelectorAll(".dragndrop-element-controls");
                    controls.forEach((element) => {
                        element.remove();
                    });

                    let container = this.element.querySelector(".cc-layout-grid-container");
                    if(container) {
                        container.classList.remove("cc-layout-grid-container-editmode");
                    }
                }
                this.edit_mode = false;
            }
        }
    }

    async renderData(data: any, container: any) {
        this.flush_dynamic();
        let innerHTML = '';
        if(container) {    
            
            if(data && this.blueprint.contains) {
                let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

                data = this.fillGaps(data);

                let row_idx = 0;
                let i = 0;
                for(let di in data) {

                    let d = data[di];

                    let child_dc = dc_root + "--" + row_idx;

                    
                    //console.log(dc_root+" /// "+sub.brick.name +" "+child_dc);                 
                    for(let sub of this.blueprint.contains) {

                        let cement = this.cements[i] || sub.cement;

                        // if(cement.hidden) {
                        //     continue;
                        // }
                            
                        let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc);
                    
                        //innerHTML += `<div class="cc-layout-grid-cell ${classes}" id="${this.brick_id}$${i}"><div style="${lop_style}" class="cc-layout-grid-item ${child_classes}${lop_classes}" >${brick}</div></div>`;
                        if(cement) { //if(sub.type[1] == '-') {

                            let lop_style = "";
                            let lop_classes = "";
                            if(sub.cement) {
                                for(let v in cement) {
                                    if(v == "style_classes") {
                                        if(sub.cement[v]) {
                                            lop_classes = cement[v].join(' ');
                                        }
                                    }
                                    else {
                                        lop_style += v+':'+cement[v]+";"
                                    }
                                }
                            }

                            let cols = d.block_width || cement.width || 12;
                            let classes = "";
        
                            classes = "w"+cols;
        
                            if(cement && cement.hidden) {
                                classes += " hidden";
                            }

                            let cement_child_id = this.brick_id+"$"+row_idx+"$"+i;

                            let prefix = sub.type.split('-')[0];
                            if(prefix.indexOf('i') == -1 && prefix[0] != 's') {
                                
                                let drag_info = {
                                    source_id: this.brick_id,                                
                                    tags: this.cfg.drag_tags
                                };

                                innerHTML += `<div class="cc-layout-grid-cell ${classes}" id="${cement_child_id}" cd-data="${CBWebUtil.escapeHtml(JSON.stringify(d))}" drag-info="${CBWebUtil.escapeHtml(JSON.stringify(drag_info))}"><div style="${lop_style}" class="cc-layout-grid-item ${lop_classes}" >${brick}</div></div>`;
                                
                                i++;
                            }
                            else {
                                innerHTML += `<div style="position:absolute">` + brick + `</div>`; //flexbox ignores child elements with position absolute, for thing like gap, which is wah we want for invisible components. We can't use display:none because that breaks stuff
                            }
                        }
                        else {
                            innerHTML += brick;
                        }
                        
                    }
                    row_idx++;
                }
                
            }

            innerHTML += `</div>`;

            //container.innerHTML = innerHTML;
            //this.element.innerHTML = `<div class="cc-layout-grid-container" id="${this.brick_id}">` + innerHTML + `</div>`;
            let container = document.getElementById(this.brick_id);
            if(container) {
                container.innerHTML = innerHTML;
            }
        }

        await this.send_dynamic_initialisation_events(data);

        if(this.edit_mode) {
            this.enterEditMode();
        }

        this.loaded = true;
                
    }

    
    fillGaps(data: any) {

        //We need to clone, so it will start from original w's when we add or remove elements
        let cloned_data = [];
        if(this.edit_mode) {
            for(let d of data) {
                cloned_data.push(Clone(d));
            }
            data = cloned_data;
        }

        //Algorithm to adjust width to fill gaps with minimum % width change
        let row = 0;
        let row_width = 0;
        let row_start = 0;               
        for(let di = 0; di < data.length; di++) {
            let d = data[di];
            let w = d.block_width || 12;
            row_width += w;
            if(row_width == 12) {
                //no gap.
                row++;
                row_width = 0;
                row_start = di;
            }
            else if(row_width > 12) {
                //does no fit, so we have a gap.
                let was_row_width = (row_width - w)
                let gap_size = 12 - was_row_width;

                //See what largest change required is to grow elements to fill the gap
                let grow_frac = 12 / was_row_width;

                //See what change would be to squeese next element in to fill gap
                let squeeze_frac = row_width / 12;

                let max_sqeeze = 0.75;

                let do_grow = grow_frac < squeeze_frac;

                //console.log("di "+di+" w "+w+" grow_frac "+ grow_frac+ " squeeze_frac "+squeeze_frac+" do_grow "+ do_grow);



                if(!do_grow) {
                    //check if we can squeeze, given max_sqeeze
                    // for(let e = row_start; e < di; e++) {
                    //     let new_w = Math.floor(w / squeeze_frac);
                    //     if(w / new_w < max_sqeeze) {
                    //         do_grow = true;
                    //         break;
                    //     }
                    // }
                    let sort_by_size = data.slice(row_start, di + 1);
                    sort_by_size.sort(function(a: any, b: any) {
                        return a.block_width - b.block_width;
                    });

                    //OK, we can squeeze
                    let new_row_w = 0;
                    let old_cumulative = 0;
                    for(let e = sort_by_size.length-1; e > 0; e--) {
                        let ew = sort_by_size[e].block_width;
                        let new_w = Math.floor(ew / squeeze_frac);
                        old_cumulative += ew;
                        new_row_w += new_w;
                        sort_by_size[e].block_width = new_w;
                        squeeze_frac = (row_width - old_cumulative) / (12 - new_row_w); //what we need to fit / what we have left
                        if(squeeze_frac < 0.01) {
                            break;
                        }
                    }
                }

                if(do_grow) {

                    let sort_by_size = data.slice(row_start, di);
                    sort_by_size.sort(function(a: any, b: any) {
                        return a.block_width - b.block_width;
                    });
                    //grow to close
                    let new_row_w = 0;
                    let old_cumulative = 0;
                    for(let e = sort_by_size.length-1; e > 0; e--) {
                        let ew = sort_by_size[e].block_width;
                        let new_w = Math.ceil(ew * grow_frac);

                        //console.log("grow from "+ew+" to "+new_w);

                        old_cumulative += ew;
                        new_row_w += new_w;
                        sort_by_size[e].block_width = new_w;
                        grow_frac = (12 - new_row_w) / (12 - old_cumulative) //space left / width left
                        if(grow_frac < 1 || Math.abs(1 - grow_frac) < 0.01) {
                            break;
                        }
                    }
                    row++;
                    row_width = w;
                    row_start = di;
                }
            }
        }
        return data;
    }

    enterEditMode() {

        if(!this.data) {
            return;
        }
        this.edit_mode = true;

        let blocks = this.element.querySelectorAll(".cc-layout-grid-cell");

        let position = 0;
        let self = this;

        blocks.forEach((draggable) => {

            //console.log("enterEditMode "+position);

            if(draggable.classList.contains("dragndrop-element-draggable")) {
                //console.log("enterEditMode "+position+" already");
                return;
            }
            
            draggable.setAttribute('draggable', true);
            draggable.classList.add("dragndrop-element-draggable");

            let controls_html = `<div class="dragndrop-element-controls" position="${position}">
                <div class="dragndrop-element-controls-delete" onclick=""></div>
            </div>`;

            draggable.insertAdjacentHTML("beforeend", controls_html);

            let drag_handle = `<div class="drag-handle"></div>`;
            draggable.insertAdjacentHTML("beforeend", drag_handle);

            let pos = position;

            let del_btn = this.element.querySelector('.dragndrop-element-controls[position="'+position+'"] > .dragndrop-element-controls-delete');
            if(del_btn) {
                del_btn.addEventListener("click", function() {
                    self.deleteBlock(pos);
                })
            }

            draggable.addEventListener("mousedown", (e: any) => {
                self.drag_target = e.target;
            });

            draggable.addEventListener("dragstart", (e: any) => {
                if(!self.drag_target.classList.contains("drag-handle")) {
                    e.preventDefault();
                    return;
                }

                e.dataTransfer.setData("text", e.target.id);

                (<any>window).drag_info = {
                    source_id: self.brick_id,                                 
                    tags: self.cfg.drag_tags
                };

                draggable.classList.add("dragging");
            });
            draggable.addEventListener("dragend", () => {
                draggable.classList.remove("dragging");
                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });

            draggable.addEventListener("dragenter", () => {
                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
                draggable.classList.add("dropzone");
            });

            // draggable.addEventListener("dragleave", () => {
            //     //draggable.classList.remove("dropzone");
            // });

            draggable.addEventListener("dragover", (e: any) => {
                if(!self.may_drop()) {
                    return;
                }
                e.preventDefault();
                e.stopPropagation();
            });

            position++;
        });

        let container = this.element.querySelector(".cc-layout-grid-container");

        if(container && container.getAttribute("has_drag_handlers") == null) {

            container.setAttribute("has_drag_handlers", "true");

            container.classList.add("cc-layout-grid-container-editmode");

            container.addEventListener("dragover", (e: any) => {
                if(!self.may_drop()) {
                    return;
                }
                e.preventDefault();

                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });

            container.addEventListener("drop", (e: any) => {
                e.preventDefault();
                e.stopPropagation();

                var data = e.dataTransfer.getData("text");
                let block = document.getElementById(data);
                if(container && block) {
                    let drag_info = JSON.parse(CBWebUtil.unescapeHtml(block.getAttribute("drag-info") || ""));
                    let cd_data = JSON.parse(CBWebUtil.unescapeHtml(block.getAttribute("cd-data") || ""));

                    //console.log("drop onto "+data+" drag_info "+JSON.stringify(drag_info) + " cd_data "+JSON.stringify(cd_data));

                    if(drag_info.source_id == self.brick_id) {

                        block.classList.remove("dragging");

                        let dropzone = self.element.querySelector(".dropzone");
                        if(dropzone) {

                            let dest_id = dropzone.id;
                            let src_id = data;
                                    
                            let dest_idx = Number(SplitGetLast(dest_id, "$"));
                            let src_idx = Number(SplitGetLast(src_id, "$"));

                            array_move(self.data, src_idx, dest_idx);
                            
                            if(dest_id < src_id) {
                                container.insertBefore(block, dropzone);
                            }
                            else {
                                self.insertAfter(dropzone, block);
                            }

                            dropzone.id = src_id;
                            block.id = dest_id;

                            self.saveLayout();
                        }
                        else {
                            container.appendChild(block);
                        }
                    }
                    else {
                        //console.log("drop external data "+data);

                        let dropzone = self.element.querySelector(".dropzone");
                        if(dropzone) {
                            let dest_id = dropzone.id;
                            let dest_idx = Number(SplitGetLast(dest_id, "$"));

                            self.data.splice(dest_idx, 0, cd_data);
                        }
                        else {
                            self.data.push(cd_data);
                        }

                        let container = document.getElementById(self.brick_id);
                        if(container) {
                            self.renderData(self.data, container);
                        }

                        self.saveLayout();
                    }
                }

                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });
            
        }
    }

    may_drop() {
        let drag_info = (<any>window).drag_info;
        if(drag_info) {

            if(drag_info.source_id == this.brick_id) {
                if(this.cfg.disallow_move) {
                    return;
                }
            }
            else {
                try {
                    let intersection = this.cfg.drop_tags.filter(function(n:any) {
                        return drag_info.tags.indexOf(n) !== -1;
                    });
                    if(!intersection || intersection.length == 0) {
                        return false;
                    }
                }
                catch(err) {
                    console.error(err);
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    deleteBlock(position: number) {
        let controls = this.element.querySelector('.dragndrop-element-controls[position="'+position+'"]');
        if(controls && controls.parentElement) {
            //controls.parentElement.remove();
            this.data.splice(position, 1);

            let container = document.getElementById(this.brick_id);
            if(container) {
                this.renderData(this.data, container);
            }

            this.saveLayout();
        }
    }

    saveLayout() {
        for(let d = 0; d < this.data.length; d++) {
            this.data[d]["Position"] = d;
        }

        //console.log("cd-layout-grid emit "+JSON.stringify(this.data));

        this.cb_emit({ "@data" : this.data });
    }

    insertAfter(referenceNode: any, newNode: any) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
        this.cements = cements;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
        let cement_child_id = this.brick_id+"$"+row_idx+"$"+child_idx;

        let child = document.getElementById(cement_child_id);
        if(child) {
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }

            let item = child.firstElementChild;
            if(item) {
                if(cement.style_classes) {
                    for(let sc of cement.style_classes) {
                        item.classList.add(sc);
                    }
                }
            }
        }
        
    }
    cb_status(status: string): void {
        let container = document.getElementById(this.brick_id);
        if(container) {
            if(status == "loading") {
                container.innerHTML = '<div class="loader loader-loading"></div>';
            }
            else {
                if(container.firstElementChild) {
                    container.firstElementChild.classList.remove("loader");
                    container.firstElementChild.classList.remove("loader-loading");
                }
            }
        }
    }
    cb_snapshot() {}
}


