import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import { CBWebUtil } from "../controls/cb_web_util";

export class cci_modal_webcomponent extends HTMLElement {
    ci: web_cci_modal | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;

            //standalone
            let ins = this.getAttribute('ins') as string;

            this.ci = new web_cci_modal(context, cid, name, dc, Number(idx), container_id, this, ins);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cci-modal', cci_modal_webcomponent);

export class web_cci_modal extends CodeBrick {

    element: HTMLElement;
    outdata: any;

    //style_classes = [] as string[];

    body_content = '';

    initialized = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement, standalone_ins: string) {
        super(context, cid, name, dc, idx, container_id, standalone_ins);
        this.element = element;

    
        //Add this to all bricks that need to support standalone, as well as the ins parameter
        if(cid == undefined) {
            this.blueprint.name = element.id;
            this.brick_id = element.id + "_brick";
            (<any>window).so_bricks =  (<any>window).so_bricks || {};
            (<any>window).so_bricks[this.brick_id] = this;
            this.body_content = element.innerHTML;
        }


        let html = `<div id="${this.brick_id}" class="cci-modal-background">
        </div>`;

        this.element.innerHTML = html;

        let self = this;

        this.init_cement();

        //Add this also to all bricks that need to support standalone
        if(cid == undefined) {
            setTimeout(async function() {
                for(let input in self.blueprint.ins) {
                    let ret = await self.cb_event(input, self.blueprint.ins[input], {} as CBEventInfo);
                }
            } ,0);
            return;
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log("CodeBricksModal "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));

        this.show_loading(false); 
        
        if(input == "cfg") {

            let modal = document.getElementById(this.brick_id);

            if(!this.initialized) {
                this.initialized = true;
 
                let self = this;                                       

                if(cfg.easy_close) {
                    //this.eclose = true;
                    window.onclick = function(event:any) {
                        if (modal && event.target == modal) {
                            self.hide();
                        }
                    }
                }

                //if(cfg.cancel_button) { might be set later
                    let cancel = document.getElementById(`cci-modal-cancel_${this.brick_id}`);
                    if(cancel) {
                        cancel.addEventListener('click', function() {
                            self.hide();
                        });
                    }
                //}

                //if(cfg.ok_button) { might be set later
                    let ok = document.getElementById(`cci-modal-ok_${this.brick_id}`);
                    if(ok) {
                        ok.addEventListener('click', function() {
                            if(modal) {
                                if(self.validate_sources("")) {
                                    self.cb_emit({"@": self.outdata});
                                }
                            }
                        });
                    }
                //}


            }

            let title_element = document.getElementById(`cci-modal-title_${this.brick_id}`);
            if(title_element) {
                title_element.innerHTML = cfg.title;
            }
    
            let cancel_button = document.getElementById(`cci-modal-cancel_${this.brick_id}`);
            if(cancel_button) {
                if(cfg.cancel_button) {
                    cancel_button.innerHTML = cfg.cancel_button;
                    cancel_button.classList.remove("hidden"); 
                }
                else if(cancel_button) {
                    cancel_button.classList.add("hidden");
                }
                CBWebUtil.ApplyElementStyles(cancel_button, cfg, "cancel");
            }

            let ok_button = document.getElementById(`cci-modal-ok_${this.brick_id}`);
            if(ok_button) {
                if(cfg.ok_button) {
                    ok_button.innerHTML = cfg.ok_button;
                    ok_button.classList.remove("hidden");
                }
                else if(ok_button) {
                    ok_button.classList.add("hidden");
                }
                CBWebUtil.ApplyElementStyles(ok_button, cfg, "ok");
            }

            let buttons_container = document.getElementById(`${this.brick_id}$buttons`);
            if(buttons_container) {
                if((cfg.cancel_button || cfg.ok_button)) {
                    buttons_container.classList.remove("hidden");
                }
                else {
                    buttons_container.classList.add("hidden");
                }
            }

            var x = document.getElementById(`cci-modal-close_${this.brick_id}`);
            if(x) {
                if(cfg.no_x) {
                    x.classList.add("hidden");
                }
                else {
                    x.classList.remove("hidden");
                }
            }

            
            // let classes = cfg.style_classes;
            // if(classes && modal) {
            //     for(let v of classes) {
            //         if(v) {
            //             modal.classList.add(v);
            //         }
            //     }

            //     for(let had of this.style_classes) {
            //         let has = false;
            //         for(let v of classes) {
            //             if(v == had) {
            //                 has = true;
            //                 break;
            //             }
            //         }
            //         if(!has && had) {
            //             modal.classList.remove(had);
            //         }
            //     }
            // }
            // this.style_classes = cfg.style_classes;

            if(modal) {
                let modal_body = modal.querySelector(".cci-modal-body") as HTMLElement;

                CBWebUtil.ApplyElementStyles(modal_body, cfg, "body");

                let modal_foter = modal.querySelector(".cci-modal-footer") as HTMLElement;

                CBWebUtil.ApplyElementStyles(modal_foter, cfg, "footer");

                // if(modal_body && cfg.style) {
                //     for(let v in cfg.style) {
                //         if(cfg.style[v]) {
                //             modal_body.style.setProperty(v, cfg.style[v]);
                //         }
                //     }
                // }
            }
            
        }
        else if(input == "show") {
            this.show();
        }
        else if(input == "show_no_animate") {
            if(cfg) {
                this.show(true, false);
            }
        }
        else if(input == "hide") {
            this.hide();               
        }
        else if(input == "data") {

            this.outdata = cfg;

        }
    }



    show(push_stack = true, animate = true) {

        cb_tooltip.hide(); //don't let tooltips stay open over modal

        (<any>window).cb_modal_stack = (<any>window).cb_modal_stack || [];
        if((<any>window).cb_modal_stack.length > 0) {
            let open_modal = (<any>window).cb_modal_stack[(<any>window).cb_modal_stack.length - 1];
            open_modal.hide(false);
        }
        if(push_stack) {
            let open_modal = null;
            if((<any>window).cb_modal_stack.length > 0) {
                open_modal = (<any>window).cb_modal_stack[(<any>window).cb_modal_stack.length - 1];
            }
            if(open_modal != this) {
                (<any>window).cb_modal_stack.push(this);
            }
        }

        var modal = document.getElementById(this.brick_id);
        if(modal) {
            if(animate) {
                modal.firstElementChild?.classList.add("cci-modal-animate");
            }
            else {
                modal.firstElementChild?.classList.remove("cci-modal-animate");
            }

            modal.style.display = "flex";
        }
        if(push_stack) {
            var container = document.getElementById(this.brick_id+"$body");
            if(container) {
                container.scrollTop = 0;
            }
        }

        //Set focus on first focus-able element
        //This will have to drill into the sub, e.g. a modal containing a switchpanel, it should focus the first focusable in that....TODO
        // if(this.blueprint.contains) {
        //     for(let sub of this.blueprint.contains) {
        //         let targets = this.context.composition_runners[this.cid].get_brick_ids(sub.name, this.dc);
        //         if(targets && targets.length == 1) {
        //             let ci = this.context.bricks[targets[0]];
        //             if(ci) {
        //                 if(ci.cb_status("focus")) {
        //                     break;
        //                 }
        //             }
        //         }
        //         else {
        //             break;
        //         }
        //     }
        // }

    }

    hide(pop_stack = true) {
        let modal = document.getElementById(this.brick_id);
        if(modal) {
            modal.style.display = "none";
        }

        if(pop_stack) {
            (<any>window).cb_modal_stack = (<any>window).cb_modal_stack || [];

            if((<any>window).cb_modal_stack.length > 0) {
                let pop_modal = (<any>window).cb_modal_stack[(<any>window).cb_modal_stack.length - 1];
                if(pop_modal == this) {
                    (<any>window).cb_modal_stack.pop();
                }

                if((<any>window).cb_modal_stack.length > 0) {
                    let open_modal = (<any>window).cb_modal_stack[(<any>window).cb_modal_stack.length - 1];
                    open_modal.show(false, false);
                }
            }
        }

    }

    show_loading(show:boolean) {
        let spinner = document.getElementById(this.brick_id+"$spinner");
        let body = document.getElementById(this.brick_id+"$body");
        let footer = document.getElementById(this.brick_id+"$footer");
        let buttons = document.getElementById(this.brick_id+"$buttons");

        let yes = 'flex';
        let no = 'none';
        if(!show) {
            yes = 'none';
            no = 'flex'
        }

        if(spinner && body) {
            spinner.style.display = yes;

            //body.style.display = no;
            if(show) {
                body.classList.add('hidden');
                if(footer) {
                    footer.classList.add('hidden');
                }
                if(buttons) {
                    buttons.classList.add('hidden');
                }
            }
            else {
                body.classList.remove('hidden');
                if(footer) {
                    footer.classList.remove('hidden');
                }
                if(buttons) {
                    buttons.classList.remove('hidden');
                }
            }

        }
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
        
        let header_content = '';
        
        let footer_content = '';

        if(this.blueprint.contains) {
            let i = 0;
            for(let sub of this.blueprint.contains) {

                let prefix = sub.type.split('-')[0];
                let component = CBWebUtil.BrickHtml(sub, this, i);
                if(prefix.indexOf('i') == -1 && prefix[0] != 's') {
                    component = `<div id="${this.brick_id}$${i}" class="child-hidden">` + component + `</div>`;
                }
                else {
                    component = `<div style="position:absolute">` + component + `</div>`; //flexbox ignores child elements with position absolute, for thing like gap, which is wah we want for invisible components. We can't use display:none because that breaks stuff
                }

                let section = "body";
                if(sub.cement) {
                    section = sub.cement.section;
                }

                if(section == "header") {
                    header_content += component;
                }
                else if(section == "footer") {
                    footer_content += component;
                }
                else {
                    this.body_content += component;
                }

                i++;

            }
        }

        let cfg = this.blueprint.ins.cfg;

        //let title = cfg.title ? '<div></div>' : '';

        let buttons = '';
        //if(cfg.cancel_button || cfg.ok_button) {
        buttons += `<div id="${this.brick_id}$buttons" class="cci-modal-buttons hidden">`;
        //if(cfg.cancel_button) {
            buttons += ` <div class="cci-modal-cancel hidden" id="cci-modal-cancel_${this.brick_id}"></div>`;
        //}
        //if(cfg.ok_button) {
            buttons += ` <div class="cci-modal-ok hidden" id="cci-modal-ok_${this.brick_id}"></div>`;
        //}
        buttons += '</div>';
        //}

        let footer = `<div class="cci-modal-footer" id="${this.brick_id}$footer">`+footer_content+buttons + '</div>';

        let html = `<div class="cci-modal cci-modal-${(cfg.width || "auto").toLowerCase()}">
                <div class="cci-modal-header">
                    <span class="cci-modal-close" id="cci-modal-close_${this.brick_id}">&times;</span>
                    <span id="cci-modal-title_${this.brick_id}"></span>
                    ${header_content}
                </div>
                <div class="cci-modal-body" id="${this.brick_id}$body">
                    ${this.body_content}
                </div>
                <div id="${this.brick_id}$spinner" class="loader loader-loading"></div>
                ${footer}
            </div>`;


        let modal = document.getElementById(this.brick_id);
        if(modal) {
            modal.innerHTML = html;
        }

        let self = this;
        var x = document.getElementById(`cci-modal-close_${this.brick_id}`);
        if(x) {
            x.addEventListener('click', function(event: any) {
                self.hide();
            });
        }    

        // if(initial) {
        //     //TODO move contructor content here
        // }
        // else {
            for(let child_idx in cements) {
                let child = document.getElementById(this.brick_id+"$"+child_idx);
                let cement = cements[child_idx];
                if(child) {
                    if(cement && cement.hidden) {
                        child.classList.add("child-hidden");
                    }
                    else {
                        child.classList.remove("child-hidden");
                    }

                    if(cement && cement.style_classes) {
                        for(let c of cement.style_classes) {
                            child.classList.add(c);
                        }
                    }
                }
            }
        //}
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
        let child = document.getElementById(this.brick_id+"$"+child_idx);

        //console.log(this.brick_id+"$"+child_idx + " " +child);

        if(child) {
            if(cement && cement.hidden) {
                child.classList.add("child-hidden");
            }
            else {
                child.classList.remove("child-hidden");
            }

            if(cement && cement.style_classes) {
                for(let c of cement.style_classes) {
                    child.classList.add(c);
                }
            }
        }
    }
    cb_status(status: string): void {
        if(status == "loading") {
            this.show_loading(true); 
        }
        else {
            this.show_loading(false); 
        }
    }
    cb_snapshot() {}
}


