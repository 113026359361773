import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { SplitGetLast, array_move } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { LayoutGrid } from "../../shared-funcs/LayoutGrid";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_layout_grid_webcomponent extends HTMLElement {
    ci: web_cc_layout_grid | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_layout_grid(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-layout-grid', cc_layout_grid_webcomponent);

export class web_cc_layout_grid extends CodeBrick {

    element: HTMLElement;

    cfg = {} as any;
    edit_mode = false;
    drag_target: any;
    drop_contained = [] as {name:string, type:string}[];

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });
        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        if(input == "cfg") {
            this.cfg = cfg || {};

            let container = document.getElementById(this.brick_id);
            if(container) {
                CBWebUtil.ApplyElementStyles(container as HTMLElement, cfg, "container");

                let items = container.children;
                if(items) {
                    for(let i = 0; i < items.length; i++) {
                        let item = items[i];
                        CBWebUtil.ApplyElementStyles(item as HTMLElement, cfg, "item");
                    }
                }
            }
        }
        else if(input == "hide") {
            let slot = document.getElementById(this.brick_id);
            if(slot) {
                if(cfg) {
                    slot.style.display = "none";
                }
                else {
                    slot.style.display = "flex";
                }
            }
        }
        else if(input == "edit_mode") {
            if(cfg) {
                if(!this.edit_mode) {
                    this.enterEditMode();
                }
                this.edit_mode = true;
            }
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {

        //console.log("cc-layout-grid "+this.brick_id+" initial "+initial+" cb_cement "+JSON.stringify(cements));
        
        //order childs by row and position
        let ordered_childs = LayoutGrid.orderChilds(this.blueprint); 

        let container_style = "";

        // if(this.blueprint.ins && this.blueprint.ins.cfg && this.blueprint.ins.cfg.style) {
        //     for(let v in this.blueprint.ins.cfg.style) {
        //         container_style += v+':'+this.blueprint.ins.cfg.style[v]+";"
        //     }
        // }

        // let classes = '';
        // if(this.blueprint.ins && this.blueprint.ins.cfg && this.blueprint.ins.cfg.style_classes) {
        //     for(let cl in this.blueprint.ins.cfg.style_classes) {
        //         classes += this.blueprint.ins.cfg.style_classes[cl] + " ";
        //     }
        // }

        // let child_classes = '';
        // if(this.blueprint.ins && this.blueprint.ins.cfg && this.blueprint.ins.cfg.child_classes) {
        //     for(let cl in this.blueprint.ins.cfg.child_classes) {
        //         child_classes += this.blueprint.ins.cfg.child_classes[cl] + " ";
        //     }
        // }

        let at_row = undefined;
        let innerHTML = `<div style="${container_style}" class="cc-layout-grid-container" id="${this.brick_id}">`

        
        //TODO calculate md widths to fill gaps for md


        for(let c = 0; c < ordered_childs.length; c++) {
            let sub = ordered_childs[c].sub;
            let cement_idx = ordered_childs[c].p;
            let cement = cements[cement_idx] || {};

            let lop_classes = "";

            let brick = CBWebUtil.BrickHtml(sub, this, cement_idx);

            let prefix = sub.type.split('-')[0];
            if(prefix.indexOf('i') == -1 && prefix[0] != 's') {

                let lop_style = "";
                
                if(cement) {
                    for(let v in cement) {
                        if(v == "style_classes") {
                            if(cement[v]) {
                                lop_classes = sub.cement[v].join(' ');
                            }
                        }
                        else if(v != "width" && v != "row" && v != "position" && v != "hidden") {
                            lop_style += v+':'+cement[v]+";"
                        }
                    }                 
                }

                let cols = sub.cement.width || 12;
                let classes = "";

                classes = "w"+cols;

                if(cement && cement.hidden) {
                    classes += " hidden";
                }

                let drag_info = {
                    source_id: this.brick_id,                                
                    tags: this.blueprint.ins.cfg.drag_tags
                };

                let d = { name: sub.name, type: sub.type, position: c };

                this.drop_contained.push(d);

                innerHTML += `<div class="cc-layout-grid-cell ${classes}" id="${this.brick_id}$${cement_idx}" cd-data="${CBWebUtil.escapeHtml(JSON.stringify(d))}" drag-info="${CBWebUtil.escapeHtml(JSON.stringify(drag_info))}"><div style="${lop_style}" class="cc-layout-grid-item ${lop_classes}" >${brick}</div></div>`;

            }
            else {
                innerHTML += "<div style='display:none'>"+brick+"</div>";
            }

            if((cement.row || 0) !== at_row) {
                at_row = cement.row || 0;
            }
        }

        innerHTML += `</div>`;

        this.element.innerHTML = innerHTML;
                    
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {

        let child = document.getElementById(this.brick_id+"$"+child_idx);

        if(child) {
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
                    
            //If you do child classes, do it on the child of child, the id is on the outer container
            let item = child.firstElementChild;
            if(item) {
                if(cement.style_classes) {
                    for(let sc of cement.style_classes) {
                        item.classList.add(sc);
                    }
                }
            }
        }


        
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}




    enterEditMode() {

        this.edit_mode = true;

        let blocks = this.element.querySelectorAll(".cc-layout-grid-cell");

        let position = 0;
        let self = this;

        blocks.forEach((draggable) => {

            if(!draggable.id.startsWith(this.brick_id + "$")) {
                return;
            }

            //console.log("enterEditMode "+position);

            if(draggable.classList.contains("dragndrop-element-draggable")) {
                //console.log("enterEditMode "+position+" already");
                return;
            }
            
            draggable.setAttribute('draggable', true);
            draggable.classList.add("dragndrop-element-draggable");

            let controls_html = `<div class="dragndrop-element-controls" position="${position}">
                <div class="dragndrop-element-controls-delete" onclick=""></div>
            </div>`;

            draggable.insertAdjacentHTML("beforeend", controls_html);

            let drag_handle = `<div class="drag-handle"></div>`;
            draggable.insertAdjacentHTML("beforeend", drag_handle);

            let pos = position;

            let del_btn = this.element.querySelector('.dragndrop-element-controls[position="'+position+'"] > .dragndrop-element-controls-delete');
            if(del_btn) {
                del_btn.addEventListener("click", function() {
                    self.deleteBlock(pos);
                })
            }

            draggable.addEventListener("mousedown", (e: any) => {
                self.drag_target = e.target;
            });

            draggable.addEventListener("dragstart", (e: any) => {
                if(!self.drag_target.classList.contains("drag-handle")) {
                    e.preventDefault();
                    return;
                }

                e.dataTransfer.setData("text", e.target.id);

                (<any>window).drag_info = {
                    source_id: self.brick_id,                                 
                    tags: self.cfg.drag_tags
                };

                draggable.classList.add("dragging");
            });
            draggable.addEventListener("dragend", () => {
                draggable.classList.remove("dragging");
                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });

            draggable.addEventListener("dragenter", () => {
                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
                draggable.classList.add("dropzone");
            });

            // draggable.addEventListener("dragleave", () => {
            //     //draggable.classList.remove("dropzone");
            // });

            draggable.addEventListener("dragover", (e: any) => {
                if(!self.may_drop()) {
                    return;
                }
                e.preventDefault();
                e.stopPropagation();
            });

            position++;
        });

        let container = this.element.querySelector(".cc-layout-grid-container");

        if(container && container.getAttribute("has_drag_handlers") == null) {

            container.setAttribute("has_drag_handlers", "true");

            container.classList.add("cc-layout-grid-container-editmode");

            container.addEventListener("dragover", (e: any) => {
                if(!self.may_drop()) {
                    return;
                }
                e.preventDefault();

                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });

            container.addEventListener("drop", (e: any) => {
                e.preventDefault();
                e.stopPropagation();

                var data = e.dataTransfer.getData("text");
                let block = document.getElementById(data);
                if(container && block) {
                    let drag_info = JSON.parse(CBWebUtil.unescapeHtml(block.getAttribute("drag-info") || ""));
                    let cd_data = JSON.parse(CBWebUtil.unescapeHtml(block.getAttribute("cd-data") || ""));

                    console.log("drop onto "+data+" drag_info "+JSON.stringify(drag_info) + " cd_data "+JSON.stringify(cd_data));

                    if(drag_info.source_id == self.brick_id) {

                        block.classList.remove("dragging");

                        let dropzone = self.element.querySelector(".dropzone");
                        if(dropzone) {

                            let dest_id = dropzone.id;
                            let src_id = data;
                                    
                            let dest_idx = Number(SplitGetLast(dest_id, "$"));
                            let src_idx = Number(SplitGetLast(src_id, "$"));

                            console.log("move from idx "+src_idx+" to "+dest_idx);

                            array_move(self.drop_contained, src_idx, dest_idx);
                            
                            // if(dest_id < src_id) {
                            //     container.insertBefore(block, dropzone);
                            // }
                            // else {
                            //     self.insertAfter(dropzone, block);
                            // }

                            // dropzone.id = src_id;
                            // block.id = dest_id;

                            self.saveLayout();
                        }
                        else {
                            container.appendChild(block);
                        }
                    }
                    else {
                        console.log("drop external data "+data);

                        let dropzone = self.element.querySelector(".dropzone");
                        if(dropzone) {
                            let dest_id = dropzone.id;
                            let dest_idx = Number(SplitGetLast(dest_id, "$"));

                            self.drop_contained.splice(dest_idx, 0, cd_data);
                        }
                        else {
                            self.drop_contained.push(cd_data);
                        }

                        // let container = document.getElementById(self.brick_id);
                        // if(container) {
                        //     self.renderData(self.data, container);
                        // }

                        self.saveLayout();
                    }
                }

                let dropzone = self.element.querySelector(".dropzone");
                if(dropzone) {
                    dropzone.classList.remove("dropzone");
                }
            });
            
        }
    }

    may_drop() {
        let drag_info = (<any>window).drag_info;
        if(drag_info) {

            if(drag_info.source_id == this.brick_id) {
                if(this.cfg.disallow_move) {
                    return;
                }
            }
            else {
                try {
                    let intersection = this.cfg.drop_tags.filter(function(n:any) {
                        return drag_info.tags.indexOf(n) !== -1;
                    });
                    if(!intersection || intersection.length == 0) {
                        return false;
                    }
                }
                catch(err) {
                    console.error(err);
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    deleteBlock(position: number) {
        let controls = this.element.querySelector('.dragndrop-element-controls[position="'+position+'"]');
        if(controls && controls.parentElement) {
            // //controls.parentElement.remove();
            // this.data.splice(position, 1);
            this.drop_contained.splice(position, 1);

            // let container = document.getElementById(this.brick_id);
            // if(container) {
            //     this.renderData(this.data, container);
            // }

            this.saveLayout();
        }
    }

    saveLayout() {
        // for(let d = 0; d < this.data.length; d++) {
        //     this.data[d]["Position"] = d;
        // }

        console.log("cc-layout-grid emit "+JSON.stringify(this.drop_contained));

        this.cb_emit({ "@contained" : this.drop_contained });
    }
}


