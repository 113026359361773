import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_expander_webcomponent extends HTMLElement {
    ci: web_cc_expander | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_expander(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-expander', cc_expander_webcomponent);

export class web_cc_expander extends CodeBrick {

    element: HTMLElement;
    handler: any;
    initialized = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        this.blueprint.ins = this.blueprint.ins || {};   
        this.blueprint.ins.cfg = this.blueprint.ins.cfg || {};  

        let cfg = this.blueprint.ins.cfg;
        

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        if(input == "cfg") {
            let et = document.getElementById(this.brick_id+"$et");

            if(!this.initialized) {
        
                if(!this.handler && cfg.accordion_group) {
                    this.handler = true;
                    let id = this.brick_id + "_chck1";
                    let btn = document.getElementById(id);
                    let accordion_group = cfg.accordion_group;
                    
                    if(btn) {
                        btn.classList.add(cfg.accordion_group);

                        btn.addEventListener('click', function() {
        
                            let group = document.querySelectorAll("." + accordion_group);
                            for (var i = 0; i < group.length; i++) {
                                if(group[i].id != id) {
                                (<any>group[i]).checked = false;
                                }
                            }
                        });
                    }
                }

                if(et) {
                    et.classList.remove("hidden");
                }

                this.initialized = true;
            }

            if(et) {
                // if(cfg.style_classes) {
                //     for(let cl in cfg.style_classes) {
                //         et.classList.add(cfg.style_classes[cl]);
                //     }
                // }     
                
                // if(cfg.style) {
                //     for(let v in cfg.style) {
                //         et.style.setProperty(v, cfg.style[v]);
                //     }
                // }
                CBWebUtil.ApplyElementStyles(et, cfg, "expander");
                CBWebUtil.ApplyElementStyles(et.lastElementChild as HTMLElement, cfg, "content");
            }

            let label = document.getElementById(this.brick_id+"$label");
            if(label) {
                //console.log("cc-expander~"+this.blueprint.name+" cfg "+JSON.stringify(cfg));
                label.innerHTML = cfg.label;
                CBWebUtil.ApplyElementStyles(label, cfg, "label");
            }

            let icon = document.getElementById(this.brick_id+"$icon");
            let img = document.getElementById(this.brick_id+"$img") as HTMLImageElement;
            if(icon && img) {
                if(cfg.icon) {
                    icon.classList.remove("hidden");
                }
                else {
                    icon.classList.remove("hidden");
                }
                img.src = cfg.icon;
            }

            let expanded = cfg.expanded == true;
            this.expand(expanded);
        
        }

    }

    pop_hide() {

        //console.log("pop_hide"+this.ci_idx);

        let menu = document.getElementById("cc-expander-expander"+this.ci_idx);
        menu?.classList.remove("cc-expander-show");
    }

    handleClick = (e:any) => {
        let showing = false;
        let menu = document.getElementById("cc-expander-expander"+this.ci_idx);
        if (menu?.classList.contains("cc-expander-show")) {
            showing = true;
        }
        this.expand(!showing);
    };

    expand(expand: boolean) {
        let checkbox = document.getElementById(this.brick_id+"_chck1") as HTMLInputElement;
        if(checkbox) {
            checkbox.checked = expand;
        }
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {

        let subs = '';
        if(this.blueprint.contains) {
            let i = 0;
            for(let sub of this.blueprint.contains) {

                let brick = CBWebUtil.BrickHtml(sub, this, i);
                
                let prefix = sub.type.split('-')[0];
                if(prefix.indexOf('i') == -1) {

                    let lop_style = "";
                    let lop_classes = "";
                    let cement = cements[i];
                    if(cement) {
                        for(let v in cement) {
                            if(v == "style_classes") {
                                if(sub.cement[v]) {
                                    lop_classes = cement[v].join(' ');
                                }
                            }
                            else {
                                lop_style += v+':'+cement[v]+";"
                            }
                        }
                        
                    }
                    subs += `<div style="${lop_style}" class="${lop_classes}">${brick}</div>`;
                }
                else {
                    subs += brick;
                }
                i++;
            }
        }

        let disp = `<span id="${this.brick_id}$label"></span>`;

        //disp = `<div id="${this.brick_id}$icon" style="display: flex; justify-content: flex-start; align-items: center;" class="hidden"><img id="${this.brick_id}$img" class="cc-expander-icon" src=""/><span id="${this.brick_id}$label"></span></div>`
        
        let innerHTML =`
        <div id="${this.brick_id}$et" class="cc-expander hidden" style="" id="${this.brick_id}">
            <input type="checkbox" class="cc-expander-input" id="${this.brick_id}_chck1">
            <label class="cc-expander-label" for="${this.brick_id}_chck1">${disp}</label>
            <div class="cc-expander-content">
            ${subs}
        </div>`;
        this.element.innerHTML = innerHTML;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}


