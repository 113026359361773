import { DrillSet, ObjectDeepMerge } from "../codebricks-runtime/CBUtil";
import { PivotTable } from "./PivotTable";

export class Data {
    static ProcessDataInput(input: string, cfg: any, self: any) {
        if(input == "data") {
            if(cfg.ignore_unchanged || cfg.ingore_unchanged) {//fixed typo in spec
                if(self.prev_data == JSON.stringify(cfg.value)) {
                    return;
                }
            } 
            self.data = cfg.value;

            //group
            if(cfg.group_by && cfg.group_by.group_cols && cfg.group_by.group_cols.length > 0) {
                self.data = PivotTable.groupData(self.data, cfg.group_by.group_cols, cfg.group_by.count_cols, cfg.group_by.sum_cols);
            }

            //console.log('Grouped data: '+JSON.stringify(data));

            if(cfg.pivot && cfg.pivot.rows && cfg.pivot.rows.length > 0 &&
                cfg.pivot.columns && cfg.pivot.columns.length > 0 &&
                cfg.pivot.data_columns && cfg.pivot.data_columns.length > 0 ) {
    
                self.data = PivotTable.pivotData(self.data, cfg.pivot.rows[0], cfg.pivot.columns[0], cfg.pivot.data_columns, false);
            }

            let dont_emit = false;
            if(cfg.ingore_initial) {
                if(self.prev_data === "") {
                    dont_emit = true;
                }
            }
            self.prev_data = JSON.stringify(self.data);
            if(!dont_emit) {

                if(cfg.no_event) {
                    self.cb_emit({ "@" : self.data }, undefined, true);
                }
                else {
                    return { "@" : self.data };
                }
            }
        }
        else if(input == "merge") {
            if(cfg.value !== "") {
                ObjectDeepMerge(self.data || {}, cfg.value);

                return { "@" : self.data  };
            }
        }
        else if(input == "set_property") {
            if(self.data && cfg.value !== "" && cfg.path !== undefined) {
                self.data = DrillSet(self.data, cfg.path, cfg.value);
                return { "@" : self.data  };
            }
        }
        else if(input == "push") {
            if(cfg.value !== "") {
                self.data = self.data || [];
                if(Array.isArray(self.data)) {
                    self.data.push(cfg.value);
                    return { "@" : self.data  };
                }         
            }
        }
    }
}