import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_datetimepicker } from "../controls/cb_datetimepicker";
import { cb_popout } from "../controls/cb_popout";
import Sugar from "sugar";

export class c_datetime_webcomponent extends HTMLElement {
    ci: web_c_datetime | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_datetime(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-datetime', c_datetime_webcomponent);

export class web_c_datetime extends CodeBrick {

    element: HTMLElement;
    initialised = false;

    dateformat = "{short}"
    has_value = false;
    date = null as any;

    //--
    popout: cb_popout;

    datetimepicker: cb_datetimepicker | undefined;
    cfg = {} as any;

    snapshot = undefined as any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        let self = this;
        this.element.innerHTML = `<div id="${this.brick_id}" class='c-datetime-container'></div>`;
        this.popout = new cb_popout(this.brick_id, true, this.blueprint.ins.cfg, "c-datepicker", false, false,
        function() {
            //pop opened
            if(!self.cfg.value) {
                self.datetimepicker?.set_value(Sugar.Date.create());
                self.datetimepicker?.value_changed_callback(self.datetimepicker?.value_date);
            }
        },
        function() {

            let emit_val = 

                Sugar.Date.format(
                    self.datetimepicker?.value_date, self.cfg.outputformat || self.cfg.displayformat || "{short}");

            self.snapshot = self.snapshot || {};
            self.snapshot.cfg = self.snapshot.cfg || {};
            self.snapshot.cfg.value = emit_val;

            self.cfg.value = emit_val; //For the sake of stopping running_clock

            self.cb_emit({"@": emit_val });
        });

        let drop = document.getElementById(this.brick_id+"$drop");
        if(drop) {
            drop.innerHTML = `<div class="c-datetime-drop"><div id="${this.brick_id}$picker"></div><div class="c-datetime-presets-container" id="${this.brick_id}$presets"></div></div>`;
        }
     
        this.popout.init();

    }

    running_clock() {
        if(!this.cfg.value && this.cfg.running_clock) {
            this.datetimepicker?.set_value(Sugar.Date.create());
            this.datetimepicker?.value_changed_callback(this.datetimepicker?.value_date);
            let emit_val = 
                Sugar.Date.format(
                    this.datetimepicker?.value_date, this.cfg.outputformat || this.cfg.displayformat || "{short}");

            this.cb_emit({"@": emit_val });

            let self = this;
            setTimeout(function() {
                self.running_clock();  
            }, 60000);
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        this.cfg = cfg;

        let self = this;

        let current_year = Number(Sugar.Date.format(Sugar.Date.create(), "{yyyy}"));

        if(!this.datetimepicker) {
            this.datetimepicker = new cb_datetimepicker(this.brick_id+"$picker", 
                !cfg.hide_year,
                !cfg.hide_month,
                !cfg.hide_day,
                cfg.show_hour,
                cfg.show_minute,
                current_year - 120,
                current_year + 50,
                function (value_date: any) {
                    let value_str = Sugar.Date.format(
                        value_date, self.cfg.outputformat || self.cfg.displayformat || "{short}"
                    );
                    self.popout.setDisplayValue(value_str);
                }
            );   

            
        }

        this.datetimepicker.init(this.cfg.value || Sugar.Date.create() + "");
        let value_str = Sugar.Date.format(
            this.datetimepicker.value_date, self.cfg.outputformat || self.cfg.displayformat || "{short}"
        );
        this.popout.setDisplayValue(value_str);

        this.popout.setLabel(cfg.label);
        this.datetimepicker.setShowHour(cfg.show_hour);
        this.datetimepicker.setShowMinute(cfg.show_minute);

        if(this.cfg.presets && this.cfg.presets.length > 0) {
            let presets_html = `<div class="c-datetime-presets-heading">Presets</div>`;
            if(cfg.presets) {
                for(let p = 0; p < cfg.presets.length; p++) {
                    presets_html += `<button class="c-datetime-preset" id="${this.brick_id}$preset${p}">${cfg.presets[p].label}</button>`;
                }
            }
            let presets_element = document.getElementById(this.brick_id+"$presets");
            if(presets_element) {
                presets_element.innerHTML = presets_html;
            }

            if(cfg.presets) {
                let self = this;
                for(let p = 0; p < cfg.presets.length; p++) {
                    let preset_button = document.getElementById(this.brick_id+"$preset"+p);
                    if(preset_button) {
                        preset_button.addEventListener("click", function(e:any) {
                            let p = Number(e.target.id.split("$preset")[1]);
                            let preset = self.cfg.presets[p];
                            self.date =
                                
                                Sugar.
                                Date.create(
                                    preset.datetime, self.cfg.dateformat);

                            if(self.datetimepicker) {
                                self.datetimepicker.set_value(self.date);
                            }

                            self.snapshot = self.snapshot || {};
                            self.snapshot.cfg = self.snapshot.cfg || {};
                            self.snapshot.cfg.value = preset.datetime;
                            
                        });
                    }
                }
            }
        }
        else {
            let presets_element = document.getElementById(this.brick_id+"$presets");
            if(presets_element) {
                presets_element.innerHTML = "";
            }
        }

        if(!self.cfg.value && self.cfg.running_clock) {
            setTimeout(function() {
                self.running_clock();
            }, 60000);
        }

        return { '@' : value_str };

    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        if(status == "required") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.add("required");
            }
        }
        else if(status == "") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.remove("required");
            }
        }
    }
    cb_snapshot() {
        return this.snapshot;
    }
}
