import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { ValueFromSchema } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { Unit } from "../../shared-funcs/Unit";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_unit_webcomponent extends HTMLElement {
    ci: web_cc_unit | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_unit(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-unit', cc_unit_webcomponent);

export class web_cc_unit extends CodeBrick {

    element: HTMLElement;
    args = undefined as any;;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        if(input == "args") {
            this.args = cfg;
        }
        //console.log("cc-unit "+this.brick_id+" input "+input+" cfg "+JSON.stringify(cfg));

        if(input == "unit_outs") {
            //for(let out of cfg) { for some reason this does not pick up the values
            let keys = Object.keys(cfg);
            for(let key of keys) {
                let output = '';
                if(key != "default") {
                    output = key;
                }
                //this.context.composition_runners[this.cid].cn_out_data["@"+output] = cfg[key].value;
                let values = ValueFromSchema(cfg);
                this.context.composition_runners[this.cid].set_cn_out_data(output, values[key]); //cfg[key].value);
            }
        }
        
        let ret = Unit.PassThrough(input, cfg);
        
        //console.log("cc-unit "+this.brick_id+" return "+JSON.stringify(ret));

        return ret;
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {

        //console.log("cc-unit "+this.brick_id+" cb_initial_cement "+JSON.stringify(cements));

        let innerHTML =  `<div id="${this.brick_id}">`;
        if(this.blueprint.contains) {
            let  i = 0;
            for(let sub of this.blueprint.contains) {
                let lop_classes = "";
                let cement = cements[i];
                if(cement) {
                    for(let v in cement) {
                        if(v == "style_classes") {
                            if(cement[v]) {
                                lop_classes = cement[v].join(' ');
                            }
                        }        
                    }
                    if(cement.hidden) {
                        lop_classes += lop_classes ? " hidden" : "hidden";
                    }
                }
                let brick = CBWebUtil.BrickHtml(sub, this, i);
                if(lop_classes) {
                    innerHTML += `<div class="${lop_classes}" id="${this.brick_id+"$"+i}">${brick}</div>`;
                }
                else {
                    innerHTML += brick;
                }

                i++;
            }
        }
        this.element.innerHTML = innerHTML + `</div>`;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
        let child = document.getElementById(this.brick_id+"$"+child_idx);
        if(child) {
            if(cement && cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
        }
        else {
            let child = this.element.querySelector(`[container_id="${this.brick_id}"][idx="${child_idx}"]`);
            if(child) {
                if(cement && cement.hidden) {
                    child.classList.add("hidden");
                }
                else {
                    child.classList.remove("hidden");
                }
            }
        }
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {
        if(this.args) {
            if(this.args.snapshot_id) {
                delete this.args.snapshot_id;
            }
            return {
                args: this.args
            }
        }
    }

}