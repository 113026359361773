import Sugar from "sugar";

export class cb_datetimepicker {

    value_changed_callback: (value:string) => void;

    container_id: string;
    show_year: boolean; show_month: boolean; show_day: boolean; show_hour: boolean; show_minute: boolean;

    start_year: number;
    end_year: number;

    value_date: any

    constructor(container_id: string, show_year: boolean, show_month: boolean, show_day: boolean, show_hour: boolean, show_minute: boolean, start_year: number, end_year: number, value_changed_callback: (value_date:any) => void) {
        this.container_id = container_id;
        
        this.value_changed_callback = value_changed_callback;

        this.show_year = show_year;
        this.show_month = show_month;
        this.show_day = show_day;
        this.show_hour = show_hour;
        this.show_minute = show_minute;

        this.start_year = start_year;
        this.end_year = end_year;

    }

    init(value: string) {

        this.value_date = value ? Sugar.Date.create(value) : Sugar.Date.create();

        let container = document.getElementById(this.container_id);
        if(container) {

            
            let year = Number(Sugar.Date.format(this.value_date, "{yyyy}"));

            let years = "";
            for(let y = this.end_year; y >= this.start_year; y--) {
                years += `<option value="${y}" ${y == year ? "selected":""}>${y}</option>`;
            }

            let selected_hour = Sugar.Date.format(this.value_date, "{HH}");
            let hours = "";
            for(let h = 0; h <= 23; h++) {
                let hour = Sugar.String.padLeft(String(h), 2, "0");
                hours += `<option value="${hour}" ${hour == selected_hour ? "selected":""}>${hour}</option>`;
            }

            let selected_minute = Sugar.Date.format(this.value_date, "{mm}");
            let minutes = "";
            for(let m = 0; m <= 59; m++) {
                let minute = Sugar.String.padLeft(String(m), 2, "0");
                minutes += `<option value="${minute}" ${minute == selected_minute ? "selected":""}>${minute}</option>`;
            }

            let month = Number(Sugar.Date.format(this.value_date, "{M}"));

            let html = `<div class="cb_dtp">
                <div class="cb_dtp_yearmonth">
                    <button class="cb_dtp_btn cb_dtp_year_down" id="${this.container_id}$year_dn"><span></span></button>
                    <button class="cb_dtp_btn cb_dtp_month_down" id="${this.container_id}$month_dn"><span></span></button>
                    <select id="${this.container_id}$year">${years}</select>
                    <select id="${this.container_id}$month">
                        <option value="01" ${month == 1 ? "selected":""}>Jan</option>
                        <option value="02" ${month == 2 ? "selected":""}>Feb</option>
                        <option value="03" ${month == 3 ? "selected":""}>Mar</option>
                        <option value="04" ${month == 4 ? "selected":""}>Apr</option>
                        <option value="05" ${month == 5 ? "selected":""}>May</option>
                        <option value="06" ${month == 6 ? "selected":""}>Jun</option>
                        <option value="07" ${month == 7 ? "selected":""}>Jul</option>
                        <option value="08" ${month == 8 ? "selected":""}>Aug</option>
                        <option value="09" ${month == 9 ? "selected":""}>Sep</option>
                        <option value="10" ${month == 10 ? "selected":""}>Oct</option>
                        <option value="11" ${month == 11 ? "selected":""}>Nov</option>
                        <option value="12" ${month == 12 ? "selected":""}>Dec</option>
                    </select>
                    <button class="cb_dtp_btn cb_dtp_month_up" id="${this.container_id}$month_up"><span></span></button>
                    <button class="cb_dtp_btn cb_dtp_year_up" id="${this.container_id}$year_up"><span></span></button>
                </div>
                <div class="cb_dtp_days_container" id="${this.container_id}$days_container">
                    
                </div>
                <div class="cb_dtp_time_container${this.show_hour || this.show_minute ? "" : " hidden"}" id="${this.container_id}$time_container">
                    <div class="cbp_time_icon"></div><select id="${this.container_id}$hour" class="${this.show_hour ? "" : "hidden"}">${hours}</select>${this.show_hour && this.show_minute ? " : " : ""}<select id="${this.container_id}$minute" class="${this.show_minute ? "" : "hidden"}">${minutes}</select>
                </div>
            </div>`;

            container.innerHTML = html;

            this.refresh_date();

            let self = this;

            let year_select = document.getElementById(`${this.container_id}$year`);
            if(year_select) {
                year_select.addEventListener("change", function(e: any) {
                    self.year_selected(e.target.value);
                });
            }

            let month_select = document.getElementById(`${this.container_id}$month`);
            if(month_select) {
                month_select.addEventListener("change", function(e: any) {
                    self.month_selected(e.target.value);
                });
            }

            let year_dn = document.getElementById(`${this.container_id}$year_dn`);
            if(year_dn) {
                year_dn.addEventListener("click", function() {
                    let year = Number(Sugar.Date.format(self.value_date, "{yyyy}"));
                    year--;
                    self.year_selected(year);
                });
            }

            let year_up = document.getElementById(`${this.container_id}$year_up`);
            if(year_up) {
                year_up.addEventListener("click", function() {
                    let year = Number(Sugar.Date.format(self.value_date, "{yyyy}"));
                    year++;
                    self.year_selected(year);
                });
            }

            let month_dn = document.getElementById(`${this.container_id}$month_dn`);
            if(month_dn) {
                month_dn.addEventListener("click", function() {
                    let month = Number(Sugar.Date.format(self.value_date, "{M}"));
                    month--;
                    self.month_selected(month);
                });
            }

            let month_up = document.getElementById(`${this.container_id}$month_up`);
            if(month_up) {
                month_up.addEventListener("click", function() {
                    let month = Number(Sugar.Date.format(self.value_date, "{M}"));
                    month++;
                    self.month_selected(month);
                });
            }

            let hour_select = document.getElementById(`${this.container_id}$hour`);
            if(hour_select) {
                hour_select.addEventListener("change", function(e: any) {
                    Sugar.Date.set(self.value_date, { hour: e.target.value });
                    self.value_changed_callback(self.value_date);
                });
            }

            let minute_select = document.getElementById(`${this.container_id}$minute`);
            if(minute_select) {
                minute_select.addEventListener("change", function(e: any) {
                    Sugar.Date.set(self.value_date, { minute: e.target.value });
                    self.value_changed_callback(self.value_date);
                });
            }
        }
    }

    refresh_date() {

        //Redraw days

        let days = `<table class="cb_dtp_days">
        <tr>
            <th>Su</th>
            <th>Mo</th>
            <th>Tu</th>
            <th>We</th>
            <th>Th</th>
            <th>Fr</th>
            <th>Sa</th>
        </tr>`;
    
        let start_of_month = Sugar.Date.create(Sugar.Date.format(this.value_date, "{yyyy}-{MM}") +"-01");
        
        let start_dow = Sugar.Date.getWeekday(start_of_month);
        
        let days_in_month = Sugar.Date.daysInMonth(start_of_month);

        let startcol = Number(start_dow);

        let rem = 0;

        let day_num = Number(Sugar.Date.format(this.value_date, "{d}"));

        for(let i = 0; i < days_in_month + startcol; i++) {
            if(i % 7 == 0) {
                if(i == 0) {
                    days += "<tr>";
                }
                else {
                    days += "</tr><tr>";
                }
            }
            if(i < startcol) {
                days += "<td></td>";
            }
            else {
                let date = i - start_dow + 1

                let day_str = Sugar.Number.pad(date, 2);

                let sel = "";
                if(date == day_num) {
                    sel = " cb_dtp_day_selected";
                }

                days += `<td><div class="cb_dtp_day${sel}" id="${this.container_id}$${date}">${day_str}</div></td>`;
            }
            rem = 7 - i % 7 - 1;
        }

        for(let i = 0; i < rem; i++) {
            days += "<td></td>";
        }
        days += "</tr></table>";

        let days_container = document.getElementById(`${this.container_id}$days_container`);
        if(days_container) {
            days_container.innerHTML = days;
        }

        let self = this;

        for(let i = 0; i < days_in_month; i++) {
            let day_div = document.getElementById(`${this.container_id}$${i + 1}`);
            if(day_div) {
                day_div.addEventListener("click", function(e: any) {
                    let clicked_item_id = e.target.id;
                    let s = clicked_item_id.split("$");
                    let day = Number(s[s.length-1]);

                    self.day_selected(day);
                });
            }
        }

        //Update years
        let year =Sugar.Date.format(this.value_date, "{yyyy}");

        let year_select = document.getElementById(`${this.container_id}$year`) as HTMLSelectElement;
        if(year_select) {
            year_select.value = year;
        }

        //Update months
        let month = Sugar.Date.format(this.value_date, "{MM}");

        let month_select = document.getElementById(`${this.container_id}$month`) as HTMLSelectElement;
        if(month_select) {
            month_select.value = month;
        }

        let selected_hour = Sugar.Date.format(this.value_date, "{HH}");
        let hour_select = document.getElementById(`${this.container_id}$hour`) as HTMLSelectElement;
        if(hour_select) {
            hour_select.value = selected_hour;
        }

        let selected_minute = Sugar.Date.format(this.value_date, "{mm}");
        let minute_select = document.getElementById(`${this.container_id}$minute`) as HTMLSelectElement;
        if(minute_select) {
            minute_select.value = selected_minute;
        }

        self.value_changed_callback(self.value_date);
    }

    set_value(value_date: any) {
        this.value_date = value_date;
        this.refresh_date();
    }

    year_selected(year: number) {
        Sugar.Date.set(this.value_date, { year: year });

        this.value_changed_callback(this.value_date);
        this.refresh_date();
    }

    month_selected(month: number) {
        Sugar.Date.set(this.value_date, { month: month - 1 });
        this.value_changed_callback(this.value_date);
        this.refresh_date();
    }

    day_selected(day: number) {

        //console.log("day_selected "+this.container_id + " day "+day);

        let prev_date = Number(Sugar.Date.format(this.value_date, "{d}"));
        let prev = document.getElementById(`${this.container_id}$${prev_date}`);
        if(prev) {

            //console.log(" remove class "+this.container_id + " on prev_date "+prev_date+ " has class "+prev.classList.contains("cb_dtp_day_selected"));

            prev.classList.remove("cb_dtp_day_selected");
        }

        let new_day = document.getElementById(`${this.container_id}$${day}`);
        if(new_day) {
            new_day.classList.add("cb_dtp_day_selected");
        }

        Sugar.Date.set(this.value_date, { day: day });
        this.value_changed_callback(this.value_date);
    }

    hour_selected(hour: number) {
        Sugar.Date.set(this.value_date, { hour: hour });
        this.value_changed_callback(this.value_date);
        this.refresh_date();
    }

    minute_selected(minute: number) {
        Sugar.Date.set(this.value_date, { minute: minute });
        this.value_changed_callback(this.value_date);
        this.refresh_date();
    }

    setShowHour(show_hour: boolean) {
        this.show_hour = show_hour;
        let time_container = document.getElementById(`${this.container_id}$time_container`);
        if(time_container) {
            time_container.classList.toggle("hidden", !(this.show_hour || this.show_minute));
        }
        let hour_select = document.getElementById(`${this.container_id}$hour`);
        if(hour_select) {
            hour_select.classList.toggle("hidden", !this.show_hour);
        }
    }
    setShowMinute(show_minute: boolean) {
        this.show_minute = show_minute;
        let time_container = document.getElementById(`${this.container_id}$time_container`);
        if(time_container) {
            time_container.classList.toggle("hidden", !(this.show_hour || this.show_minute));
        }
        let minute_select = document.getElementById(`${this.container_id}$minute`);
        if(minute_select) {
            minute_select.classList.toggle("hidden", !this.show_minute);
        }
    }
}
}
