import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_button_webcomponent extends HTMLElement {
    ci: web_c_button | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_button(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-button', c_button_webcomponent);

export class web_c_button extends CodeBrick {

    outdata = {} as any;
    element: HTMLElement;
    style_classes = [] as string[];
    initialised = false;
    no_loader = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log(this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));
        
        if(input == "data") {
            this.outdata = cfg;
        }
        else if(input == "cfg") {
            this.setDisplay(cfg);
        }
    }

    setDisplay(cfg: any) {


        if(!this.initialised) {

            let tooltip = "";
            if(cfg.tooltip && cfg.tooltip.text) {
                tooltip = ` tooltip="${cfg.tooltip.text}"`;
            }

            this.element.innerHTML = `<button id="${this.brick_id}" class="c-button" label="${cfg.label || ''}"${tooltip}></button>`;//<div id="${this.brick_id}_loader" class="loader"></div>`;

            let self = this;
            let btn = document.getElementById(`${this.brick_id}`);
            if(btn) {
                btn.addEventListener('click', async function(e: any) {

                    if((<any>window).cb_popout_global && (<any>window).cb_popout_global.window_click_handler) {
                        //Close any open selects so they can send their events before processing this button's event, in case the selection is used in this event.
                        (<any>window).cb_popout_global.window_click_handler(e);
                    }

                    //console.log(self.blueprint.name+' emit '+JSON.stringify(self.outdata));
                    if(self.validate_sources("")) {
                        await self.cb_emit({"@":self.outdata});
                    }
                });

                if(cfg.tooltip && cfg.tooltip.text) {
                    cb_tooltip.set_tooltip(btn);
                }
            }
            this.initialised = true;

            
        }


        let btn = document.getElementById(`${this.brick_id}`) as HTMLButtonElement;
        if(btn) {
            if(cfg.style) {
                for(let v in cfg.style) {
                    //(btn.style as any)[v] = cfg.style[v];
                    btn.style.setProperty(v, cfg.style[v]);
                }
            }

            if(cfg.tooltip && cfg.tooltip.text) {
                btn.setAttribute("tooltip", cfg.tooltip.text);
                cb_tooltip.set_tooltip(btn);
            }

            // btn.className = 'c-button'+tooltip_class;
            // if(cfg.style_classes) {
            //     for(let cl in cfg.style_classes) {
            //         if(cfg.style_classes[cl]) {
            //             btn.classList.add(cfg.style_classes[cl]);
            //         }
            //     }
            // }
            CBWebUtil.ApplyElementStyles(btn, cfg, "button");

            if(cfg.nativeoptions && cfg.nativeoptions.html) {
                if(cfg.nativeoptions.html.attributes) {
                    for(let attribute in cfg.nativeoptions.html.attributes) {
                        btn.setAttribute(attribute, cfg.nativeoptions.html.attributes[attribute]);
                    }
                }
            }

            if(cfg.hidden) {
                btn.classList.add("hidden");
            }
            else {
                btn.classList.remove("hidden");
            }


            if(cfg.disabled) {
                btn.disabled = true;
            }
            else {
                btn.disabled = false;
            }

            if(cfg.no_loader) {
                this.no_loader = true;
            }
            else {
                this.no_loader = false;
            }

            let icon_left = '';
            if(cfg.icon_left) {
                if(cfg.icon_left[0] == "<") {
                    icon_left = cfg.icon_left;
                }
                else {
                    icon_left = `<img class="c-button-icon-left" src="${cfg.icon_left}"/> `;
                }
            }
            let icon_center = '';
            if(cfg.icon_center) {
                if(cfg.icon_center[0] == "<") {
                    icon_center = cfg.icon_center;
                }
                else {
                    icon_center = `<img class="c-button-icon-center" src="${cfg.icon_center}"/> `;
                }
            }
            let icon_right = '';
            if(cfg.icon_right) {
                if(cfg.icon_right[0] == "<") {
                    icon_right = cfg.icon_right;
                }
                else {
                    icon_right = ` <img class="c-button-icon-right" src="${cfg.icon_right}"/>`;
                }
            }

 
        
            btn.innerHTML = `${icon_left}${icon_center}${cfg.label || ''}${icon_right}`;

        }

        

    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }

    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }

    async cb_status(status: string): Promise<void> {

        //console.log("c-button "+this.blueprint.name+" cb_status "+status);

        let button = document.getElementById(this.brick_id);
        let loader = document.getElementById(this.brick_id+"_loader");
        if(status == "loading" && !this.no_loader) {
            if(button) {
                button.classList.add("loading");
            }
        }
        else {
            if(button) {
                button.classList.remove("loading");
            }
        }

        if(status == "required") {
            this.element.style.border = '1px solid red';
        }
    }

    cb_trigger_submit() {
        //this.cb_emit({"@":this.outdata});
        let btn = document.getElementById(`${this.brick_id}`);
        if(btn) {
            btn.click();
        }
    }

    cb_snapshot() {}
   
}