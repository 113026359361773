import {styleTags, tags} from "@lezer/highlight"
import {HighlightStyle} from "@codemirror/language"
//import {parser as cbtemplateParser} from "./cbtemplate-parser";
//import {syntaxHighlighting} from "@codemirror/language"

export const cbtemplateHighlighting = styleTags({
  "{{ }}": tags.brace,
  ". = > < <= >= ? ; : & | ( ) ,": tags.operator,
	Brick: tags.moduleKeyword,
  Output: tags.modifier,
  Prop: tags.namespace,
  String: tags.string,
  Number: tags.number,
  Boolean: tags.bool,
	Null: tags.null,
  Eq: tags.operator,
  And: tags.operator,
  Or: tags.operator,
  FormatType: tags.annotation,
  FormatFunc: tags.updateOperator,
  Formatparams: tags.changed
});

export const cbtemplateHighlightStyle = HighlightStyle.define([
  {tag: tags.brace, color: "#61bdff", fontWeight: "bold" },//, backgroundColor: "#f0f0ff"},
  //{tag: tags.operator, color: "#00419b", fontWeight: "bold" },
  {tag: tags.operator, color: "#000000", fontWeight: "bold" },
  {tag: tags.moduleKeyword, color: "#00f", fontWeight: "bold" },
  {tag: tags.modifier, color: "#00bd52", fontWeight: "bold" },
  {tag: tags.namespace, color: "#00f" },
  {tag: tags.string, color: "#00ad76" },
  {tag: tags.number, color: "#aa00ff" },
  {tag: tags.bool, color: "#aa00ff", fontWeight: "bold" },
  {tag: tags.null, color: "#aa00ff", fontWeight: "bold" },
  //{tag: tags.namespace, color: "#dd4eea", fontWeight: "bold" },
  {tag: tags.annotation, color: "#dd4eea", fontWeight: "bold" },
  {tag: tags.updateOperator, color: "#dd4eea" },
  {tag: tags.changed, color: "#aa00ff" },

  //html style
  {tag: tags.tagName, color: "#0a5" },

  //js style
  {tag: tags.function, color: "#708" }
]);//, { scope: cbtemplateParser.topNode });


//export let extensions = [syntaxHighlighting(cbtemplateHighlightStyle)];