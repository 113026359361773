import { Brick, CBContext, CBEventInfo, Composition, DebugEvent, TagItem } from "../../codebricks-runtime/CBModels";
import { EscapeHtml, FindBrick, FindBrickContainer } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class c_visual_debugger_webcomponent extends HTMLElement {
    ci: web_c_visual_debugger | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_visual_debugger(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-visual-debugger', c_visual_debugger_webcomponent);

export class web_c_visual_debugger extends CodeBrick {

    element: HTMLElement;
    initialized = false;
    composition_name = "";
    compos: Composition | undefined;
    debug_log: any;
    initialised = false;
    bricks_by_name = {} as { [name:string] : Brick };

    bp_inputs = {} as { [input_id: string] : boolean }

    bp_paused = false as boolean | string;
    bp_paused_emit_idx = 0;
    bp_paused_event_idx = 0;

    bp_emit_start_idx = 0;
    bp_event_start_idx = 0;
    bp_emit_stop_idx = -1;
    bp_event_stop_idx = -1;

    brick_statuses = {} as { [brick_id:string] : {
        inputs : {
            [input:string] : {
                last: DebugEvent,
                sources : {
                    [source: string] : {
                        [output: string] : DebugEvent
                    }
                }
            }
        },
        outputs: {
            [output:string] : DebugEvent
        }

    } };

    lines = [] as any[];

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;  
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        //console.log("c-visual-debugger input "+input+" cfg "+JSON.stringify(cfg));

        if(input == "cfg") {
            this.composition_name = cfg.composition_name;
            this.compos = cfg.composition_tree;
            this.element.innerHTML = "<h1>"+cfg.composition_name+"</h1>";
            this.debug_log = cfg.debug_log;

            //console.log("this.debug_log "+this.debug_log);

            //The UI expects brick ids to start with cb_1_, but for server side it will start with cb_0_ and if you run again it will start with cb_2_ ++
            //So we rename them here to alawys be cb_1_
            for(let emit of this.debug_log.emits) {
                for(let event of emit.events) {
                    if(event.source_brick_id) {
                        event.source_brick_id = "cb_1_" + event.source_brick_id.substring(5);
                    }
                    if(event.dest) {
                        event.dest  = "cb_1_" + event.dest.substring(5);
                    }
                }
            }

            this.bp_paused = false;

            this.register_brick_by_name(this.compos as Brick);

            this.create_event_handlers();

            this.element.innerHTML = `<div class="debugger-container">
                <ul>
                    <li>Click any brick to see event information</li>
                    <li>Click any input to set a breakpoint. Breakpoints will be hit when Run / Rerun is clicked</li>
                    <li>For server side compositions, the debugging will play a recording of the events as sent from the server after it has fully run</li>
                </ul>
                <div class="debugger-bp-controls hidden" id="bp.${this.brick_id}">
                
                <button class="debugger-bp-btn-play" onclick="debugger_bp_play()"></button>
                <button class="debugger-bp-btn-next" onclick="debugger_bp_next()"></button>
            </div>
            <div id="brick."><div class="debugger-brick-inputs"></div><div></div><div class="debugger-brick-childs"></div><div class="debugger-brick-outputs"></div></div></div>`
           
            this.render_static_brick(this.compos as Brick, "");

            let self = this;
            setTimeout(async function() { 
                await self.render_emits() 
            }, 100);

            if(!this.initialised) {
                let mod1_html = `<cci-modal id="${this.brick_id}$mod1" ins='{"cfg":{ "title":"Brick Status", "cancel_button": "Close"}}'></cci-modal>`;
                document.body.insertAdjacentHTML("beforeend", mod1_html);

                this.RegisterSOCallback(this.brick_id+"$mod1", "@", 
                    async function(data:any) {
                    
                    }
                );


                this.initialised = true;
            }
            
        }
        else if(input == "clear_lines") {
            this.DeleteAllLines();
        }
    }

    create_event_handlers() {
        let self = this;
        //@ts-expect-error  
        if(!window["debugger_brick_click"]) {
            //@ts-expect-error
            window["debugger_brick_click"] = async function(ev: any, element: any) {
                ev.stopPropagation();
                let brick_id = element.id.split(".")[1];
                let brick_name = element.getAttribute("name");//
                let displayname = element.getAttribute("displayname");

                //alert(JSON.stringify(self.brick_statuses[brick_name], null, 2));
                let brick = FindBrick(self.compos as Brick, brick_name);

                let mod1 = document.getElementById(self.brick_id+"$mod1_brick$body");
                if(mod1) {

                    let html = "<div>";

                    
                    if(brick) {
                        html += `<h2>Inputs Template</h2>`;
                        html += `<pre class="debugger-io-indent">${EscapeHtml(JSON.stringify(brick.ins, null, 2))}</pre>`;
                        html += `</div>`;
                    }

                    html += "<h2>Received Inputs</h2>";
                    
                    if(self.brick_statuses[brick_id]) {
                        for(let input in self.brick_statuses[brick_id].inputs) {
                            html += `<div class="debugger-io-indent"><div class="debugger-io-label">${input}</div>`
                            html += "<div>Last triggering source: "+self.brick_statuses[brick_id].inputs[input].last.source+"</div>";
                            html += self.brick_statuses[brick_id].inputs[input].last.resolved ? `<pre class="debugger-io-value">${EscapeHtml(JSON.stringify(self.brick_statuses[brick_id].inputs[input].last.cfg, null, 2))}</pre>` : `<pre class="debugger-io-value">Unresolved (Awaiting Values / Values do not match condition)</pre>`;
                            
                            if(brick && brick.type[0] == "s" && self.compos && self.compos.type[0] == "c") {
                                html += `<div class="editor-form-property-desc">Note: For security reasons static values in server side ins are not in client requests. The server will read them from the composition.</div>`;
                            }
                            
                            html += `<div class="debugger-io-indent"><div class="debugger-io-label">${input} sources</div><div class="debugger-io-indent">`;
                            for(let source in self.brick_statuses[brick_id].inputs[input].sources) {
                                for(let output in self.brick_statuses[brick_id].inputs[input].sources[source]) {
                                    // if(!source && !self.brick_statuses[brick_id].inputs[input].sources[source][output].source_data) {
                                    // }
                                    // else {
                                        let pretty_source = source.split("--")[0].substring(5);
                                        html += `${ source ? ("{{" + pretty_source + (output == "@" ? "" : ("."+output))+"}}") : "Initialization"} sent:<pre class="debugger-io-indent">${JSON.stringify(self.brick_statuses[brick_id].inputs[input].sources[source][output].source_data, null, 2) }</pre>`;
                                    //}
                                }
                            }

                            html += `</div>`;

                            if(self.brick_statuses[brick_id].inputs[input].last.error) {
                                html += `<div class="debugger-error"><div class="debugger-io-label">Error in reponse to input:</div><div class="debugger-io-indent">${JSON.stringify(self.brick_statuses[brick_id].inputs[input].last.error)}</div></div>`;
                            }

                            if(self.brick_statuses[brick_id].inputs[input].last.out_data != undefined) {
                                //we need to remove @debug_log
                                let data_with_debug_log = self.brick_statuses[brick_id].inputs[input].last.out_data;
                                let show_data = {} as any;
                                for(let k in data_with_debug_log) {
                                    if(k != "@debug_log") {
                                        //show_data[k] = data_with_debug_log[k];
                                        if(self.compos && self.compos.debug_log && 
                                            self.compos.debug_log.last_emit_data && 
                                            self.compos.debug_log.last_emit_data[brick_name] &&
                                            self.compos.debug_log.last_emit_data[brick_name].outputs[k]) {
                                                show_data[k] = self.compos.debug_log.last_emit_data[brick_name].outputs[k].data;
                                        }
                                    }
                                }

                                html += `<div><div class="debugger-io-label">Last output data produced (SHORTENED SAMPLE):</div><pre class="debugger-io-indent">${JSON.stringify(show_data, null, 2)}</pre></div>`;

                                //html += `<div><div class="debugger-io-label">Last output data produced:</div><pre class="debugger-io-indent">${JSON.stringify(self.compos.debug_log.last_emit_data[brick_name], null, 2)}</pre></div>`;

                            }

                            html += "</div></div>";
                        }
                        html += "<h2>Linked Outputs</h2>"
                        for(let output in self.brick_statuses[brick_id].outputs) {
                            html += `<div><div class="debugger-io-label">${output}</div>
                            <pre class="debugger-io-value">${JSON.stringify(self.brick_statuses[brick_id].outputs[output].source_data, null, 2) }</pre>
                            </div>`;
                        }

                        
                    }
                    else {
                        html += "None";
                    }


                    mod1.innerHTML = html + "</div>"; //JSON.stringify(self.brick_statuses[brick_name], null, 2);//`<c-editor-code style="width:100%" id="${self.brick_id}$expanded" ins='{ "cfg": { "value": "" }}'></c-editor-code>`;

                    (<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("cfg", { "title": displayname +" <div>"+(brick ? brick.type : "")+"</div>", "cancel_button": "Close" });
                    //(<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("data", { node_path: node_path });
                    (<any>window).so_bricks[self.brick_id+"$mod1_brick"].cb_event("show", 1);  
                }
                
            }
        }

        //@ts-expect-error
        if(!window["debugger_input_click"]) {
            //@ts-expect-error
            window["debugger_input_click"] = async function(ev: any, element: any) {
                ev.stopPropagation();
                let input_id = element.id;

                if(self.bp_inputs[input_id]) {
                    element.classList.remove("debugger-io_breakpoint");
                    element.classList.remove("debugger-bp-active");
                    delete self.bp_inputs[input_id];
                }
                else {
                    element.classList.add("debugger-io_breakpoint");
                    self.bp_inputs[input_id] = true;
                }
                
            }
        }

        //@ts-expect-error
        if(!window["debugger_bp_play"]) {
            //@ts-expect-error
            window["debugger_bp_play"] = async function(ev: any, element: any) {
                if(self.bp_paused) {
                    let input_element = document.getElementById(self.bp_paused as string);
                    if(input_element) {
                        self.bp_event_start_idx = self.bp_paused_event_idx + 1;
                        self.bp_emit_start_idx = self.bp_paused_emit_idx;
                        if(self.bp_event_start_idx >= self.debug_log.emits[self.bp_emit_start_idx].length) {
                            self.bp_emit_start_idx++;
                            self.bp_event_start_idx = 0;
                        }
                            
                        self.bp_paused = false;
                        input_element.classList.remove("debugger-bp-active");
                        let bp_controls = document.getElementById(`bp.${self.brick_id}`);
                        if(bp_controls) {
                            bp_controls.classList.add("hidden");
                        }
                        
                        await self.render_emits(false);
                        self.bp_emit_start_idx = 0;
                        self.bp_event_start_idx = 0;
                    }
                }
            }
        }

        //@ts-expect-error
        if(!window["debugger_bp_next"]) {
            //@ts-expect-error
            window["debugger_bp_next"] = async function(ev: any, element: any) {
                let input_element = document.getElementById(self.bp_paused as string);
                if(input_element) {

                    let ids = self.get_next_event(self.bp_paused_emit_idx, self.bp_paused_event_idx);
                    if(ids) {
                        self.bp_emit_start_idx = ids[0];
                        self.bp_event_start_idx = ids[1];
                    }

                    ids = self.get_next_event(self.bp_emit_start_idx, self.bp_event_start_idx);
                    if(ids) {
                        self.bp_emit_stop_idx = ids[0];
                        self.bp_event_stop_idx = ids[1];
                    }

                    self.bp_paused_emit_idx = self.bp_emit_start_idx;
                    self.bp_paused_event_idx = self.bp_event_start_idx;

                    input_element.classList.remove("debugger-bp-active");

                    let paused_event = self.debug_log.emits[self.bp_paused_emit_idx].events[self.bp_paused_event_idx];
                    let input_id = "input." + paused_event.dest + "." + paused_event.input;
                    self.bp_paused = input_id;
                    
                    await self.render_emits(false);
                    self.bp_emit_start_idx = 0;
                    self.bp_event_start_idx = 0;
                    self.bp_emit_stop_idx = -1;
                    self.bp_event_stop_idx = -1;

                }
            }
        }
    }

    get_next_event(emit_idx: number, event_idx: number) : number[] | null {
        let next_emit_idx = emit_idx;
        let next_event_idx = event_idx + 1;
        if(next_event_idx >= this.debug_log.emits[emit_idx].length) {
            next_emit_idx++;
            next_event_idx = 0;
        }
        let event = this.debug_log.emits[next_emit_idx].events[next_event_idx];
        if(event) {
            let source_brick_info = this.get_brick_info(event.source_brick_id);
            let dest_brick_info = this.get_brick_info(event.dest);
            if(!source_brick_info.is_in_sub && !dest_brick_info.is_in_sub) {
                return [next_emit_idx, next_event_idx];
            }
            else {
                return this.get_next_event(next_emit_idx, next_event_idx);
            }
        }
        return null;
    }

    async render_emits(delete_lines = true) {
        if(delete_lines) {
            this.DeleteAllLines();
        }

        if(this.debug_log) {
            let stop = this.bp_emit_stop_idx == -1 ? this.debug_log.emits.length : (this.bp_emit_stop_idx + 1);

            for(let emit_idx = this.bp_emit_start_idx; emit_idx < stop; emit_idx++) {

                let emit_log = this.debug_log.emits[emit_idx];

                await this.render_emit(emit_log, emit_idx);

                if(this.bp_paused) {
                    return;
                }
            }

            this.PositionAllLines();
        }
    }

    async render_emit(emit_log: any, emit_idx: number) {
        let emitter = emit_log.emitter;
        let stop = this.bp_event_stop_idx == -1 ? emit_log.events.length : this.bp_event_stop_idx;
        for(let event_idx = this.bp_event_start_idx; event_idx < stop; event_idx++) {
            let event = emit_log.events[event_idx];

            await this.render_event(emitter, event, emit_idx, event_idx);

            if(this.bp_paused) {
                return;
            }
        }
    }

    async render_event(source: string, event: DebugEvent, emit_idx: number, event_idx: number) {
        let dest_brick_info = this.get_brick_info(event.dest);
        let source_brick_id = event.source_brick_id || (event.source ? ("cb_1_" + event.source) : "");

        if(event.dest == "cb_1_rsp_CallLeaderBoard") {
            console.log("render_event "+event.source_brick_id +"=>"+event.dest+ " resolved "+event.resolved + " " + event.out_data);
        }

        if(event.source == "") {
            
            if(event.resolved) {
                if(!dest_brick_info.is_in_sub) {

                    //await this.sleep(100);

                    let dest_brick_element = document.getElementById("brick."+event.dest);
                    if(dest_brick_element) {
                        dest_brick_element.classList.add("debugger-brick-resolved");
                    }
                    else {
                        this.render_dynamic_brick(event.dest, dest_brick_info, event, emit_idx, event_idx);
                    }          
                }
            }
        }
        else {
            if(event.source_brick_id == undefined) {
                //console.error("event.source_brick_id == undefined event "+JSON.stringify(event));
                return;
            }

            let source_brick_info = this.get_brick_info(event.source_brick_id);
            let output_element;
            let input_element;

            if(!source_brick_info.is_in_sub && !dest_brick_info.is_in_sub) {

                //await this.sleep(300);

                //draw output on source (if not there)
                
                let source_brick_element = document.getElementById("brick."+source_brick_id);
                if(source_brick_element) {                
                    let output_id = "output." + source_brick_id + "." + event.output;
                    output_element = document.getElementById(output_id);
                    if(!output_element) {
                        let outputs_container = this.get_brick_element_outputs_container(source_brick_element); //.lastElementChild;
                        if(this.compos && (event.source == this.compos.name)) {
                            //for the root unit, it's output is an input from this perspective, draw it wiht the inputs
                            outputs_container = this.get_brick_element_inputs_container(source_brick_element);//source_brick_element.firstElementChild;
                        }
                        if(outputs_container) {
                            outputs_container.insertAdjacentHTML("beforeend", `<div class="debugger-brick-output" id="${output_id}">${event.output == "@" ? "" : event.output}</div>`);
                        }
                        output_element = document.getElementById(output_id);
                    }
                }
                else {
                    this.render_dynamic_brick(event.source_brick_id, source_brick_info, event, emit_idx, event_idx);
                }

                //draw input on dest (if not there)
                let dest_brick_element = document.getElementById("brick."+event.dest);
                if(dest_brick_element) {                
                    let input_id = "input." + event.dest + "." + event.input;

                    input_element = document.getElementById(input_id);
                    if(!input_element) {
                        let inputs_container = this.get_brick_element_inputs_container(dest_brick_element); //dest_brick_element.firstElementChild;
                        if(event.input == "unit_outs") {
                            inputs_container = this.get_brick_element_outputs_container(dest_brick_element); //.lastElementChild; //draw unit_outs with the outputs
                        }
                        if(inputs_container) {
                            inputs_container.insertAdjacentHTML("beforeend", `<div class="debugger-brick-input" id="${input_id}" onclick="debugger_input_click(event, this)">${event.input}</div>`);
                        }
                        input_element = document.getElementById(input_id);
                    }
                    if(event.resolved && input_element) {
                        input_element.classList.add("debugger-input-resolved");
                    }

                    if(this.bp_inputs[input_id] && input_element) {
                        input_element.classList.add("debugger-io_breakpoint");
                        input_element.classList.add("debugger-bp-active");
                        this.bp_paused = input_id;
                        this.bp_paused_emit_idx = emit_idx;
                        this.bp_paused_event_idx = event_idx;

                        let bp_controls = document.getElementById(`bp.${this.brick_id}`);
                        if(bp_controls) {
                            bp_controls.classList.remove("hidden");
                        }

                        console.log("paused on bp "+input_id);
                    }
                    if(emit_idx == this.bp_paused_emit_idx && event_idx == this.bp_paused_event_idx && input_element) {
                        input_element.classList.add("debugger-bp-active");
                    }
                }
                else {
                    this.render_dynamic_brick(event.dest, dest_brick_info, event, emit_idx, event_idx);
                }

                //draw line from source output to dest input, with color indicating if it resolved.
                if(output_element && input_element) {
                    this.DrawLine(output_element, input_element, { 
                        color: "#4dce92",
                        path: "straight", 
                        size: 2 
                    });
                }
            }
        }

        this.brick_statuses[source_brick_id] = this.brick_statuses[source_brick_id] || { inputs: {}, outputs: {} };
        this.brick_statuses[source_brick_id].outputs[event.output] = event;
        
        this.brick_statuses[event.dest] = this.brick_statuses[event.dest] || { inputs: {}, outputs: {} };
        this.brick_statuses[event.dest].inputs[event.input] = this.brick_statuses[event.dest].inputs[event.input] || { sources: {} };
        let last_data = null;
        if(this.brick_statuses[event.dest].inputs[event.input].last) {
            last_data = this.brick_statuses[event.dest].inputs[event.input].last.out_data;
        }
        this.brick_statuses[event.dest].inputs[event.input].last = event;
        if(last_data) {
            this.brick_statuses[event.dest].inputs[event.input].last.out_data = last_data; //We want the last emit data, else if another event comes that does not resolve, we lose this
        }
        this.brick_statuses[event.dest].inputs[event.input].sources[source_brick_id] = this.brick_statuses[event.dest].inputs[event.input].sources[source_brick_id] || {};
        this.brick_statuses[event.dest].inputs[event.input].sources[source_brick_id][event.output] = event;
    }

    render_static_brick(container: Brick, container_parent_brick_name: string) {

        this.render_brick(container.name, "cb_1_" + container.name, container_parent_brick_name ? "cb_1_" + container_parent_brick_name : "");

        let type_prefix = container.type.split("-")[0];
        if(container.contains && type_prefix.indexOf("d") == -1) {
            for(let child of container.contains) {
                this.render_static_brick(child, container.name);
            }
        }
    }

    register_brick_by_name(brick: Brick) {
        this.bricks_by_name[brick.name] = brick;
        if(brick.contains) {
            for(let child of brick.contains) {
                this.register_brick_by_name(child);
            }
        }
    }

    render_brick(brick_name: string, brick_id: string, container_brick_id: string) {
        let container_div = document.getElementById("brick."+container_brick_id);
        if(container_div) {
            let has = document.getElementById("brick."+brick_id);
            if(!has) {
                let child_html = `<div id="brick.${brick_id}" name="${brick_name}" displayname="${brick_name}" onclick="debugger_brick_click(event, this)" class="debugger-brick"><div class="debugger-brick-name">${brick_name} <div class="debugger-brick_type">${this.bricks_by_name[brick_name].type}</div></div> <div class="debugger-brick-inputs"></div> <div class="debugger-brick-outputs"></div> <div class="debugger-brick-childs"></div></div>`;
                let holder = container_div.children[3];
                if(holder) {
                    holder.insertAdjacentHTML("beforeend", child_html);
                }
            }
        }
        else {
            console.error(container_brick_id + " not found");
        }
    }

    render_dynamic_brick(brick_id: string, brick_info: {is_in_sub: boolean, is_dc: boolean, dc_parts: string[] }, event: DebugEvent, emit_idx: number, event_idx: number) {
        if(brick_info.is_dc) {

            let rearranged_dc_parts = [];
            for(let d = 1; d < brick_info.dc_parts.length; d += 1) {
                rearranged_dc_parts.push(brick_info.dc_parts[d]);
            }
            rearranged_dc_parts.push(brick_info.dc_parts[0].substring(5));

            //Ensure dynamic heirarchical container debug blocks are created, and create dynamic brick
            let rows_parent = document.getElementById("brick.cb_1_"+brick_info.dc_parts[1]);
            let row_container;
            if(rows_parent) {
                let row_name = "";           
                for(let d = 0; d < rearranged_dc_parts.length; d += 2) {
                    let container = rearranged_dc_parts[d];
                    let pos = rearranged_dc_parts[d + 1];

                    //row_name = container + "--" + pos + (row_name ? ("--" + row_name) : "");
                    row_name = (row_name ? (row_name + "--") : "") + container + "--" + pos;

                    //The first container is not in a dc (it is dc root)
                    //It will already have been created by render_static_brick

                    //create the entry array container
                    if(rearranged_dc_parts.length > d + 2) {
                        let dynamic_array_container_id = "row." + row_name;
                        row_container = document.getElementById(dynamic_array_container_id);
                        if(!row_container && rows_parent) {
                            let row_html = `<div id="${dynamic_array_container_id}" class="debugger-dynamic-row">${pos}</div>`;

                            rows_parent.insertAdjacentHTML("beforeend", row_html);      
                            
                            row_container = document.getElementById(dynamic_array_container_id);
                        }

                        //Now create the container brick
                    
                        let debugger_brick_id = "brick.cb_1_"+rearranged_dc_parts[d+2] + "--" +row_name;  //"brick."+brick_id.replaceAll("--", "."); //We cant use brick Id because its used in the actual composition
                        let has_brick = document.getElementById(debugger_brick_id);
                        if(!has_brick) {
                            let child_html = `<div id="${debugger_brick_id}" name="${rearranged_dc_parts[d+2]}" displayname="${rearranged_dc_parts[d+2]} (${pos})" onclick="debugger_brick_click(event, this)" class="debugger-brick"><div class="debugger-brick-name">${rearranged_dc_parts[d+2]} (${pos})<div class="debugger-brick_type">${this.bricks_by_name[rearranged_dc_parts[d+2]].type}</div></div><div class="debugger-brick-inputs"></div><div class="debugger-brick-outputs"></div><div class="debugger-brick-childs"></div></div>`;
                            if(row_container) {
                                row_container.insertAdjacentHTML("beforeend", child_html);
                            }
                            has_brick = document.getElementById(debugger_brick_id);
                        }
                        rows_parent = has_brick;

                        if(has_brick) {
                            let output_element;
                            let input_element;
                            let source_brick_id = event.source_brick_id || (event.source ? ("cb_1_" + event.source) : "");

                            let source_brick_element = document.getElementById("brick."+source_brick_id);
                            let output_id = "output." + source_brick_id + "." + event.output;
                            if(source_brick_element) {                
                                
                                output_element = document.getElementById(output_id);
                                if(!output_element) {
                                    let outputs_container = this.get_brick_element_outputs_container(source_brick_element); //.lastElementChild;
                                    if(this.compos && (event.source == this.compos.name)) {
                                        //for the root unit, it's output is an input from this perspective, draw it wiht the inputs
                                        outputs_container = this.get_brick_element_inputs_container(source_brick_element); //.firstElementChild;
                                    }
                                    if(outputs_container) {
                                        outputs_container.insertAdjacentHTML("beforeend", `<div class="debugger-brick-output" id="${output_id}"></div>`);
                                    }
                                    output_element = document.getElementById(output_id);
                                }
                            }
                            
                            
                            let input_id = "input." + event.dest + "." + event.input;
                            input_element = document.getElementById(input_id);
                            if(brick_id == event.source_brick_id) {
                                
                                if(!output_element) {
                                    let outputs_container = this.get_brick_element_outputs_container(has_brick); //.lastElementChild;
                                    if(this.compos && (event.source == this.compos.name)) {
                                        //for the root unit, it's output is an input from this perspective, draw it wiht the inputs
                                        outputs_container = this.get_brick_element_inputs_container(has_brick); //.firstElementChild;
                                    }
                                    if(outputs_container) {
                                        outputs_container.insertAdjacentHTML("beforeend", `<div class="debugger-brick-output" id="${output_id}"></div>`);
                                    }
                                    output_element = document.getElementById(output_id);
                                }
                            }
                            else {

                                if(!input_element) {
                                    let inputs_container = this.get_brick_element_inputs_container(has_brick); //.firstElementChild;
                                    if(event.input == "unit_outs") {
                                        inputs_container = this.get_brick_element_outputs_container(has_brick); //.lastElementChild; //draw unit_outs with the outputs
                                    }
                                    if(inputs_container) {
                                        inputs_container.insertAdjacentHTML("beforeend", `<div class="debugger-brick-input" id="${input_id}" onclick="debugger_input_click(event, this)"></div>`);
                                    }
                                    input_element = document.getElementById(input_id);
                                }
                                if(event.resolved && input_element) {
                                    input_element.classList.add("debugger-input-resolved");
                                }
                            }

                            if(this.bp_inputs[input_id] && input_element) {
                                input_element.classList.add("debugger-io_breakpoint");
                                input_element.classList.add("debugger-bp-active");
                                this.bp_paused = input_id;
                                this.bp_paused_emit_idx = emit_idx;
                                this.bp_paused_event_idx = event_idx;
        
                                let bp_controls = document.getElementById(`bp.${this.brick_id}`);
                                if(bp_controls) {
                                    bp_controls.classList.remove("hidden");
                                }
        
                                console.log("paused on bp "+input_id);
                            }
                            if(emit_idx == this.bp_paused_emit_idx && event_idx == this.bp_paused_event_idx && input_element) {
                                input_element.classList.add("debugger-bp-active");
                            }

                            //draw line from source output to dest input, with color indicating if it resolved.
                            //Too may lines
                            // if(output_element && input_element) {
                            //     this.DrawLine(output_element, input_element, { 
                            //         color: "#4dce92",
                            //         path: "straight", 
                            //         size: 2 
                            //     });
                            // }
                        }
                    }
                }
            }
        }
    }

    get_brick_element_inputs_container(brick_element: HTMLElement) {
        //return brick_element.firstElementChild;
        return brick_element.children[1];
    }

    get_brick_element_outputs_container(brick_element: HTMLElement) {
        return brick_element.children[2]; //brick_element.lastElementChild;
    }
    

    get_brick_info(brick_id: string) : {is_in_sub: boolean, is_dc: boolean, dc_parts: string[] } {
        let ret = { is_in_sub: false, is_dc: false, dc_parts: [] as string[] } as any;
        for(let i = 1; i < brick_id.length - 1; i++) {
            if(brick_id[i-1] != "-" && brick_id[i] == "-" &&  brick_id[i + 1] != "-") {
                ret.is_in_sub = true;
                break;
            }
            if(brick_id[i-1] == "-" && brick_id[i] == "-") {
                ret.is_dc = true;
                break;
            }
        }

        if(ret.is_dc) {
            let dc_parts = brick_id.split("--"); 
            //example: cb_1_lvl3_items--menu_lvl1_cd_flex--1--menu_lvl2_cd_flex--0--lvl3_items_container--0
            ret.dc_parts = dc_parts;
        }
        return ret;
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
        
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}

    DrawLine(from: HTMLElement, to: HTMLElement, options: any) {
        // try {
        //     //@ts-expect-error
        //     let line = new LeaderLine(from, to, options);

        //     let container = document.getElementById("cb_0_visual_debugger_container");
        //     if(container) {
        //         //@ts-expect-error
        //         container.addEventListener('scroll', AnimEvent.add(function() {
        //             line.position();
        //         }), false);
        //     }

        //     // let scroll_elems = this.get_Scrolls() as HTMLElement[];
        //     // for(let s = 0; s < scroll_elems.length; s++) {
        //     //   if(scroll_elems[s].id.startsWith("cb_1") && !scroll_elems[s].id.endsWith("$items")) {
        //     //       //@ts-expect-error
        //     //       scroll_elems[s].addEventListener('scroll', AnimEvent.add(function() {
        //     //           line.position();
        //     //         }), false);
        //     //   }
        //     // }
        //     this.lines.push(line);
        //     return line;
        // }
        // catch(err) {
        //     console.error(err);
        // }
    }

    DeleteAllLines() {
        for(let line of this.lines) {
            line.remove();
        }
        this.lines = [];
    }

    PositionAllLines() {
        for(let line of this.lines) {
            line.position();
        }
    }


    get_Scrolls = (function() {
        //@ts-expect-error
        var getComputedStyle = document.body && document.body.currentStyle ? function(elem) {
            return elem.currentStyle;
            //@ts-expect-error
        } : function(elem) {
            //@ts-expect-error
            return document.defaultView.getComputedStyle(elem, null);
        };
    
        function getActualCss(elem: any, style: any) {
            return getComputedStyle(elem)[style];
        }
    
        function isXScrollable(elem: any) {
            return elem.offsetWidth < elem.scrollWidth &&
                autoOrScroll(getActualCss(elem, 'overflow-x'));
        }
    
        function isYScrollable(elem: any) {
            return elem.offsetHeight < elem.scrollHeight &&
                autoOrScroll(getActualCss(elem, 'overflow-y'));
        }
    
        function autoOrScroll(text: any) {
            return text == 'scroll' || text == 'auto';
        }
    
        function hasScroller(elem: any) {
            return isYScrollable(elem) || isXScrollable(elem);
        }
        return function ElemenetsWithScrolls() {
            return [].filter.call(document.querySelectorAll('*'), hasScroller);
        };
    })();

    generateRandomColor(){
        // let maxVal = 0xCCCCCC; // 16777215
        // let minVal = 0x555555;
        // let randomNumber = minVal + (Math.random() * (maxVal - minVal)); 
        // randomNumber = Math.floor(randomNumber);
        // randomNumber = randomNumber.toString(16);
        // let randColor = randomNumber.padStart(6, 0);   
        // return `#${randColor.toUpperCase()}`

        //return "#" + Math.floor(Math.random()*16777215).toString(16);
        // 30 random hues with step of 12 degrees

        let steps = 25;
        return this.rainbow(steps, Math.random()*steps);

    }

    rainbow(numOfSteps, step) {
        var r, g, b;
        var h = step / numOfSteps;
        var i = ~~(h * 6);
        var f = h * 6 - i;
        var q = 1 - f;
        switch(i % 6){
            case 0: r = 1; g = f; b = 0; break;
            case 1: r = q; g = 1; b = 0; break;
            case 2: r = 0; g = 1; b = f; break;
            case 3: r = 0; g = q; b = 1; break;
            case 4: r = f; g = 0; b = 1; break;
            case 5: r = 1; g = 0; b = q; break;
        }
        var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
        return (c);
    }

    sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    RegisterSOCallback(id: string, output: string, callback: Function) {
        (<any>window).so_callbacks = (<any>window).so_callbacks || {};
        (<any>window).so_callbacks[id+"_brick"] = (<any>window).so_callbacks[id+"_brick"] || {};
        (<any>window).so_callbacks[id+"_brick"][output] = [];
    
        (<any>window).so_callbacks[id+"_brick"][output].push(callback);
    }

}