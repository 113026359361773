
export let CBTemplateParserNodes = {

    // Based on cbtemplate.grammar definition
    // Lezer does not support predicting the next node, so we use this
    // Read by TagElementPredictor.ts
     grammar: {

        values: {
            //ampersand: ["&", "And"],
            And: ["And", "And"],
            Or: ["Or", "Or"],
            bracesclose: ["}}", "End tag"],
            bracesopen: ["{{", "Start tag" ],
            bracketclose: [")", "End bracket"],
            bracketopen: ["(", "Start bracket"],
            Brick: ["Brick", "Select source brick"],
            colon: [":", " else" ],
            comma: [",", "Add another source"],
            dot: [".", "Add output or property (optional, else whole output used)"],
            dot2: [".", "Add property (optional, else whole output used)"],
            Eq: ["Eq", "Add equals condition"],
            gt: [ ">", "Add greater than condition"],
            ge: [">=", "Add greater or equal condition"],
            lt: ["<", "Add less than condition"],
            le: ["<=", "Add less or equal condition"],
            pipe: ["|", "Or"],
            Output: ["Output", "Select output"],
            Prop: ["Prop", "Select property"],
            semicolon: [";","Add format"],
            questionmark: ["?", "then"],
            Null: ["Null", "Null"],
            Boolean: ["Boolean", "Boolean"],
            Number: ["Number", "Number"],
            String: ["String", "Start string"],
            FormatType: ["FormatType", "Format Type"],
            Formatparams: ["Formatparams", "Format Parameters"],
            formatdot: [".", "Add format function"],
            FormatFunc: ["FormatFunc", "Format Function"],
            Plus: ["Plus", "Append Term"]
        },

        // Each prop in nodes represents a line in the cbtemplate.grammar
        // Each sub prop is for each element in the line, with an array of the next allowed elements accoring to the grammar.
        // The elements in the array are the prop names locally in the line, so could be appended with _number, indicating that its the second occurence of the type in the line, e.g. nodes.section.term_2
        // the names (with _number stripped off) can either refer to values (above) or nodes (below), they must be found in one of them.
        nodes: {
            Top: {
                start: ["bracesopen"],
                bracesopen: ["section"],
                section: [
                    "comma",
                    "semicolon",
                    "bracesclose"
                ],
                comma: ["section"],
                semicolon:["Format"],
                Format: ["bracesclose"],
                bracesclose: ["done"]
            },
            nestedTag: {
                start: ["bracketopen"],
                bracketopen: ["section"],
                section: [
                    "comma",
                    "semicolon",
                    "bracketclose"
                ],
                comma: ["section"],
                semicolon:["Format"],
                Format: ["bracketclose"],
                bracketclose: ["done"]
            },
            section: {
                start: ["compareValue"],
                compareValue: [
                    "And",
                    "Or",
                    "questionmark",
                    "done"
                ],
                And: ["compareValue"],
                Or: ["compareValue"],
                questionmark: ["appendterm"],
                appendterm: [
                    "colon",
                    "done"
                ],
                colon: ["appendterm_2"],
                appendterm_2: ["done"]
            },
            compareValue: {
                start: ["appendterm"],
                appendterm: [
                    "Eq",
                    "lt",
                    "gt",
                    "le",
                    "ge",
                    "done"
                ],
                Eq: ["appendterm_2"],
                lt: ["appendterm_2"],
                gt: ["appendterm_2"],
                le: ["appendterm_2"],
                ge: ["appendterm_2"],
                appendterm_2: ["done"]
            },
            appendterm: {
                start: ["term"],
                term: ["Plus", "done"],
                Plus: ["term"]
            },
            term: {
                start: [
                    "Brick",
                    "String",
                    "Number",
                    "Boolean",
                    "Null",
                    "nestedTag"
                ],
                Brick: [
                    "dot",
                    "dot2",
                    "done"
                ],
                dot: [
                    "Output",
                    "Prop"
                ],
                Output: [
                    "dot2",
                    "done"
                ],
                dot2: [
                    "Prop"
                ],
                String: ["done"],
                Null: ["done"],
                Number: ["done"],
                Boolean: ["done"],
                Prop: ["dot2", "done"],
                nestedTag: ["done"]
            },
            // And: {
            //     start: [ "ampersand" ],
            //     ampersand: [ "done", "ampersand_2"],
            //     ampersand_2: ["done"]
            // },
            // Or: {
            //     start: [ "pipe" ],
            //     pipe: [ "done", "pipe_2"],
            //     pipe_2: ["done"]
            // },
            // Boolean: {
            //     start: [ "true", "false"],
            //     true: [ "done"],
            //     false: ["done"]
            // }
            Format: {
                start: ["FormatType"],
                FormatType: [ "Formatparams", "formatdot" ],
                Formatparams: ["formatdot", "done"],
                formatdot: ["FormatFunc"],
                FormatFunc: ["Formatparams", "done"],
            }
        
        }
    }
} as NodeGrammar;

