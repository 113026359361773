import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_html_webcomponent extends HTMLElement {
    ci: web_c_html | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_html(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-html', c_html_webcomponent);

export class web_c_html extends CodeBrick {

    element: HTMLElement;
    html = "";
    no_loader = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log("CodeBricksHTML "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));

        if(input == 'cfg') {
            // let container_style = "";
            // if(cfg.style) {
            //     for(let v in cfg.style) {
            //         container_style += v+':'+cfg.style[v]+";"
            //     }
            // }

            // let classes = '';
            // if(cfg.style_classes) {
            //     for(let cl in cfg.style_classes) {
            //         classes += " " + cfg.style_classes[cl];
            //     }
            // }

            let hidden = '';
            if(cfg.hidden || cfg.hide) {
                hidden = "hidden"
            }

            this.element.innerHTML = `<div id="${this.brick_id}" ${CBWebUtil.GetElementStylesString(hidden, cfg, "container")}>${this.html}</div>`;
            
            let btn = document.getElementById(this.brick_id);
            if(btn && this.blueprint.targets) {
                let self = this;

                btn.addEventListener('click', function() {
                    self.cb_emit({"@":self.blueprint.name});
                });
                
            }
            
            this.no_loader = cfg.no_loader;
        }
        else if(input == "html") {
            if(cfg === null || cfg === undefined) {
                this.html = "";
            }
            else {
                this.html = cfg;
            }
            let container = document.getElementById(this.brick_id);
            if(container) {
                container.innerHTML = this.html;
            }
            else {
                this.element.innerHTML = `<div id="${this.brick_id}" style="" class="">${this.html}</div>`;
            }    
        }  
        cb_tooltip.set_tooltips(this.element);
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        let container = document.getElementById(this.brick_id);
        if(container) {
            if(status == "loading" && !this.no_loader) {
                container.classList.add("loading");
            }
            else {
                container.classList.remove("loading");
            }
        }
        cb_tooltip.set_tooltips(this.element);
    }

    cb_snapshot() {}
}